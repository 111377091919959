<template>
  <div
    style="cursor: pointer; display: flex; align-items: center; gap: 12px"
    @click.stop="changeValue"
  >
    <img
      v-if="!modelValue && green"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-red.svg`)"
    />
    <img
      v-if="!modelValue && !green"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-inactive.svg`)"
    />
    <img
      v-if="modelValue && !green && !toggleColour"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-active.svg`)"
    />
    <img
      v-if="modelValue && green"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-green.svg`)"
    />
    <img
      v-if="modelValue && toggleColour === 'turquoise'"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-turquoise.svg`)"
    />
    <img
      v-if="modelValue && toggleColour === 'black'"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-black.svg`)"
    />
    <div
      v-if="toggleLabel"
      class="content"
      v-bind:class="{ 'white-text': labelColour && labelColour === 'white' }"
    >
      {{ toggleLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseToggle",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: String,
    labelColour: String,
    disabled: Boolean,
    green: Boolean,
    toggleColour: String,
  },
  computed: {
    toggleLabel() {
      return this.label;
    },
  },
  methods: {
    changeValue() {
      if (this.disabled) return;
      const newValue = !this.modelValue;
      this.$emit("update:modelValue", newValue);
      this.$emit("click", newValue);
    },
  },
};
</script>

<style scoped>
.white-text {
  color: white !important;
}
</style>
