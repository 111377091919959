<template>
  <div>
    <div
      :key="'status-dropdown-' + invoice._id + '-' + componentUpdater"
      @click="openDropdown"
      v-click-outside="closeDropdown"
      class="content-medium status-dropdown-container"
      :class="{
        selectable: selectedStatus.opensDropdown,
        'not-selectable': !selectedStatus.opensDropdown,
      }"
    >
      <div
        class="status-dropdown-container-text"
        :class="{
          'additional-info-borders':
            !showAdditionalInfo ||
            (showAdditionalInfo &&
              invoice.payments &&
              invoice.payments.length === 0),
        }"
        :style="{ backgroundColor: selectedStatus.backgroundColor }"
      >
        {{ $t(selectedStatus.translation) }}
      </div>
      <div v-if="dropdownOpen" class="type-dropdown">
        <p
          v-for="status of statuses.filter(
            (arrayStatus) =>
              arrayStatus.selectableFromDropdown &&
              (!selectedStatus.ifSelectedHidesFromDropdown ||
                (selectedStatus.ifSelectedHidesFromDropdown &&
                  !selectedStatus.ifSelectedHidesFromDropdown.includes(
                    arrayStatus.value,
                  ))),
          )"
          class="content-medium"
          :style="{ backgroundColor: status.backgroundColor }"
          @click="selectDropdownStatus(status)"
          :key="status.value"
        >
          {{ $t(status.translation) }}
        </p>
      </div>
      <div
        v-if="
          invoice.payments && invoice.payments.length > 0 && showAdditionalInfo
        "
        :class="{ 'status-info-container-hover': !dropdownOpen }"
        @mouseover="openHover"
        @mouseleave="closeHover"
        class="status-info-container"
        :style="{ backgroundColor: selectedStatus.backgroundColor }"
      >
        <img
          height="16"
          width="16"
          :src="require('@/assets/images/info.svg')"
          alt=""
        />
        <div
          class="status-info-dropdown"
          v-if="hoverOpen && invoice.payments.length > 0"
        >
          <div
            class="status-info"
            v-for="(info, index) in invoice.payments"
            :key="'status-info-' + index"
          >
            <div class="content-normal">{{ formatDate(info.date) }}</div>
            <div
              style="width: 100%; text-align: right"
              class="content-semibold"
            >
              {{ $nMapper.price(info.sum, "€") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <InvoicePaymentWidget
      v-if="paymentWidgetOpen"
      :invoice="invoice"
      :pre-fill-unpaid="true"
      @updateInvoice="addPaymentConfirmed"
      @closeWidget="addPaymentCanceled"
    />
  </div>
</template>

<script>
import moment from "moment";
import InvoicePaymentWidget from "@/components/Invoices/InvoicePaymentWidget";
import { nanoid } from "nanoid";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useAccess } from "@/composables/useAccess";

export default {
  name: "TypeDropdown",
  components: { InvoicePaymentWidget },
  props: {
    statuses: {
      type: Array,
      default() {
        return [
          {
            value: "paid",
            translation: "Paid",
            backgroundColor: "#B5E7CF",
            opensDropdown: false,
            selectableFromDropdown: true,
          },
          {
            value: "not_paid",
            translation: "NotPaid",
            backgroundColor: "#FFB4B6",
            opensDropdown: true,
            selectableFromDropdown: true,
          },
          {
            value: "make_invoice",
            translation: "MakeInvoice",
            backgroundColor: "#E6E8EC",
            opensDropdown: true,
            selectableFromDropdown: true,
          },
          {
            value: "partially_paid",
            translation: "PartiallyPaid",
            backgroundColor: "#FFF2B3",
            opensDropdown: true,
            selectableFromDropdown: false,
            ifSelectedHidesFromDropdown: ["not_paid", "make_invoice"],
          },
        ];
      },
      validator(statuses) {
        const requiredFields = ["value", "translation", "backgroundColor"];
        for (let statusObject of statuses) {
          for (let key of requiredFields) {
            if (!Object.keys(statusObject).includes(key)) {
              return false;
            }
          }
        }
        return true;
      },
    },
    invoice: Object,
    showAdditionalInfo: Boolean,
  },
  data() {
    return {
      dropdownOpen: false,
      hoverOpen: false,
      paymentWidgetOpen: false,
      componentUpdater: 0,
    };
  },
  computed: {
    ...mapGetters(["customers", "user", "users"]),
    selectedStatus() {
      return this.statuses.find(
        (status) => status.value === this.invoice.status,
      );
    },
  },
  setup() {
    const { user, users, hasAccess, accessColleagues, groupColleagues } =
      useAccess();

    return {
      user,
      users,
      hasAccess,
      accessColleagues,
      groupColleagues,
    };
  },
  methods: {
    ...mapActions(["invoiceUpdated", "fetchInvoice"]),
    openDropdown() {
      if (!this.hasAccess(this.invoice)) return;
      if (!this.hoverOpen && this.selectedStatus.opensDropdown) {
        this.dropdownOpen = true;
      }
    },
    closeDropdown() {
      this.dropdownOpen = false;
      this.componentUpdater++;
    },
    openHover() {
      if (!this.dropdownOpen) {
        this.hoverOpen = true;
      }
    },
    closeHover() {
      this.hoverOpen = false;
    },
    async addPaymentConfirmed(data) {
      const payment = {
        ...data,
        _id: nanoid(6),
      };
      const response = await axios.post(
        `/api/invoice/add/payment/${this.invoice._id}`,
        payment,
      );
      if (response && response.status === 200 && response.data !== "overpaid") {
        this.$toast.success(
          this.$t("Payment") + " " + this.$t("Added").toLowerCase(),
        );
        await this.fetchInvoice(this.invoice._id);
        this.invoiceUpdated();
      } else {
        this.$toast.error(this.$t("ErrorAdding"));
      }
    },
    addPaymentCanceled() {
      this.paymentWidgetOpen = false;
      this.$emit("paymentCanceled");
    },
    async selectDropdownStatus(status) {
      if (status.value === this.invoice.status) return;
      if (status.selectableFromDropdown) {
        if (status.value === "paid") {
          this.paymentWidgetOpen = true;
        } else {
          const updatedInvoiceObject = {
            status: status.value,
          };
          const response = await axios.post(
            `/api/invoice/status/${this.invoice._id}`,
            updatedInvoiceObject,
          );
          if (response && response.status === 200) {
            this.$toast.success(
              this.$t("Invoice") +
                " " +
                this.$t("Status").toLowerCase() +
                " " +
                this.$t("Updated").toLowerCase(),
            );
            await this.fetchInvoice(this.invoice._id);
            this.invoiceUpdated();
          } else {
            this.$toast.error(this.$t("ErrorUpdating"));
          }
        }
        this.closeDropdown();
      }
    },
    formatDate(transactionDate) {
      if (transactionDate) {
        return moment(transactionDate).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.status-dropdown-container {
  display: flex;
  gap: 1px;
  flex-wrap: nowrap;
  position: relative;
  height: 20px;
}

.status-dropdown-container-text {
  padding: 0 4px;
  position: relative;
  width: fit-content;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  white-space: nowrap;
}

.additional-info-borders {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.selectable {
  cursor: pointer;
}

.not-selectable {
  cursor: default;
}

.type-dropdown {
  border-radius: 8px;
  position: absolute;
  top: 20px;
  background-color: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  outline: none;
  z-index: 7;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.04),
    0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
}

.type-dropdown p {
  border-radius: 4px;
  color: black;
  min-width: 116px;
  text-align: center;
}

.status-info-container {
  cursor: pointer;
  position: relative;
  padding: 2px 4px;
  border-radius: 0 4px 4px 0;
}

.status-info-container-hover:hover {
  background-color: black !important;
}

.status-info-container-hover:hover img {
  filter: invert(100%) sepia(0) saturate(0) hue-rotate(317deg) brightness(103%)
    contrast(102%);
}

.status-info-dropdown {
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  position: absolute;
  z-index: 8;
  top: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  gap: 12px;
  padding: 16px;
}

.status-info {
  border-radius: 8px 8px 0 0;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
}
</style>
