import { mapGetters, mapActions } from "vuex";
import axios from "axios";

const globalMixin = {
  computed: {
    ...mapGetters(["groups", "user"]),
  },
  methods: {
    ...mapActions(["getGroups"]),
    async updateModulePreferences(module, item, value) {
      return await axios.post(`/api/admin/preferences`, {
        module: module,
        item: item,
        value: value,
      });
    },
    async getGroupUsers(groupId) {
      await this.getGroups();
      return this.groups[groupId].users;
    },
  },
};

export default globalMixin;
