<template>
  <div class="notes-container">
    <div v-if="noteAddOpen" class="note-card">
      <textarea
        :placeholder="$t('AddNote') + '...'"
        v-model="newNoteContent"
        ref="noteAddTextArea"
        oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
        onfocus='this.style.height = "";this.style.height = this.scrollHeight + "px"'
        @focusout="addNote"
      />
    </div>
    <div class="notes-content" v-if="notes.length > 0">
      <div class="note-card" v-for="note of notes" :key="note.id">
        <img
          height="32"
          width="32"
          :src="require('@/assets/images/note_32_32.svg')"
          alt=""
        />
        <div
          v-if="editingNote !== note"
          @click="openNoteEdit(note)"
          class="note-card-container"
        >
          <div class="note-card-header">
            <p :class="{ 'note-public': note.isPublic }">
              {{
                note.isPublic
                  ? $t("Public").toUpperCase()
                  : $t("Private").toUpperCase()
              }}
            </p>
            <p>{{ formatDate(note.createdAt) }}</p>
          </div>
          <div class="note-card-content">{{ note.content }}</div>
        </div>
        <div class="note-edit-container" v-if="editingNote === note">
          <textarea
            ref="noteEditTextArea"
            oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
            onfocus='this.style.height = "";this.style.height = this.scrollHeight + "px"'
            @focusout="updateNote(note)"
            v-model="note.content"
          />
        </div>
        <div>
          <img
            @click="openNoteDropdown(note)"
            style="cursor: pointer"
            height="24"
            width="24"
            :src="require('@/assets/images/dot_menu_grey.svg')"
            alt=""
          />
          <div
            :style="dropdownStyle"
            tabindex="0"
            ref="noteDropdown"
            @focusout="noteDropdown = null"
            v-if="noteDropdown && noteDropdown.id === note.id"
            class="note-dropdown-menu"
          >
            <div @click="updateNoteStatus(note)" class="note-dropdown-item">
              <img
                height="20"
                width="20"
                :src="
                  note.isPublic
                    ? require('@/assets/images/toggle-active-24x24.svg')
                    : require('@/assets/images/toggle-inactive-20x20.svg')
                "
                alt=""
              />
              <p class="content-small">{{ $t("PublicEntry") }}</p>
            </div>
            <div @click="deleteNote(note)" class="note-dropdown-item">
              <img
                :src="require(`@/assets/images/red_trash_20_20.svg`)"
                alt=""
              />
              <p style="color: #ff1e24; height: 16px" class="content-small">
                {{ $t("Delete") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="notes-empty-container"
      v-if="notes.length < 1 && !noteAddOpen && !loading && showNoNotesMessage"
    >
      <p>{{ $t("NoNotesAdded") }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { nanoid } from "nanoid";

export default {
  name: "BaseNotes",
  props: {
    showNoNotesMessage: {
      type: Boolean,
      default: true,
    },
    view: {
      name: String,
      itemId: String,
      itemName: String,
      type: Object,
      required: true,
    },
    addNewNoteCounter: {
      type: Number,
      default: 0,
    },
    dropdownStyle: {
      type: Object,
    },
  },
  data() {
    return {
      notes: [],
      failedToGetNotes: false,
      editingNote: null,
      editingNoteBefore: null,
      noteAddOpen: false,
      noteDropdown: null,
      newNoteContent: "",
      loading: false,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    addNewNoteCounter() {
      if (!this.noteAddOpen) {
        this.noteAddOpen = true;
        this.$nextTick(() => {
          this.$refs.noteAddTextArea.focus();
        });
      }
    },
  },
  methods: {
    updateNoteStatus(note) {
      note.isPublic = !note.isPublic;
      this.sortNotes();
      this.saveNotes("update");
    },
    deleteNote(note) {
      this.editingNote = null;
      note.statusCode = 0;
      this.saveNotes("delete", note);
    },
    openNoteDropdown(note) {
      this.noteDropdown = note;
      this.$nextTick(() => {
        this.$refs.noteDropdown[0].focus();
      });
    },
    emitNotesCounter() {
      this.$emit("notesCounter", this.notes.length);
    },
    async initialize() {
      this.loading = true;
      await this.getNotes();
      this.loading = false;
      this.sortNotes();
    },
    openNoteEdit(note) {
      this.editingNote = note;
      this.editingNoteBefore = JSON.parse(JSON.stringify(this.editingNote));
      this.$nextTick(() => {
        this.$refs.noteEditTextArea[0].focus();
      });
    },
    updateNote(note) {
      if (this.editingNote.content === this.editingNoteBefore.content) {
        this.editingNote = null;
        this.editingNoteBefore = null;
        return;
      }
      let message = "update";
      if (note.content === "" || /^\s*$/.test(note.content)) {
        note.content = this.editingNoteBefore.content;
        note.statusCode = 0;
        message = "delete";
      }
      this.saveNotes(message, note);
      this.editingNote = null;
      this.editingNoteBefore = null;
    },
    isValidHttpUrl(string) {
      try {
        const url = new URL(string);
        return url.protocol === "http:" || url.protocol === "https:";
      } catch (err) {
        return false;
      }
    },
    addNote() {
      this.noteAddOpen = false;
      if (this.newNoteContent === "" || /^\s*$/.test(this.newNoteContent)) {
        this.newNoteContent = "";
        return;
      }
      const newNote = {
        content: this.newNoteContent,
        createdAt: moment().format(),
        id: nanoid(12),
        linkedTo: this.view.itemId,
        parentName: this.view.itemName,
        statusCode: 1,
      };
      this.notes.push(newNote);
      this.sortNotes();
      this.saveNotes("add");
      this.newNoteContent = "";
    },
    async saveNotes(message, note) {
      await axios
        .patch(`/api/${this.view.name}/notes/${this.view.itemId}`, this.notes)
        .then((response) => {
          if (response) {
            switch (message) {
              case "update":
                this.$toast.success(this.$t("Updated"));
                break;
              case "delete":
                this.$toast.success(this.$t("Deleted"));
                this.notes = this.notes.filter(
                  (listNote) => listNote.id !== note.id,
                );
                break;
              case "add":
                this.$toast.success(
                  this.$t("Note") + " " + this.$t("Added").toLowerCase(),
                );
            }
          }
        })
        .catch((e) => {
          console.error(e);
          switch (message) {
            case "update":
              this.$toast.error(this.$t("ErrorUpdating"));
              break;
            case "delete":
              this.$toast.error(this.$t("ErrorDeleting"));
              break;
            case "add":
              this.$toast.error(this.$t("ErrorAdding"));
          }
        });
      this.emitNotesCounter();
    },
    async getNotes() {
      await axios
        .get(`/api/${this.view.name}/notes/${this.view.itemId}`)
        .then((response) => {
          if (response && response.data) {
            this.notes = response.data;
            this.emitNotesCounter();
          } else {
            console.log(response);
            this.failedToGetNotes = true;
          }
        })
        .catch((e) => {
          console.error(e);
          this.failedToGetNotes = true;
        });
    },
    sortNotes() {
      this.notes = this.notes.sort(
        (a, b) =>
          moment(a.createdAt, "DD/MM/YYYY").valueOf() -
          moment(b.createdAt, "DD/MM/YYYY").valueOf(),
      );
    },
    formatDate(date) {
      const formatDate = moment(date).format("DD.MM.YYYY");
      if (formatDate === "Invalid date") {
        date = date.replaceAll("/", ".");
        return " • " + date;
      } else {
        return " • " + formatDate;
      }
    },
  },
};
</script>

<style scoped>
.notes-content,
.notes-container {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.note-card {
  min-height: 52px;
  padding: 8px 0;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
}

.note-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.note-card-header {
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
}

.note-card-header p {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #75787a;
}

.note-card-content {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  word-break: break-word;
}

/* .break-all {
  word-break: break-all;
} */

.note-edit-container {
  width: 100%;
}

.note-card textarea {
  width: 100%;
  height: 36px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  padding: 7px 11px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  overflow: hidden;
}

.note-card textarea:focus {
  outline: none;
}

.note-card textarea::-webkit-resizer {
  display: none;
}

.note-edit-container textarea {
  width: 100%;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  padding: 7px 11px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  overflow: hidden;
}

.note-edit-container textarea:focus {
  outline: none;
}

.note-edit-container textarea::-webkit-resizer {
  display: none;
}

.note-public {
  color: #0aaf60 !important;
}

.note-dropdown-menu {
  position: absolute;
  z-index: 50;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.04),
    0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.note-dropdown-menu:focus {
  outline: none;
}

.note-dropdown-item {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  height: 40px;
  background: #ffffff;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  align-items: center;
}

.note-dropdown-item:first-child {
  padding: 12px 12px 8px 12px;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid #e6e8ec;
}

.note-dropdown-item:last-child {
  padding: 8px 12px 12px 12px;
  border-radius: 0 0 8px 8px;
  border-bottom: 1px solid #e6e8ec;
}

.notes-empty-container p {
  color: rgb(117, 120, 122);
}
</style>
