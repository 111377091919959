<template>
  <div
    class="flex-row"
    :id="dropdownId"
    @click="rowClick($event)"
    v-click-outside="() => (this.showDropdown = false)"
    style="display: table; width: 100%; margin-top: 16px"
    v-bind:class="{
      marginTop: label === 'Contact' || label === 'Owner',
    }"
  >
    <div class="dropdown" style="width: 100%">
      <div
        class="dropbtn-hover"
        style="width: 100%"
        v-bind:class="{
          dropbtn: label !== 'Owner',
          'dropbtn-two':
            (label === 'Owner' || label === 'Project') && label !== 'Contact',
          'fixed-height': !selectedItem || (!selectedItem && label === 'Owner'),
        }"
      >
        <div
          @click="openPanel(label, selectedItem)"
          class="label-and-item-wrapper"
          style="height: 20px"
        >
          <p
            class="justify-center"
            v-bind:class="{
              '': label === 'Owner',
              'cursor-class': label !== 'Owner',
            }"
            style="
              display: flex;
              align-items: center;
              height: 20px;
              width: 20px;
            "
          >
            <img
              class="icon-img"
              v-bind:src="require(`../../assets/images/${icon}`)"
              alt=""
            />
          </p>
        </div>
        <v-col
          @click="openPanel(label, selectedItem)"
          v-bind:class="{
            '': label === 'Owner',
            'cursor-class': label !== 'Owner',
          }"
          style="
            text-align: left;
            display: inline-grid;
            min-height: 32px;
            width: 100%;
          "
        >
          <span
            class="entry-field-label"
            style="align-items: center; width: 100%; height: 16px"
          >
            <span
              :class="{
                'entry-field-label': selectedItem,
                'entry-field-label padding-content':
                  !selectedItem || !selectedItem[displayKey],
                required: fieldRequired,
              }"
            >
              <label>
                {{ $t(label) }}
              </label>
            </span>
            <span
              style="width: 16px; height: 16px; margin-bottom: 0px"
              v-if="
                !fieldRequired &&
                allowDelete &&
                selectedItem &&
                selectedItem[displayKey]
              "
            >
              <img
                @click="
                  removeItem();
                  showDropdown = true;
                "
                class="icon-img"
                v-bind:src="require(`@/assets/images/close.svg`)"
                style="background-color: #e6e8ec; border-radius: 4px"
                alt=""
              />
            </span>
          </span>

          <div class="entry-field-value flex-row">
            {{
              selectedItem && selectedItem[displayKey]
                ? selectedItem[displayKey]
                : ""
            }}
          </div>
        </v-col>
      </div>
      <div
        :id="dropdownId"
        :style="
          'display: none; z-index:1; display: ' +
          (showDropdown && !disabled ? 'flex' : 'none')
        "
        class="dropdown-single scrollable"
      >
        <div class="flex-row">
          <v-text-field
            autocomplete="off"
            outlined
            v-model="search"
            hide-details
            id="search-bar"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search') + '...'"
            shaped
            style="background: white; border: 0px !important"
          >
          </v-text-field>
        </div>
        <div class="separator"></div>
        <div
          class="flex-row dropdown-item-wrapper"
          v-for="item in foundItems"
          :key="item._id"
          @click="selectItem(item)"
        >
          <!--        <img-->
          <!--          v-if="selectedItem._id === item._id"-->
          <!--          :src="require('@/assets/images/checkmark.svg')"-->
          <!--          alt=""-->
          <!--        />-->

          <p
            class="small-size-text"
            :style="
              selectedItem && selectedItem[valueKey] === item[valueKey]
                ? 'font-weight: bold'
                : null
            "
          >
            {{ item[displayKey] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
//import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel";

export default {
  name: "TaskRowWithDropdown",
  components: {}, //ClientSidepanel },
  props: [
    "items",
    "displayKey",
    "valueKey",
    "icon",
    "selectedItem",
    "label",
    "allowDelete",
    "fieldRequired",
    "headDisabled",
  ],
  data: () => ({
    dropdownId: null,
    dropdownItems: null,
    showDropdown: false,
    dropdownOpen: false,
    disabled: false,
    contactPanel: false,
    search: null,
    originalArr: [],
    foundItems: [],
  }),
  created() {},
  mounted() {
    this.dropdownId = nanoid(10);
    this.dropdownItems = [...this.items];
  },

  watch: {
    items() {
      this.dropdownItems = [...this.items];
      this.originalArr = [...this.dropdownItems];
      this.foundItems = this.originalArr;
    },
    search() {
      if (this.search) {
        let searchValue = this.search.toLowerCase();
        let tempItemsTitle = this.originalArr.filter(
          (item) => item.name.toLowerCase().indexOf(searchValue) > -1,
        );
        this.foundItems = [...tempItemsTitle];
      } else {
        this.search = null;
        this.foundItems = this.originalArr;
      }
    },
  },
  beforeUnmount() {
    window.onclick = null;
  },
  methods: {
    selectItem(item) {
      this.showDropdown = false;
      this.disabled = true;

      this.$emit("itemSelected", item);
    },
    rowClick(e) {
      if (this.headDisabled) return;
      if (this.disabled) {
        this.disabled = false;
      } else if (e.target.id !== "search-bar") {
        this.search = null;
        this.showDropdown = !this.showDropdown;
      }
    },
    removeItem() {
      this.$emit("itemSelected", { displayKey: "", valueKey: "" });
    },
    openPanel(label, item) {
      if (label === "Project" && this.headDisabled) {
        this.$router
          .push({
            name: "ProjectGeneral",
            params: { id: item.id },
          })
          .then(() => {});
      } else if (label === "Contact" && this.headDisabled) {
        this.$emit("contactPanelOpen", item);
        this.contactPanel = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.required label::after {
  font-weight: 400;
  font-size: 16px;
}

.entry-field-label {
  color: $grey950 !important;
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.entry-field-value {
  margin-top: 0 !important;
  height: 16px;
  line-height: 16px;
  display: table;
  margin-right: 20px;
}

.icon-img {
  margin: 0px !important;
}

.col {
  padding: 0;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  margin-top: 12px;
}

.padding-content {
  padding-top: 2px;
}
.fixed-height {
  height: 20px;
}

.dropbtn {
  //background-color: white;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  /* identical to box height, or 133% */
  padding: 0;
  display: flex;
  height: 100%;
  text-align: center;
  flex-direction: row;
  /* black */
  cursor: default;
  color: #000000;
  box-sizing: border-box;
  justify-content: space-between;

  img {
    margin-left: 8px;
  }
}
:deep(.v-input--is-focused fieldset) {
  border: 0px;
}
.margin-content {
  margin-top: 16px !important;
}
.marginTop {
  margin-top: 6px !important;
}

.dropbtn-two {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  padding: 0;
  display: flex;
  height: 100%;
  text-align: center;
  flex-direction: row;
  //align-items: unset;
  cursor: default;
  color: #000000;
  box-sizing: border-box;
  justify-content: space-between;

  img {
    margin-left: 8px;
  }
}
.drobtn-white {
  background-color: white;
}
.dropbtn-hover {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}
.cursor-class {
  cursor: pointer;
}

.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}
.small-size-text {
  font-size: 12px;
  line-height: 16px;
  align-content: center;
}

.dropdown {
  display: inline-block;
}

.dropdown-item-wrapper {
  cursor: pointer;
  padding: 12px;
  margin-top: 0 !important;
  &:hover {
    background-color: #f4f5f7;
  }
}

:deep(
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
        .v-input--has-state
      )
      > .v-input__control
      > .v-input__slot
      fieldset
  ) {
  border: 0px !important;
  border-color: white !important;
}

.dropdown-single {
  position: absolute;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;

  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  //min-height: 100%;
  margin-top: 12px;

  p {
    color: black;
    text-decoration: none;
    display: block;
  }
}

.not-selected,
.selected {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
}

.label-and-item-wrapper {
  width: 32px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}
:deep(.v-text-field .v-input__control .v-input__slot) {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
:deep(.v-icon.v-icon) {
  padding-bottom: 16px !important;
}
</style>
