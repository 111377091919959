<template>
  <div
    @keyup.esc="closePanel"
    @click="closePanel($event)"
    id="offers-sidepanel-overlay"
    class="sidepanel-overlay"
  >
    <div id="offers-sidepanel" class="sidepanel">
      <div class="sidepanel-header">
        <h4>{{ $t("SendOffer") }}</h4>
      </div>
      <div
        v-if="loaded"
        class="sidepanel-content"
        :key="'offers-' + offersUpdater"
      >
        <div class="no-results-wrapper" v-if="items.length < 1">
          <p class="no-results-heading">{{ $t("NoOffersToShow") }}</p>
          <p class="no-results-subheading">
            {{
              $t("ToCreateAnOfferMoveAContactToTheOffersColumnUnderAListing")
            }}
          </p>
        </div>
        <div
          class="offer-card"
          :class="{
            'offer-card-first': index === 0,
            'offer-card-middle': index > 0 && index < items.length - 1,
            'offer-card-last': index === items.length - 1,
            'offer-card-only': items.length === 1,
          }"
          v-for="(offer, index) in items"
          :key="offer.leadId"
        >
          <div
            style="
              display: flex;
              height: 32px;
              width: 100%;
              flex-wrap: nowrap;
              justify-content: space-between;
            "
          >
            <div style="display: flex; flex-wrap: nowrap; gap: 8px">
              <div
                @click="openClientSidepanel(offer)"
                style="display: flex; cursor: pointer; align-items: center"
                class="content-normal"
              >
                {{ offer.name }}
              </div>
            </div>
            <div style="display: flex; gap: 8px">
              <div
                @click="buttonClicked(offer)"
                :style="listingsButtonWidth"
                :id="'dropdown-button-' + index"
                class="dropdown-button"
                :class="{
                  'dropdown-button-open': offer.dropdownOpen,
                }"
              >
                <img
                  v-if="!offer.dropdownOpen"
                  :src="require('@/assets/images/chevron_square_16_16.svg')"
                  alt=""
                  style="height: 16px; width: 16px"
                />
                <img
                  v-if="offer.dropdownOpen"
                  :src="
                    require('@/assets/images/chevron_square_light_16_16.svg')
                  "
                  alt=""
                  style="height: 16px; width: 16px"
                />
                <p v-if="offer.listings.length === 1" class="content-small">
                  {{
                    offer.listings.length + " " + $t("Listing").toLowerCase()
                  }}
                </p>
                <p v-if="offer.listings.length > 1" class="content-small">
                  {{
                    offer.listings.length + " " + $t("Listingst").toLowerCase()
                  }}
                </p>
              </div>

              <div class="actions-label content-small">
                {{ $t("ShareOffer") }}
              </div>

              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on" class="tooltip-item">
                      <div
                        class="icon-wrap mail-icon-wrap"
                        @click="sendSingleOffer(offer)"
                      >
                        <img
                          :src="require('@/assets/images/email.svg')"
                          alt=""
                        />
                      </div>
                    </v-list-item>
                  </template>
                  <span>
                    {{ $t("Send") + " " + $t("e-mail").toLowerCase() }}
                  </span>
                </v-tooltip>
              </div>

              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on" class="tooltip-item">
                      <div
                        class="icon-wrap link-icon-wrap"
                        @click="copyLink(offer.leadId)"
                      >
                        <img
                          :src="require('@/assets/images/add-link.svg')"
                          alt=""
                          class="img20"
                        />
                      </div>
                    </v-list-item>
                  </template>
                  <span>
                    {{ $t("Copy") + " " + $t("Link").toLowerCase() }}
                  </span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div style="margin-top: 24px" v-if="offer.dropdownOpen">
            <div
              v-for="(item, index) in offer.listings"
              :key="item.id + index"
              style="display: flex; justify-content: space-between"
              :class="{
                'offer-card-first': index === 0,
                'offer-card-middle':
                  index > 0 && index < offer.listings.length - 1,
                'offer-card-last': index === offer.listings.length - 1,
                'offer-card-only': offer.listings.length === 1,
              }"
            >
              <div @click="openListingsSidepanel(item)" style="cursor: pointer">
                <p
                  :class="{ 'selected-listing': listingId === item.id }"
                  style="padding: 13px 11px"
                  class="content-small"
                >
                  {{ item.name }}
                </p>
              </div>
              <div
                @click="removeUserFromOffer(offer, item)"
                style="
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 4px;
                  align-items: center;
                  cursor: pointer;
                "
              >
                <img :src="require('@/assets/images/close_20_20.svg')" />
                <p
                  style="
                    padding: 11px 12px 11px 0;
                    color: #ff1e24;
                    font-weight: bold;
                  "
                  class="content-small"
                >
                  {{ $t("Remove").toLowerCase() }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ListingSidepanel
      @overlayClicked="closeListingSidepanel"
      v-if="listingId"
      :listing-preview-id="listingId"
      :second-sidepanel="true"
    />
  </div>
</template>

<script>
import axios from "axios";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import contactApi from "@/http/contact";
import { mapActions } from "vuex";

export default {
  name: "OffersSidepanel",
  props: [],
  components: {
    // TheCheckbox,
    ListingSidepanel,
  },
  data() {
    return {
      loaded: false,
      items: [],
      sendToButtonText: "sendToAll",
      listingsButtonWidth: "",
      listingId: null,
      mail: {
        to: "",
        subject: "",
        body: "",
      },
      offersUpdater: 0,
    };
  },
  created() {
    this.initializeOffers();
  },
  methods: {
    ...mapActions(["updateListings", "removeLeadInOffersCount"]),
    copyLink(leadId) {
      if (!leadId) {
        this.$toast.error(this.$t("Error"));
        return;
      }

      navigator.clipboard.writeText(
        `${window.location.origin}/offers/${leadId}`,
      );
      this.$toast.success(this.$t("LinkCopied"));
    },
    closePanel(e) {
      if (this.listingId && e.target.id === "listing-overlay") {
        this.closeListingSidepanel();
        return;
      }
      if (!e) {
        this.$emit("closePanel");
      } else if (e.target.id === "offers-sidepanel-overlay") {
        this.$emit("closePanel");
      }
    },
    openClientSidepanel(offer) {
      this.closeListingSidepanel();
      console.log(offer);
      this.$emit("openClientPanel", offer.leadId);
    },
    closeListingSidepanel() {
      console.log("OLEME SININ");
      this.listingId = null;
      let sidepanel = document.getElementById("offers-sidepanel");
      sidepanel.style.width = "640px";
      sidepanel.style.borderRight = "";
      sidepanel.style.right = "0";
    },
    async openListingsSidepanel(item) {
      if (this.listingId === item.id) {
        await this.closeListingSidepanel();
        return;
      }
      this.listingId = item.id;
      console.log(item);
      let sidepanel = document.getElementById("offers-sidepanel");
      sidepanel.style.transition = "right 0.5s ease-in-out";
      sidepanel.style.borderRight = "8px solid #e6e8ec";
      sidepanel.style.right = "638px";

      this.listingId = item.id;
    },
    selectOffer(offer) {
      offer.selected = !offer.selected;
      if (this.items.find((offer) => offer.selected)) {
        if (this.sendToButtonText !== "sendToSelected") {
          this.sendToButtonText = "sendToSelected";
        }
      } else {
        if (this.sendToButtonText !== "sendToAll") {
          this.sendToButtonText = "sendToAll";
        }
      }
    },
    buttonClicked(offer) {
      offer.dropdownOpen = !offer.dropdownOpen;
    },
    async removeUserFromOffer(offer, item) {
      const funnel = {
        leadId: offer.leadId,
        step: "Lost / Archived",
        listingId: item.id,
        listingName: item.name,
        previousStep: "Offers",
      };

      offer.listings.forEach((listing, index) => {
        if (listing.id === item.id) {
          offer.listings.splice(index, 1);
        }
      });
      if (offer.listings.length === 0) {
        this.items = this.items.filter((item) => item.leadId !== offer.leadId);
        await this.removeLeadInOffersCount(offer.leadId);
      }

      await contactApi.updateFunnel(offer.leadId, funnel);
      await axios.patch(`/api/listing/${item.id}`, funnel).then((res) => {
        console.log(res);
      });
      await this.updateListings();
    },
    async sendOffersToClients() {
      let selectedCount = 0;
      for (const offer of this.items) {
        if (offer.selected) {
          selectedCount++;
          await this.sendSingleOffer(offer);
        }
      }
      if (selectedCount === 0) {
        for (const offer of this.items) {
          await this.sendSingleOffer(offer);
        }
      }
    },
    async sendSingleOffer(offer) {
      if (offer.email) {
        this.mail.to = offer.email;
      } else {
        this.mail.to = "";
      }
      await axios
        .patch(`/api/contact/offer/${offer.leadId}`)
        .then((response) => {
          if (response.data && response.data.url && response.status === 200) {
            this.mail.subject = "Sinu personaalsed pakkumised";
            this.mail.body = `Tere ${
              offer.name
            }, siin on sulle sinu personaalsed pakkumised Palun kliki lingil ${encodeURI(
              response.data.url,
            )}`;
            window.open(
              `mailto:${this.mail.to}?subject=${this.mail.subject}&body=${this.mail.body}`,
            );
          } else {
            console.log("(╯°□°)╯︵ ┻━┻");
          }
        })
        .catch((err) => {
          console.error(err);
        });
      this.offersUpdater++;
    },
    async initializeOffers() {
      const response = await axios.get(`/api/listings/offers`);
      let listings = response.data;
      console.log("OFFFERs", listings);
      listings = listings.filter((listing) => {
        if (listing.funnels.Offers) {
          return listing.funnels.Offers.length > 0;
        }
      });
      const offerObjects = [];
      const customerIds = [];
      listings.forEach((offer) => {
        offer.funnels.Offers.forEach((funnel) => {
          if (offerObjects.find((object) => object.leadId === funnel.leadId)) {
            offerObjects.forEach((offerObject, index) => {
              if (
                offerObject.leadId === funnel.leadId &&
                funnel.statusCode !== 0
              ) {
                offerObjects[index]["listings"].push({
                  id: funnel.listingId,
                  name: offer.listingName,
                });
              }
            });
          } else if (funnel.statusCode !== 0) {
            const offerObject = {};
            customerIds.push(funnel.leadId);
            offerObject["leadId"] = funnel.leadId;
            offerObject["name"] = funnel.leadName;
            offerObject["listings"] = [];
            offerObject["listings"].push({
              id: funnel.listingId,
              name: offer.listingName,
            });
            offerObject["dropdownOpen"] = false;
            offerObject["selected"] = false;
            offerObjects.push(offerObject);
          }
        });
      });
      this.items = offerObjects;
      console.log("Items", this.items);
      this.loaded = true;
      await axios
        .post(`/api/contact/offer-data/`, { ids: customerIds })
        .then((res) => {
          const data = res.data;
          if (data.length > 0) {
            data.forEach((customer) => {
              const item = this.items.find(
                (item) => item.leadId === customer.customerId,
              );
              item["email"] = customer.email ? customer.email : "";
              item["url"] = customer.offersUrl ? customer.offersUrl : "";
            });
          }
        });
      this.offersUpdater++;
      this.getListingsButtonWidth();
    },
    getListingsButtonWidth() {
      let width = 0;
      this.items.forEach((item, index) => {
        const elementWidth = document.getElementById(
          "dropdown-button-" + index,
        ).offsetWidth;
        if (width < elementWidth) {
          width = elementWidth;
        }
      });
      this.listingsButtonWidth = `width: ${width}px; white-space: nowrap;`;
    },
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.documentElement.style.overflow = "visible";
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.tooltip-item {
  min-height: unset;
  padding: 0;
}

.actions-label {
  margin-left: 16px;
  display: flex;
  justify-content: center;
}

.mail-icon-wrap:hover {
  border: none;
  background: $orange;
  img {
    filter: invert(1);
  }
}

.link-icon-wrap:hover {
  border: none;
  background: $blue;
  img {
    filter: invert(1);
  }
}

.icon-wrap {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: 1px solid #e6e8ec;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidepanel-overlay {
  position: fixed;
  z-index: 1200000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.sidepanel {
  position: absolute;
  background-color: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  width: 640px;
  border-top: 1px solid #e6e8ec;
  max-width: 640px;
  z-index: 101;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.sidepanel-header {
  height: 88px;
  padding: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.sidepanel-content {
  padding: 40px 32px;
  height: calc(100% - 88px);
  overflow-y: scroll;
}

.sidepanel-content-button-container {
  margin-bottom: 32px;
}

.offer-card {
  padding: 15px 16px;
}

.offer-card-first {
  border: 1px solid #e6e8ec;
  border-radius: 8px 8px 0 0;
}

.offer-card-middle {
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
}

.offer-card-last {
  border-bottom: 1px solid #e6e8ec;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-radius: 0 0 8px 8px;
}

.offer-card-only {
  border: 1px solid #e6e8ec;
  border-radius: 8px 8px 8px 8px;
}

.dropdown-button {
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  gap: 8px;
  height: 32px;
  padding: 7px 14px;
  cursor: pointer;
}

.dropdown-button-open {
  background-color: black;
}

.dropdown-button-open p {
  color: white;
}

.selected-listing {
  background-color: rgb(255, 92, 1);
  color: white !important;
  border-radius: 4px;
}
.no-results-heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #75787a;
  margin: 16px 0px;
}
.no-results-subheading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  color: #75787a;
  margin: 16px 0px;
}
</style>
