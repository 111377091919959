<template>
  <div
    :key="refreshKey"
    style="display: flex; flex-direction: column; gap: 12px"
  >
    <div class="invoice-sharing-row" style="align-items: start">
      <BaseInput
        v-model="invoiceSum"
        :placeholder="$t('SumBeforeVAT')"
        :error="error.showError"
        :error-message="error.errorMessage"
        type="number"
        :required="true"
        :icon-right="'euroSign.svg'"
      />
      <div style="padding: 12px">
        <div class="invoice-sharing-row-sum content-semibold">
          {{
            `${$t("TotalSumWithVAT")}: ${$nMapper.price(invoiceSumVat, "€")}`
          }}
        </div>
      </div>
    </div>
    <div v-if="invoiceSharedUsers.length > 0" class="invoice-sharing-toggle">
      <BaseToggle
        v-model="useAmountsInsteadOfPercentage"
        @update:modelValue="(value) => updateInvoicePreferences(value)"
        :label="$t('SplitUsingAmounts')"
      />
    </div>
    <div class="invoice-sharing-row">
      <GroupOrUserSelect
        v-model="localSelectedUser"
        @update:modelValue="(value) => userSelected(value)"
        :allowGroupSelection="false"
        :allow-empty="false"
        size="large"
        labelTranslation="InvoiceOwner"
      />
      <div
        class="invoice-sharing-row-data"
        v-if="invoiceSharedUsers.length > 0 && useAmountsInsteadOfPercentage"
      >
        <BaseInput
          v-model="invoiceOwner.share.sum"
          @click="editUserValues(invoiceOwner)"
          @update:modelValue="checkTypeChange(invoiceOwner)"
          @blur="setBackToEqual(invoiceOwner)"
          :placeholder="$t('Share')"
          type="number"
          :icon-right="'euroSign.svg'"
        />
      </div>
      <div
        v-if="invoiceSharedUsers.length > 0 && !useAmountsInsteadOfPercentage"
        class="invoice-sharing-row-shared"
      >
        <BaseInput
          v-model="invoiceOwner.share.percentage"
          @click="editUserValues(invoiceOwner)"
          @update:modelValue="checkTypeChange(invoiceOwner)"
          @blur="setBackToEqual(invoiceOwner)"
          :placeholder="$t('Share')"
          type="number"
          :icon-right="'percentage.svg'"
        />
        <div style="display: flex; align-items: center; padding: 12px">
          <div
            class="content-semibold"
            style="
              width: fit-content;
              height: 24px;
              padding: 2px 4px;
              background-color: #fff0d4;
              border-radius: 4px;
              white-space: nowrap;
            "
            v-if="invoiceOwner.share.sum"
          >
            {{ $nMapper.price(formatFixed(invoiceOwner.share.sum, 2), "€") }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="invoiceSharedUsers" class="flex-col">
      <div
        class="invoice-sharing-row"
        v-for="(invoiceUser, index) of invoiceSharedUsers"
        :key="'invoice-additional-user-' + index"
      >
        <GroupOrUserSelect
          :modelValue="
            invoiceUser._id ? { type: 'user', value: invoiceUser._id } : null
          "
          @update:modelValue="(value) => userSelected(value, index)"
          :allowGroupSelection="false"
          emptyValueLabelTranslation="ThirdParty"
          size="large"
          labelTranslation="BrokerName"
        />
        <div v-if="useAmountsInsteadOfPercentage">
          <BaseInput
            v-model="invoiceOwner.share.sum"
            :placeholder="$t('Share')"
            @click="editUserValues(invoiceUser, index)"
            @update:modelValue="checkTypeChange(invoiceUser, index)"
            @blur="setBackToEqual(invoiceUser)"
            type="number"
            :icon-right="'euroSign.svg'"
          />
        </div>
        <div v-else class="invoice-sharing-row-shared">
          <BaseInput
            :placeholder="$t('Share')"
            v-model="invoiceUser.share.percentage"
            @click="editUserValues(invoiceUser, index)"
            @update:modelValue="checkTypeChange(invoiceUser, index)"
            @blur="setBackToEqual(invoiceUser)"
            type="number"
            :icon-right="'percentage.svg'"
          />
          <div style="display: flex; align-items: center; padding: 12px">
            <div
              class="content-semibold"
              style="
                width: fit-content;
                height: 24px;
                padding: 2px 4px;
                background-color: #fff0d4;
                border-radius: 4px;
                white-space: nowrap;
              "
              v-if="invoiceUser.share.sumVAT"
            >
              {{ $nMapper.price(formatFixed(invoiceUser.share.sum, 2), "€") }}
            </div>
          </div>
        </div>
        <div
          @click="removeUserFromInvoice(index)"
          style="
            width: 24px;
            position: absolute;
            right: -40px;
            height: 24px;
            cursor: pointer;
          "
        >
          <img
            height="24"
            width="24"
            :src="require('@/assets/images/close_red_24_24.svg')"
            alt=""
          />
        </div>
      </div>
    </div>
    <button @click="addUserToInvoice" class="invoice-sharing-add-user">
      <img :src="require('@/assets/images/users.svg')" alt="" />
      <span class="content-normal">{{ $t("+AddAnotherBroker") }}</span>
    </button>
  </div>
</template>

<script>
import BaseInput from "@/components/common/BaseInput";
import BaseToggle from "@/components/common/BaseToggle";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "InvoiceSharing",
  components: { BaseToggle, BaseInput, GroupOrUserSelect },
  props: ["project", "error", "date"],
  computed: {
    ...mapGetters(["invoice", "user"]),
  },
  mounted() {
    if (this.invoice) {
      this.setupEdit();

      let brokerObj = {
        owner: this.localSelectedUser,
        sharedUsers: this.invoiceSharedUsers,
        sum: this.invoiceSum,
        sumVAT: this.invoiceSumVat,
        useAmountsInsteadOfPercentage: this.useAmountsInsteadOfPercentage,
      };
      this.$emit("changed", brokerObj);
      setTimeout(() => {
        this.refreshComponent();
        this.$emit("changed", brokerObj);
      }, 500);
    } else {
      this.setupAdd();
    }
  },
  data() {
    return {
      editMode: false,
      invoiceSum: null,
      invoiceSumVat: null,
      useAmountsInsteadOfPercentage: false,
      currentlyEditingUser: null,
      defaultObjects: {
        broker: {
          name: "",
          _id: null,
          email: "",
          share: {
            percentage: "",
            percentageFull: "",
            sum: 0,
            sumFull: 0,
            sumVAT: 0,
            sumVATFull: 0,
            type: "equal",
          },
        },
      },
      invoiceOwner: {
        name: "",
        _id: null,
        email: "",
        share: {},
      },
      invoiceSharedUsers: [],
      VATMultiplier: {
        EST: {
          2023: 1.2,
          2024: 1.22,
        },
      },
      localSelectedUser: {
        name: "",
        value: "",
      },
      refreshKey: 0,
    };
  },
  watch: {
    invoiceSum(value) {
      const invoiceDateYear = moment(this.date).year();
      if (Math.sign(value) === 1) {
        let calculateValue = parseFloat(value).toFixed(2);
        this.invoiceSumVat = this.formatFixed(
          calculateValue *
            (invoiceDateYear > 2023 || null || isNaN(invoiceDateYear)
              ? this.VATMultiplier.EST[2024]
              : this.VATMultiplier.EST[2023]),
          2,
        );
      } else {
        this.invoiceSumVat = 0;
      }
      this.recalculateShares();
    },
    date() {
      const invoiceDateYear = moment(this.date).year();
      if (Math.sign(this.invoiceSum) === 1) {
        let calculateValue = parseFloat(this.invoiceSum).toFixed(2);
        this.invoiceSumVat = this.formatFixed(
          calculateValue *
            (invoiceDateYear > 2023 || null || isNaN(invoiceDateYear)
              ? this.VATMultiplier.EST[2024]
              : this.VATMultiplier.EST[2023]),
          2,
        );
      } else {
        this.invoiceSumVat = 0;
      }
      this.recalculateShares();
    },
    project: {
      handler() {
        // this.invoiceSharedUsers = [];
        for (const brokerId in this?.project?.access?.users) {
          const broker = this.project.access.users[brokerId];
          const emptyBroker = JSON.parse(
            JSON.stringify(this.defaultObjects.broker),
          );
          emptyBroker._id = brokerId;
          if (broker.ownershipType === "owner") {
            this.invoiceOwner = emptyBroker;
          } else {
            this.invoiceSharedUsers.push(emptyBroker);
          }
        }
        this.recalculateShares();
      },
    },
    localSelectedUser: {
      handler(newValue) {
        this.invoiceOwner.name = newValue.name;
        this.invoiceOwner.userId = newValue.value;
        this.$emit("changed", {
          owner: newValue,
          sharedUsers: this.invoiceSharedUsers,
          sum: this.invoiceSum,
          sumVAT: this.invoiceSumVat,
          useAmountsInsteadOfPercentage: this.useAmountsInsteadOfPercentage,
        });
      },
      deep: true,
      immediate: true,
    },
    invoiceOwner: {
      handler(newValue) {
        this.invoiceOwner.name = newValue.name;
        this.invoiceOwner.userId = newValue.value;
        this.$emit("changed", {
          owner: newValue,
          sharedUsers: this.invoiceSharedUsers,
          sum: this.invoiceSum,
          sumVAT: this.invoiceSumVat,
          useAmountsInsteadOfPercentage: this.useAmountsInsteadOfPercentage,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["getUser"]),
    refreshComponent() {
      this.refreshKey += 1;
    },
    setupEdit() {
      this.editMode = true;
      let invoiceCopy = JSON.parse(JSON.stringify(this.invoice));
      this.invoiceOwner = invoiceCopy.broker;
      this.invoiceOwner.value = invoiceCopy.broker.userId;
      this.invoiceOwner.type = "user";
      this.invoiceSharedUsers = invoiceCopy.sharedBrokers;
      this.invoiceSum = invoiceCopy.sum;
      this.invoiceSumVat = invoiceCopy.sumVAT;
      this.localSelectedUser.name = this.invoiceOwner.name;
      this.localSelectedUser.value = this.invoiceOwner.value;
      this.localSelectedUser.type = "user";
      // preference
      if (this.invoice.useAmountsInsteadOfPercentage) {
        this.useAmountsInsteadOfPercentage = true;
      }
    },
    async setupAdd() {
      this.invoiceOwner = JSON.parse(
        JSON.stringify(this.defaultObjects.broker),
      );
      this.invoiceOwner.value = this.user._id;
      this.invoiceOwner.type = "user";
      this.localSelectedUser.name = this.invoiceOwner.name;
      this.localSelectedUser.value = this.invoiceOwner.value;
      this.localSelectedUser.type = "user";
      // preference
      await this.getUser({
        userId: this.user._id,
        arrayOfFieldKeys: ["preferences"],
      });
      if (
        this.user.preferences?.invoices?.useAmountInsteadOfPercentage?.enabled
      ) {
        this.useAmountsInsteadOfPercentage = true;
      }
    },
    setBackToEqual(broker) {
      if (this.useAmountsInsteadOfPercentage) {
        if (!broker.share.sum) {
          broker.share.type = "equal";
        }
      } else {
        if (!broker.share.percentage) {
          broker.share.type = "equal";
        }
      }
      this.currentlyEditingUser = null;
      this.recalculateShares();
    },
    userSelected(selection, index) {
      if (selection.type === "user") {
        if (index !== undefined) {
          this.invoiceSharedUsers[index]._id = selection.value;
        } else {
          this.invoiceOwner._id = selection.value;
        }
      }
    },
    addUserToInvoice() {
      this.invoiceSharedUsers.push(
        JSON.parse(JSON.stringify(this.defaultObjects.broker)),
      );
      this.recalculateShares();
    },
    removeUserFromInvoice(index) {
      this.invoiceSharedUsers.splice(index, 1);
      this.recalculateShares();
    },
    editUserValues(user, index) {
      this.currentlyEditingUser = JSON.parse(JSON.stringify(user));
      this.currentlyEditingUser.index = index;
    },
    checkTypeChange(user, index) {
      if (
        this.currentlyEditingUser?._id === user._id &&
        index === this.currentlyEditingUser?.index
      ) {
        user.share.type = "custom";
        if (this.invoiceSum) {
          // Percentage can be calculated.
          if (this.useAmountsInsteadOfPercentage) {
            let fullPercentage =
              (parseFloat(user.share.sum) / parseFloat(this.invoiceSum)) * 100;
            user.share.percentage = this.formatFixed(fullPercentage, 2);
            user.share.percentageFull = fullPercentage;
          } else {
            if (user.share.percentage) {
              let percentage = parseFloat(user.share.percentage);
              if (percentage > 100) {
                user.share.percentage = 100;
                user.share.percentageFull = 100;
              } else if (percentage < 0 || isNaN(percentage)) {
                user.share.percentage = 0;
                user.share.percentageFull = 0;
              } else {
                user.share.percentageFull = parseFloat(user.share.percentage);
              }
            }
          }
        } else if (!this.invoiceSum && !this.useAmountsInsteadOfPercentage) {
          if (user.share.percentage) {
            let percentage = parseFloat(user.share.percentage);
            if (percentage > 100) {
              user.share.percentage = 100;
              user.share.percentageFull = 100;
            } else if (percentage < 0 || isNaN(percentage)) {
              user.share.percentage = 0;
              user.share.percentageFull = 0;
            } else {
              user.share.percentageFull = parseFloat(user.share.percentage);
            }
          }
        }
      }
      this.recalculateShares();
    },
    recalculateShares() {
      if (this.invoiceSharedUsers.length > 0) {
        let remainingPercentage = 100;
        let remainingInvoiceSum = this.invoiceSum
          ? parseFloat(this.invoiceSum)
          : 0;
        let remainingInvoiceSumVat = this.invoiceSumVat
          ? parseFloat(this.invoiceSumVat)
          : 0;

        // 1. Dividing users between division types.
        const equalUsers = [];
        const customUsers = [];
        if (this.invoiceOwner.share.type === "equal") {
          equalUsers.push(this.invoiceOwner);
        } else {
          customUsers.push(this.invoiceOwner);
        }
        for (const user of this.invoiceSharedUsers) {
          if (user.share.type === "equal") {
            equalUsers.push(user);
          } else {
            customUsers.push(user);
          }
        }

        // 2. Split invoice values.
        if (this.useAmountsInsteadOfPercentage) {
          // A. split using amounts.
          // 1. Set values for custom type.
          for (const user of customUsers) {
            // Full sum is what user entered.
            let fullSum = parseFloat(user.share.sum);
            let fullSumVat = 0;
            user.share.sumFull = fullSum;
            let percentageMultiplier = fullSum / this.invoiceSum;
            fullSumVat = this.invoiceSumVat * percentageMultiplier;
            user.share.sumVAT = this.formatFixed(fullSumVat, 2);
            user.share.sumVATFull = fullSumVat;
            let percentage = percentageMultiplier * 100;
            user.share.percentage = this.formatFixed(percentage, 2);
            user.share.percentageFull = percentage;
            remainingPercentage -= percentage;
            remainingInvoiceSum -= user.share.sum;
            remainingInvoiceSumVat -= user.share.sumVAT;
          }
        } else {
          // B. split using percentage.
          // 1. Set values for custom type.
          for (const user of customUsers) {
            let percentage = user.share.percentageFull
              ? parseFloat(user.share.percentageFull)
              : 0;
            let percentageMultiplier = percentage ? percentage / 100 : 0;
            remainingPercentage -= percentage;
            let fullSum = this.invoiceSum * percentageMultiplier;
            let fullSumVat = this.invoiceSumVat * percentageMultiplier;
            if (this.currentlyEditingUser?._id !== user._id) {
              user.share.sum = fullSum;
              user.share.sumFull = fullSum;
              user.share.sumVAT = fullSumVat;
              user.share.sumVATFull = fullSumVat;
            }
            remainingInvoiceSum -= user.share.sum;
            remainingInvoiceSumVat -= user.share.sumVAT;
          }
        }

        // 3. Set values for equal type.
        let divideBetweenCount = equalUsers.length;
        let percentPerUser = remainingPercentage / divideBetweenCount;
        let newEqualUserSum = remainingInvoiceSum / divideBetweenCount;
        let newEqualUserSumVat = remainingInvoiceSumVat / divideBetweenCount;
        for (const user of equalUsers) {
          user.share.percentage = this.formatFixed(percentPerUser, 2);
          user.share.percentageFull = percentPerUser;
          user.share.sum = this.formatFixed(newEqualUserSum, 2);
          user.share.sumFull = newEqualUserSum;
          user.share.sumVAT = this.formatFixed(newEqualUserSumVat, 2);
          user.share.sumVATFull = newEqualUserSumVat;
        }
      } else {
        this.invoiceOwner.share.sum = this.invoiceSum;
        this.invoiceOwner.share.sumVAT = this.invoiceSumVat;
        this.invoiceOwner.share.percentage = "100";
      }
      this.$emit("changed", {
        owner: this.localSelectedUser,
        sharedUsers: this.invoiceSharedUsers,
        sum: this.invoiceSum,
        sumVAT: this.invoiceSumVat,
        useAmountsInsteadOfPercentage: this.useAmountsInsteadOfPercentage,
      });
    },
    formatFixed(value, toFixedDecimalCount) {
      let num = value;

      // Handle undefined or null values
      if (num === undefined || num === null) return num;

      // Convert to number
      num = Number(num);

      // Check if it's a whole number
      if (num % 1 !== 0) {
        // If not, return it with 2 decimal places
        return num.toFixed(toFixedDecimalCount);
      } else {
        // If it is, return it without decimal places
        return num.toFixed(0);
      }
    },
    updateInvoicePreferences(value) {
      if (!this.editMode) {
        this.updateModulePreferences(
          "invoices",
          "useAmountInsteadOfPercentage",
          value,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.invoice-sharing-row {
  display: flex;
  gap: 12px;
  position: relative;
  align-items: center;
}

.invoice-sharing-row div {
  width: 262px;
  max-width: 262px;
}

.invoice-sharing-row div .invoice-sharing-row-sum {
  background-color: $beige;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
  padding: 2px 4px;
}

.invoice-sharing-toggle {
  width: fit-content;
}

.invoice-sharing-add-user {
  width: fit-content;
  display: flex;
  gap: 12px;
  outline: none;

  img {
    height: 20px;
    width: 20px;
  }

  span {
    text-decoration: underline;
  }
}

.invoice-sharing-row-shared {
  display: flex;
  gap: 12px;
}

.invoice-sharing-row-shared div {
  max-width: 125px;
}
</style>
