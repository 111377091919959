<template>
  <TelePort to="body">
    <div
      id="broker-info"
      v-click-outside="closePanel"
      :style="styler"
      ref="brokerInfo"
    >
      <div
        class="main-wrapper"
        style="
          background-color: white;
          padding: 24px;
          border-radius: 8px;
          max-height: 200px;
        "
      >
        <div style="margin-bottom: 16px">
          <h5>{{ userInfo.name }}</h5>
        </div>

        <div style="border: 1px solid #e6e8ec"></div>
        <div v-if="userInfo.email" class="customer-field-row top16 flex-row">
          <p class="content" style="width: 150px; color: #75787a">
            {{ $t("Email") }}
          </p>
          <p
            id="deal-probability"
            class="deal-item-name hover-option"
            @click.prevent="sendEmail"
          >
            {{ userInfo.email }}
          </p>
          <CopyButton
            :toast-message="'EmailCopied'"
            :copy-text="userInfo.email"
          />
        </div>
        <div
          v-if="userInfo.phoneNumber"
          class="customer-field-row top12 flex-row"
        >
          <p class="content" style="width: 150px; color: #75787a">
            {{ $t("PhoneNumber") }}
          </p>
          <p
            id="deal-probability"
            class="deal-item-name hover-option"
            @click.prevent="callNumber"
          >
            {{ userInfo.phoneNumber }}
          </p>
          <CopyButton
            :toast-message="'PhoneNumberCopied'"
            :copy-text="userInfo.phoneNumber"
          />
        </div>
        <div class="customer-field-row top12 flex-row">
          <p class="content" style="width: 150px; color: #75787a">
            {{ $t("UserGroups") }}
          </p>
          <p id="deal-probability" class="deal-item-name">{{ groups }}</p>
        </div>
      </div>
    </div>
  </TelePort>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CopyButton from "@/components/common/CopyButton";
import { Teleport as TelePort } from "vue";

export default {
  name: "BrokerInfoPanel",
  props: ["item", "position"],
  components: {
    CopyButton,
    TelePort,
  },
  data() {
    return {
      userInfo: null,
      groups: null,
      styler: {},
    };
  },
  created() {
    this.styler = this.position;
    this.styler.position = "fixed";
    this.styler.zIndex = "110";
    this.styler.boxShadow =
      "0px 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11), 0px 0px 0px rgba(0, 0, 0, 0.1)";
    this.styler.borderRadius = "8px";
    this.styler.width = "fit-content";
    this.styler.maxHeight = "200px";
    this.getRightUser(this.item);
  },
  methods: {
    ...mapActions(["getUsers"]),
    closePanel() {
      this.$emit("closed");
    },
    async getRightUser(item) {
      if (!this.users) {
        await this.getUsers();
      }
      this.users.forEach((user) => {
        if (user._id === item) {
          this.userInfo = user;
        }
      });
      this.groups = this.getGroupDetails();
    },
    getGroupDetails() {
      let additionalDetails = [];
      this.userInfo.groups.forEach((group) => {
        additionalDetails.push(group.name);
      });
      return this.formatDetails(additionalDetails);
    },
    formatDetails(details) {
      details = details.map((detail, index) => {
        if (index === 0) return detail;
        return detail[0] + detail.slice(1);
      });

      return details.join(", ");
    },
    callNumber() {
      window.location.href = `tel:${this.userInfo.phoneNumber}`;
    },
    sendEmail() {
      window.location.href = `mailto:${this.userInfo.email}`;
    },
  },
  computed: {
    ...mapGetters(["users"]),
  },
  mounted() {
    const rect = this.$refs.brokerInfo.getBoundingClientRect();

    if (
      rect.right + 24 >
      (window.innerWidth || document.documentElement.clientWidth)
    ) {
      this.$refs.brokerInfo.style.right = "0px";
      this.$refs.brokerInfo.style.left = "unset";
    } else {
      this.$refs.brokerInfo.style.right = "unset";
    }
  },
};
</script>

<style lang="scss" scoped>
.customer-field-row {
  gap: 8px;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.top16 {
  margin-top: 16px !important;
}

.top12 {
  margin-top: 12px !important;
}
.deal-item-name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}
</style>
