<template>
  <div class="custom-select" ref="customSelect">
    <div class="select-box" @click="toggleDropdown">
      <span :class="{ 'selected-text': selectedItem }">
        {{ selectedItem ? selectedItem.name : placeholder }}
      </span>
      <div class="actions">
        <img
          v-if="selectedItem"
          :src="require(`../../assets/images/close.svg`)"
          alt="Clear selection"
          class="img20 clear-icon"
          @click.stop="clearSelection"
        />
        <i class="arrow" :class="{ open: dropdownOpen }"></i>
      </div>
    </div>
    <ul v-if="dropdownOpen" class="dropdown">
      <li
        v-for="item in items"
        :key="item.value"
        @click="selectItem(item)"
        :class="{ selected: item.value === modelValue }"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    selectedItem() {
      return this.items.find((item) => item.value === this.modelValue);
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectItem(item) {
      this.$emit("update:modelValue", item.value);
      this.dropdownOpen = false;
    },
    clearSelection() {
      this.$emit("update:modelValue", "");
    },
    handleClickOutside(event) {
      if (!this.$refs.customSelect.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(230, 232, 236);
  border-radius: 8px;
  padding: 7px 11px;
  height: 52px;
  cursor: pointer;
  color: #75787a;
}
.actions {
  display: flex;
  align-items: center;
}
.clear-icon {
  margin-right: 16px;
  padding: 2px;
  cursor: pointer;
}

.clear-icon:hover {
  background: rgb(230, 232, 236);
  border-radius: 50%;
}
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}
.arrow.open {
  transform: rotate(-135deg);
}
.dropdown {
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  right: 0;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 8px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dropdown li {
  padding: 12px;
  cursor: pointer;
  text-wrap: nowrap;
}
.dropdown li.selected {
  background-color: #f4f5f7;
}

.dropdown li:hover {
  background-color: #f4f5f7;
}

.custom-select .select-box span.selected-text {
  color: black;
}
</style>
