<template>
  <div
    @keyup.esc="closePanel"
    @click="closePanel($event)"
    class="overlay-orgpanel"
    id="overlay-org"
  >
    <div class="org-main-wrapper">
      <div
        v-if="!loaded"
        class="loader"
        style="position: absolute; top: 50%; left: 50%"
      />
      <div v-else class="org-content">
        <div class="panel-header">
          <h4 v-if="organization.company_name">
            {{ organization.company_name }}
          </h4>
          <h4 v-else-if="organization.organization">
            {{ organization.organization }}
          </h4>
          <button
            class="main-small-secondary"
            v-if="!organization.reg_code && !edit"
          >
            <img
              :src="require('@/assets/images/edit.svg')"
              alt="edit"
              width="16"
              height="16"
            />
            <span class="button-small" @click="edit = true">
              {{ $t("Edit") }}
            </span>
          </button>
        </div>
        <div class="org-content-body" v-if="!edit">
          <div class="main-info">
            <div
              v-if="organization.orgStatus === '4'"
              class="organization-deleted content-bold"
            >
              {{ $t("OrganizationIsDeleted") }}
            </div>
            <h5 style="margin-bottom: 12px">
              {{ $t("Subblock.GeneralData") }}
            </h5>

            <div
              v-for="infoRow in mainInfo"
              :key="infoRow.value"
              class="main-info-row"
            >
              <p class="main-info-row-title">{{ infoRow.title }}</p>
              <a
                class="main-info-row-value"
                :href="infoRow.value"
                v-if="infoRow.extra === 'web'"
              >
                {{ infoRow.value }}
              </a>

              <p class="main-info-row-value" v-else>
                {{ infoRow.value }}
                <span
                  v-if="infoRow.extra === 'updatedAt'"
                  class="check-update-button button-small"
                  @click="checkUpdate"
                >
                  {{ $t("CheckUpdate") }}
                </span>
              </p>
            </div>
          </div>

          <v-divider />

          <div class="related-people">
            <h5>{{ $t("Contacts") }}</h5>
            <div
              v-for="contact in organization.relatedContacts"
              :key="contact.customerId"
            >
              <ContactSimpleInfoCard
                :contact="contact"
                @nameOnPress="relatedPersonPressed(contact.customerId)"
              />
            </div>
          </div>

          <v-divider />

          <div class="related-invoices">
            <h5>{{ $t("RelatedInvoices") }}</h5>

            <div
              v-for="invoice in organization.relatedInvoices"
              :key="invoice._id + '-' + invoiceUpdater"
            >
              <InvoiceSimpleInfoCard
                :invoice="invoice"
                @onPress="relatedInvoicePressed(invoice._id)"
              />
            </div>
          </div>

          <v-divider />

          <div class="related-projects">
            <h5>{{ $t("RelatedProjects") }}</h5>

            <BaseToggle
              style="padding: 8px 0; margin-top: 12px"
              v-model="showArchivedProjects"
              :label="$t('ShowArchived')"
            />

            <div
              v-for="project in organization.relatedProjects"
              :key="project._id"
            >
              <SimpleInfoCard
                :hideArchived="!showArchivedProjects"
                :project="project"
                @onPress="relatedProjectPressed(project._id)"
                style="margin-top: 16px"
              />
            </div>
          </div>
        </div>
        <div class="org-form-wrapper" v-else>
          <div class="flex-row">
            <BaseInput
              v-model="organization.organization"
              :placeholder="$t('OrganizationsName')"
              :required="true"
              :error-message="'errorrr'"
              :error="missingOrganizationName"
            />
          </div>
          <div class="flex-row">
            <GoogleAddressSearch
              v-model="organization.address"
              :placeholder="$t('Object') + ' ' + $t('Address').toLowerCase()"
            />
          </div>
          <div
            class="flex-row"
            style="display: flex; flex-wrap: nowrap; gap: 12px"
          >
            <BaseInput
              :placeholder="$t('CustomerField.phoneNumber')"
              icon-left="phone_20_20.svg"
              v-model="organization.phoneNumber"
            />
            <BaseInput
              :placeholder="$t('CustomerField.email')"
              icon-left="email_20_20.svg"
              v-model="organization.email"
            />
          </div>
        </div>

        <div class="org-footer" v-if="edit">
          <div class="org-buttons">
            <BaseButton
              size="large"
              :label="$t('Cancel')"
              @click="edit = false"
            >
            </BaseButton>
            <BaseButton
              :icon-left="'checkmark_white_16_16.svg'"
              size="large"
              primary="true"
              :label="$t('Save')"
              @click="saveButtonClicked"
            >
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div name="sidepanels">
      <InvoiceSidepanel
        v-if="selectedInvoice"
        :invoice="selectedInvoice"
        @closePanel="selectedInvoice = null"
        @editInvoice="updateInvoice"
        @deleteInvoice="deleteInvoice"
        @updateInvoiceStatus="updateInvoiceStatus"
      />

      <ClientSidepanel
        v-if="selectedContact"
        :customerData="selectedContact"
        @panelClosed="selectedContact = null"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseToggle from "@/components/common/BaseToggle";
import SimpleInfoCard from "@/components/Deals/SimpleInfoCard";
import ContactSimpleInfoCard from "@/components/Kliendiregister/ContactSimpleInfoCard";
import InvoiceSimpleInfoCard from "@/components/Invoices/InvoiceSimpleInfoCard";
import InvoiceSidepanel from "@/components/Invoices/InvoiceSidepanel";
import moment from "moment";
import BaseInput from "@/components/common/BaseInput";
import GoogleAddressSearch from "@/components/common/GoogleAddressSearch";
import BaseButton from "@/components/common/BaseButton";
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  name: "OrganizationSidepanel",
  props: ["selectedOrganization"],
  components: {
    ClientSidepanel: defineAsyncComponent(
      () => import("@/components/Kliendiregister/ClientSidepanel"),
    ),
    InvoiceSidepanel,
    InvoiceSimpleInfoCard,
    ContactSimpleInfoCard,
    SimpleInfoCard,
    BaseToggle,
    BaseInput,
    GoogleAddressSearch,
    BaseButton,
  },
  data: () => ({
    showArchivedProjects: false,
    loaded: false,
    organization: null,
    selectedInvoice: null,
    invoiceUpdater: 0,
    selectedContact: null,
    edit: false,
    missingOrganizationName: false,
  }),
  async created() {
    await this.setData();
  },
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
    mainInfo() {
      let phoneNumber;
      let web;
      if (this.organization.reg_code) {
        const phoneObj = this.organization.contacts.find(
          (c) => c.type === "mobile",
        );
        const webObj = this.organization.contacts.find((c) => c.type === "web");
        if (phoneObj) phoneNumber = phoneObj.contact;
        if (webObj) web = webObj.contact;
      }
      const mainInfoItems = [];
      if (this.organization.company_name) {
        mainInfoItems.push({
          title: this.$t("CompanyName"),
          value: this.organization.company_name,
        });
      }
      if (this.organization.updatedAt && this.organization.reg_code) {
        mainInfoItems.push({
          title: this.$t("Data") + " " + this.$t("Updated").toLocaleLowerCase(),
          value: moment(this.organization.updatedAt).format("DD.MM.YYYY"),
          extra: moment(this.organization.updatedAt).isSame(
            moment().format(),
            "day",
          )
            ? null
            : "updatedAt",
        });
      }
      if (this.organization.reg_code) {
        mainInfoItems.push({
          title: this.$t("RegistrationNumber"),
          value: this.organization.reg_code,
        });
      }
      if (this.organization.kmkr) {
        mainInfoItems.push({
          title: "KMKR",
          value: this.organization.kmkr,
        });
      }
      if (this.organization.email) {
        mainInfoItems.push({
          title: this.$t("Email"),
          value: this.organization.email,
        });
      }
      if (this.organization.orgEmail) {
        mainInfoItems.push({
          title: this.$t("Email"),
          value: this.organization.orgEmail,
        });
      }
      if (this.organization.address) {
        mainInfoItems.push({
          title: this.$t("Address"),
          value: this.organization.address,
        });
      }
      if (this.organization.phoneNum ?? phoneNumber) {
        mainInfoItems.push({
          title: this.$t("PhoneNumber"),
          value: this.organization.phoneNum ?? phoneNumber,
        });
      }
      if (this.organization.phoneNumber) {
        mainInfoItems.push({
          title: this.$t("PhoneNumber"),
          value: this.organization.phoneNumber,
        });
      }
      if (web) {
        mainInfoItems.push({
          title: this.$t("Homepage"),
          value: web,
          extra: "web",
        });
      }

      return mainInfoItems;
    },
  },
  watch: {
    selectedInvoice() {
      if (!this.selectedInvoice) this.invoiceUpdater++;
    },
  },
  methods: {
    async saveButtonClicked() {
      if (!this.organization.organization) {
        this.missingOrganizationName = true;
        return;
      }
      this.organization.company_name = this.organization.organization;
      const response = await axios.patch(
        `/api/organization/${this.organization._id}`,
        this.organization,
      );
      if (response.status === 200) {
        this.$toast.success(
          this.$t("Organization") + " " + this.$t("Updated").toLowerCase(),
        );
        this.edit = false;
      } else {
        this.$toast.error(this.$t("Error"));
      }
    },
    async setData() {
      this.loaded = false;
      await this.getOrganization();
      await Promise.all([
        this.getRelatedContacts(),
        this.getRelatedInvoices(),
        this.getRelatedProjects(),
      ]);
      this.loaded = true;
    },
    async checkUpdate() {
      this.loaded = false;
      const res = await axios.get(
        `/api/organization/update/${this.organization.reg_code}`,
      );
      if (res.status === 200) {
        const relatedContacts = this.organization.relatedContacts;
        const relatedInvoices = this.organization.relatedInvoices;
        const relatedProjects = this.organization.relatedProjects;
        this.organization = res.data;
        this.organization.relatedContacts = relatedContacts;
        this.organization.relatedInvoices = relatedInvoices;
        this.organization.relatedProjects = relatedProjects;
        this.$toast.success(
          this.$t("Organization") + " " + this.$t("Updated").toLowerCase(),
        );
      } else {
        this.$toast.error(this.$t("ErrorUpdating"));
      }
      this.loaded = true;
    },
    async getOrganization() {
      if (this.selectedOrganization.regCode) {
        const regCode = this.selectedOrganization.regCode;
        const response = await axios.get(`/api/organization/${regCode}`);
        this.organization = response.data;
      } else {
        const orgId = this.selectedOrganization._id
          ? this.selectedOrganization._id
          : this.selectedOrganization;
        const response = await axios.get(`/api/organization/find/${orgId}`);
        this.organization = response.data;
      }
    },
    async getRelatedContacts() {
      if (!this.organization.systemContacts) return;
      const promises = [];
      let contacts = [];
      for (let i = 0; i < this.organization.systemContacts.length; i++) {
        const contact = this.organization.systemContacts[i];
        promises.push(
          axios.get(`/api/contact/${contact.customerId}`).then((res) => {
            contacts.push(res.data);
          }),
        );
      }
      await Promise.all(promises);
      let sortedContacts = [];
      contacts.forEach((contact) => {
        let contactUsers = Object.keys(contact.access.users);
        let userId = this.user._id;
        if (
          contact.access.visibility === "private" &&
          contactUsers.filter((item) => userId === item).length
        ) {
          sortedContacts.push(contact);
        } else if (
          contact.access.visibility === "private+" &&
          (contactUsers.filter((item) => userId === item).length ||
            this.user.access.accessLevel === "company_admin" ||
            this.user.access.accessLevel === "group_admin")
        ) {
          sortedContacts.push(contact);
        } else if (contact.access.visibility === "group") {
          if (
            this.user.access.accessLevel === "company_admin" ||
            contactUsers.filter((item) => userId === item).length
          ) {
            sortedContacts.push(contact);
          } else {
            //GROUPID KELLELE ON JAGATUD
            let contactGroups = Object.keys(contact.access.groups);
            //KASUTAJA KÕIK GROUPID
            let userGroups = Object.keys(this.user.access.groups);

            let commonItems = userGroups.filter((item) =>
              contactGroups.has(item),
            );
            if (commonItems.length > 0) {
              sortedContacts.push(contact);
            }
          }
        } else if (contact.access.visibility === "company") {
          sortedContacts.push(contact);
        }
      });
      this.organization.relatedContacts = sortedContacts;
    },
    async getRelatedInvoices() {
      let invoices;
      if (this.organization.reg_code) {
        invoices = await axios.get(
          `/api/invoices/client/organization/regcode/${this.organization.reg_code}`,
        );
      } else {
        invoices = await axios.get(
          `/api/invoices/client/organization/name/${this.organization.organization}`,
        );
      }
      this.organization.relatedInvoices = invoices.data;
    },
    async getRelatedProjects() {
      if (!this.organization.systemContacts) return;
      const promises = [];
      let projects = [];
      for (let i = 0; i < this.organization.systemContacts.length; i++) {
        const contact = this.organization.systemContacts[i];
        promises.push(
          axios
            .post(`/api/contact/projects/${contact.customerId}`, {
              projectsIds: [],
            })
            .then((res) => {
              projects = projects.concat(res.data.deals);
            }),
        );
      }
      await Promise.all(promises);
      // removing duplicates
      this.organization.relatedProjects = projects.filter(
        (v, i, a) => a.findIndex((v2) => v2._id === v._id) === i,
      );
    },
    closePanel(e) {
      if (!e || e.target.id === "overlay-org") {
        this.$emit("panelClosed");
      }
    },
    relatedPersonPressed(customerId) {
      this.selectedContact = this.organization.relatedContacts.find(
        (c) => c.customerId === customerId,
      );
    },
    relatedInvoicePressed(invoiceId) {
      this.selectedInvoice = this.organization.relatedInvoices.find(
        (i) => i._id === invoiceId,
      );
    },
    async relatedProjectPressed(projectId) {
      await this.$router.push({
        name: "DealDetail",
        params: { id: projectId },
      });
      this.closePanel();
    },
    updateInvoice(invoice) {
      this.organization.relatedInvoices = this.organization.relatedInvoices.map(
        (i) => (i._id !== this.selectedInvoice._id ? i : invoice),
      );

      this.selectedInvoice = null;
    },
    deleteInvoice() {
      this.organization.relatedInvoices =
        this.organization.relatedInvoices.filter(
          (i) => i._id !== this.selectedInvoice._id,
        );

      this.selectedInvoice = null;
    },
    updateInvoiceStatus(args) {
      this.selectedInvoice.status = args.newStatus;
      this.updateInvoice(this.selectedInvoice);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.org-buttons {
  display: flex;
  gap: 16px;
  justify-content: end;
}

.org-footer {
  position: fixed;
  background: #fff;
  bottom: 0;
  right: 0;
  height: 104px;
  width: 640px;
  padding: 24px 32px;
  border-top: 1px solid $grey500;
}

.org-form-wrapper {
  background-color: $grey;
  border-radius: 8px;
  padding: 16px;
  margin: 24px;
}

.check-update-button {
  margin-left: 8px;
  border: 1px solid $grey500;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
}

.organization-deleted {
  margin-bottom: 12px;
  background: #fff0f0;
  color: $red;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.related-invoices {
  display: grid;
  gap: 16px;
}

.related-projects {
  display: grid;
  //gap: 16px;
}

.related-person {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}

.related-person-icon {
  border-radius: 50%;
  padding: 0 4px;
  background: $blue;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.related-people {
  display: grid;
  gap: 16px;
}

.org-content-body {
  padding: 32px;
  display: grid;
  gap: 32px;
}

.org-content {
  overflow-y: scroll;
  height: 100%;
}

.org-main-wrapper {
  width: 640px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
}
.overlay-orgpanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}
.panel-header {
  border-bottom: 1px solid #e6e8ec;
  padding: 48px 32px 32px 32px;
  display: flex;
  justify-content: space-between;
}
.main-info {
  display: grid;
  gap: 12px;
}
.main-info-row {
  display: flex;
  gap: 16px;
}
.main-info-row-title {
  color: $grey950;
  min-width: 216px;
}
</style>
