<template>
  <div
    v-if="loaded && isOpen"
    class="outputs-widget-wrapper"
    :key="listing?._id"
  >
    <vue-draggable-resizable
      v-if="isOpen"
      :z="101"
      h="auto"
      :id="'outputs-draggable'"
      :resizable="false"
      drag-handle=".drag-area"
      class="widget-container"
      v-click-outside="isMultiSelectOpen ? '' : closePanelConfirmation"
    >
      <div id="outputs-widget" class="output-settings">
        <div class="output-settings-header drag-area">
          <img :src="require('@/assets/images/menu.svg')" alt="drag" />
        </div>
        <div class="output-settings-content">
          <div class="output-settings-content-header">
            <h5>{{ $t("OutputSettings") }}</h5>
            <!-- <p class="content-normal">{{ outputsInfo() }}</p> -->
            <div class="content-small export-header-row">
              <div class="export-id export-header-title">
                {{ $t("ExportId") + ": " }}
              </div>
              <div class="listing-identifier">
                {{ listing.identifier }}
              </div>
            </div>
            <div class="content-small export-header-row">
              <div class="export-id export-header-title">
                {{ $t("LastUpdated") + ": " }}
              </div>
              <div class="listing-identifier">
                <div v-html="outputsUpdatedAt"></div>
              </div>
            </div>
          </div>
          <div class="output-settings-content-content">
            <!-- <p class="link-small" v-if="!changeExportAccounts && hasAccess(listing)"
               @click="changeExportAccounts = true;">{{ $t("ChangeExportAccounts") }}</p> -->
            <div class="outputs-header portals-selection">
              {{ $t("SelectPortals") }}*
            </div>
            <div
              class=""
              style="display: flex; flex-wrap: wrap; gap: 20px; height: 100%"
            >
              <div
                v-for="(activeUsers, marketplaceName) in userMarketplaces"
                :key="marketplaceName + '-' + marketplacesUpdater + '-output'"
                style="display: flex; gap: 12px"
              >
                <div class="marketplace-wrapper">
                  <div style="display: flex; justify-content: space-between">
                    <CustomCheckbox
                      v-model="userMarketplaces[marketplaceName].isActive"
                      :label="
                        userMarketplaces[marketplaceName].label ||
                        marketplaceName
                      "
                      size="small"
                      :isBoldStyle="true"
                    />
                  </div>
                  <div
                    v-if="userStats && userStats[marketplaceName]"
                    class="marketplace-limit"
                  >
                    {{ userStats[marketplaceName].quantity }}/{{
                      userStats[marketplaceName].total
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="outputs-broker-section">
              <div class="outputs-header-wrapper">
                <div class="outputs-header portals-contacts">
                  {{ $t("Brokers") }}*
                </div>
                <div class="checkbox-same-broker">
                  <TheCheckbox
                    @clicked="toggleIsSameBroker"
                    :checked="isSameBroker"
                    :admin-module="true"
                    :label="$t('SameBroker')"
                    checkboxColour="black"
                  />
                </div>
              </div>
              <div v-if="isSameBroker" class="">
                <MultiOrderSelect
                  v-model="
                    userMarketplaces[Object.keys(userMarketplaces)[0]]
                      .activeUsers
                  "
                  @dropdownToggled="handleDropdown"
                  :items="allMarketplaceUsersFlat"
                  :remove-duplicates="true"
                  :duplicate-key="'email'"
                  :label="$t('BrokersShownInListing')"
                />
              </div>
              <div v-else class="multi-order-select">
                <MultiOrderSelect
                  v-for="marketplace of listing.marketplaces"
                  v-model="userMarketplaces[marketplace?.name].activeUsers"
                  @dropdownToggled="handleDropdown"
                  :key="marketplace.name"
                  :items="allMarketplaceUsers[marketplace?.name]"
                  :label="$t('BrokersShownInListing')"
                  :title="marketplace.name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="outputs-confirmation-section">
          <div @click="handleCancel" class="output-cancel confirmation-button">
            {{ $t("Cancel") }}
          </div>
          <div class="save-row flex-row">
            <div
              @click="saveMarketplaceChanges"
              class="output-save confirmation-button"
            >
              {{ isSaving ? "" : $t("Save") }}
            </div>
            <div
              v-if="isSaving"
              style="position: absolute; top: 0%; left: 46%; margin: 8px 0 0 0"
              class="loader"
            ></div>
          </div>
        </div>
      </div>
    </vue-draggable-resizable>
  </div>
  <ConfirmationModal
    v-if="cancelChangesConfirmation"
    :isDecline="true"
    @onCancel="handleCancelSave"
    @onDecline="handleDeclineSave"
    @onConfirm="handleConfirm"
  >
    <h5
      class="title-text"
      style="display: flex; width: 100%; padding-top: 10px"
    >
      {{ $t("DoYouWantToSaveOutputChanges") }}
    </h5>
  </ConfirmationModal>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import axios from "axios";
import CustomCheckbox from "@/components/CustomCheckbox";
import MultiOrderSelect from "@/components/common/MultiOrderSelect";
import { mapGetters } from "vuex";
import { useAccess } from "@/composables/useAccess";
import TheCheckbox from "@/components/common/TheCheckbox";
import moment from "moment";
import ConfirmationModal from "@/components/common/ConfirmationModal";

export default {
  name: "OutputsModal",
  props: ["listing", "project", "toggleModal", "position"],
  components: {
    VueDraggableResizable,
    CustomCheckbox,
    TheCheckbox,
    MultiOrderSelect,
    ConfirmationModal,
  },
  setup() {
    const { user, users, hasAccess, accessColleagues, groupColleagues } =
      useAccess();

    return {
      user,
      users,
      hasAccess,
      accessColleagues,
      groupColleagues,
    };
  },
  data() {
    return {
      confirmationModalOpen: false,
      confirmationModalEmail: "",
      confirmationModalMarketplace: null,
      marketplacesUpdater: 0,
      marketplaceUsers: null,
      changeExportAccounts: false,
      loaded: false,
      isOpen: false,
      listingOwnerId: null,
      userStats: null,
      isSameBroker: false,
      allMarketplaceUsers: [],
      allMarketplaceUsersFlat: [],
      userMarketplaces: {},
      owner: null,
      outputsUpdatedAt: "-",
      isSaving: false,
      primaryBroker: null,
      isMultiSelectOpen: false,
      originalActiveUsers: {},
      cancelChangesConfirmation: false,
    };
  },
  async mounted() {
    await this.setupListing();
    await this.getOutputStats();
    this.setupUserMarketplaces();
    this.outputsInfo();
    document.addEventListener("dblclick", this.handleDoubleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("dblclick", this.handleDoubleClickOutside);
  },
  watch: {
    isMultiSelectOpen(isOpen) {
      if (isOpen) {
        document.removeEventListener("dblclick", this.handleDoubleClickOutside);
      } else {
        document.addEventListener("dblclick", this.handleDoubleClickOutside);
      }
    },
    isOpen: {
      handler: async function (val) {
        if (val) {
          await this.setupListing();
          this.setupUserMarketplaces();
          this.getUpdatedAtOutputs();
          const interval = setInterval(() => {
            const element = document.getElementById("outputs-draggable");
            if (element) {
              element.style.top = this.position.y + "px";
              element.style.left = this.position.x + "px";
              element.style.visibility = "visible";
              const widget = document
                .getElementById("outputs-widget")
                .getBoundingClientRect();
              const isOutOfViewport = this.isOutOfViewport(element, widget);
              if (isOutOfViewport.bottom) {
                element.style.top = "unset";
                element.style.bottom = isOutOfViewport.rect.height - 20 + "px";
              }
              if (isOutOfViewport.right) {
                element.style.left = "unset";
                element.style.right = "340px";
              }

              clearInterval(interval);
            }
          }, 50);
        } else if (val === false) {
          this.userStats = null;
          // this.isSameBroker = !this.listing.marketplaces.some(
          //   (marketplace) => marketplace.activeUser
          // );
          await this.setupListing();
          await this.getOutputStats();
          this.outputsInfo();
        }
      },
    },
    listing: {
      handler: async function () {
        if (this.listing && this.listing.marketplaces) {
          const firstMarketplaceActiveUsers = JSON.stringify(
            this.listing.marketplaces[0]?.activeUsers || [],
          );

          this.isSameBroker = this.listing.marketplaces.every((marketplace) => {
            const currentMarketplaceActiveUsers = JSON.stringify(
              marketplace.activeUsers || [],
            );
            return (
              currentMarketplaceActiveUsers === firstMarketplaceActiveUsers
            );
          });
        }

        await this.getOutputStats();
        await this.setupListing();
        this.outputsInfo();
      },
      immediate: true,
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
      this.changeExportAccounts = false;
    },
    listingOwnerId: {
      handler: async function (newVal) {
        if (newVal) {
          await this.getOutputStats();
        }
      },
    },
    isSameBroker: {
      handler: function () {
        this.setupUserMarketplaces();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user", "users"]),
  },
  methods: {
    closePanelConfirmation() {
      this.cancelChangesConfirmation = true;
      this.isOpen = false;
    },
    handleCancelSave() {
      this.cancelChangesConfirmation = false;
      this.isOpen = true;
    },
    handleDeclineSave() {
      this.cancelChangesConfirmation = false;
      this.isOpen = false;
    },
    handleConfirm() {
      this.isOpen = true;
      this.cancelChangesConfirmation = false;
      this.saveMarketplaceChanges();
    },
    handleDropdown(isOpen) {
      this.isMultiSelectOpen = isOpen;
    },
    handleCancel() {
      this.listing.marketplaces.forEach((marketplace) => {
        if (this.originalActiveUsers[marketplace.name]) {
          marketplace.activeUsers = JSON.parse(
            JSON.stringify(this.originalActiveUsers[marketplace.name]),
          );
        }
      });
      setTimeout(() => {
        this.isOpen = false;
      }, 50);
    },
    toggleIsSameBroker() {
      this.isSameBroker = !this.isSameBroker;
    },
    getUpdatedAtOutputs() {
      if (this.listing && this.listing.listingsOutputsUpdated) {
        const lastUpdated = moment(this.listing.listingsOutputsUpdated);
        const formattedDate = lastUpdated.format("DD.MM.YYYY HH:mm");
        const daysAgo = lastUpdated.fromNow();

        this.outputsUpdatedAt = `<strong>${daysAgo}</strong> (${formattedDate})`;
      }
    },
    isOutOfViewport(elem, realWidget) {
      const rect = elem.getBoundingClientRect();
      rect.width = realWidget.width;
      rect.height = realWidget.height;
      const outOfViewport = { rect: rect };
      outOfViewport.bottom =
        rect.bottom >
        (window.innerHeight || document.documentElement.clientHeight);
      outOfViewport.right =
        rect.right >
        (window.innerWidth || document.documentElement.clientWidth);
      return outOfViewport;
    },
    setupUserMarketplaces() {
      this.outputsInfo();

      if (this.listing && this.listing.marketplaces) {
        if (this.isSameBroker) {
          this.listing.marketplaces.forEach((marketplace) => {
            if (marketplace.activeUsers && marketplace.activeUsers.length > 0) {
              this.userMarketplaces[marketplace.name] = {
                activeUsers: marketplace.activeUsers,
                isActive: marketplace.isActive,
              };
              this.primaryBroker = marketplace.activeUsers[0];
            } else {
              this.userMarketplaces[marketplace.name] = {
                activeUsers: [
                  {
                    name: this.owner.name,
                    email: this.owner.email,
                    _id: this.owner._id,
                  },
                ],
                isActive: marketplace.isActive,
              };
              this.primaryBroker = {
                name: this.owner.name,
                email: this.owner.email,
                _id: this.owner._id,
              };
            }
          });
        } else {
          this.listing.marketplaces.forEach((marketplace) => {
            let marketplaceUser = {};
            let marketplaceUsers = [];

            if (marketplace.activeUsers && marketplace.activeUsers.length > 0) {
              marketplaceUsers = marketplace.activeUsers;
            } else {
              marketplaceUser = {
                name: marketplace?.activeUser
                  ? marketplace?.activeUser
                  : this.owner.name,
                email: marketplace?.activeUser
                  ? marketplace?.activeUser
                  : this.owner.email,
              };
            }

            this.userMarketplaces[marketplace.name] = {
              activeUsers:
                marketplaceUsers.length > 0
                  ? marketplaceUsers
                  : [marketplaceUser],
              isActive: marketplace.isActive,
            };
          });
        }
        this.originalActiveUsers = JSON.parse(
          JSON.stringify(
            Object.keys(this.userMarketplaces).reduce((acc, key) => {
              acc[key] = this.userMarketplaces[key].activeUsers;
              return acc;
            }, {}),
          ),
        );
      }
    },
    handleDoubleClickOutside(event) {
      if (this.isMultiSelectOpen) {
        return;
      }
      const widget = document.getElementById("outputs-widget");
      if (widget && !widget.contains(event.target)) {
        this.isOpen = false;
      }
    },
    async closePanel(el) {
      if (this.isMultiSelectOpen) return;
      if (this.confirmationModalOpen) return;
      el = el.target;
      if (
        (el.classList && el.classList.contains("v-list-item__title")) ||
        el.classList.contains("v-list-item") ||
        el.classList.contains("v-list") ||
        el.classList.contains("v-list-item__content")
      ) {
        return;
      }
      while (el.parentNode) {
        el = el.parentNode;
        if (el.id === "outputs-widget") return;
      }
      this.isOpen = false;
    },
    async setupListing() {
      await this.getMarketplaceUsers();
      this.getUpdatedAtOutputs();
      this.loaded = true;
    },
    outputsInfo() {
      if (this.listing && this.listing.access) {
        const listingAccess = this?.listing?.access;
        const listingOwner = listingAccess?.usersArray.find(
          (user) => user.ownershipType === "owner",
        );
        this.listingOwnerId = listingOwner._id;
        this.owner = listingOwner;
        const youLabel = ` (${this.$t("You")})`;
        if (
          this.user._id === this.owner._id &&
          !this.owner.name.includes(youLabel)
        ) {
          this.owner.name += youLabel;
        }
        // return this.$t('ByDefaultAllActiveListingsAreExportedFromYourAccount') + ` ${listingOwner.email || ''}`;
      }
    },
    async getOutputStats() {
      if (this.listingOwnerId) {
        const response = await axios.get(
          `/api/users/output-stats/${this.primaryBroker._id ? this.primaryBroker._id : this.listingOwnerId}`,
        );
        this.userStats = response.data;
      }
    },
    openMarketplaceURL(marketplace) {
      if (marketplace.url || marketplace.portalURL) {
        window.open(marketplace.url || marketplace.portalURL, "_blank").focus();
      }
    },
    async getMarketplaceUsers() {
      const response = await axios.post("/api/users/output", {
        listing: { _id: this.listing?._id },
      });

      this.allMarketplaceUsers = response.data;
      this.marketplaceUsers = Object.values(response.data);

      this.allMarketplaceUsersFlat = this.removeDuplicatesByKey(
        this.marketplaceUsers.flat(),
        "email",
      );

      const currentUser = this.allMarketplaceUsersFlat.find(
        (user) => user.email === this.user.email,
      );
      if (currentUser && !currentUser.name.includes(`(${this.$t("You")})`)) {
        currentUser.name += ` (${this.$t("You")})`;
      }
    },
    removeDuplicatesByKey(arr, key) {
      const seen = new Set();
      return arr.filter((item) => {
        const keyValue = item[key];
        if (seen.has(keyValue)) {
          return false;
        }
        seen.add(keyValue);
        return true;
      });
    },
    async saveMarketplaceChanges() {
      try {
        this.isSaving = true;
        await this.marketplaceUpdated();

        this.isSaving = false;
        this.isOpen = false;
      } catch (error) {
        this.$toast.error(this.$t("ErrorSaving"));
      }
    },
    async marketplaceUpdated() {
      try {
        const response = await axios.post("/api/users/update-output", {
          listing: { _id: this.listing?._id },
          marketplaces: this.userMarketplaces,
          isSameBroker: this.isSameBroker,
        });

        if (response && response.status === 200) {
          switch (response.data.message) {
            case "success":
              this.syncMarketplacesIsActive();
              this.$toast.success(this.$t("Updated"));
              break;
            case "marketplace_active_limit_exceeded": {
              const exceededMarketplace = response.data.marketplaceName;
              if (exceededMarketplace) {
                const marketplace = this.listing.marketplaces.find(
                  (mp) => mp.name === exceededMarketplace,
                );
                if (marketplace) {
                  marketplace.isActive = false;
                }
                this.$toast.error(
                  `${exceededMarketplace} ${this.$t("LimitHasBeenReached")} ${this.$t("ForUser")} ${response?.data?.user?.name}`,
                );
              } else {
                this.$toast.error(this.$t("LimitHasBeenReached"));
              }
              this.marketplacesUpdater++;
              break;
            }
            case "www_set_to_inactive": {
              const inactiveMarketplace = response.data.marketplaceName;
              if (inactiveMarketplace) {
                const marketplace = this.listing.marketplaces.find(
                  (mp) => mp.name === inactiveMarketplace,
                );
                if (marketplace) {
                  marketplace.isActive = false;
                }
                this.$toast.error(
                  `${inactiveMarketplace} ${this.$t("WWWIsSetToInactiveForThisUser")}`,
                );
              } else {
                this.$toast.error(this.$t("WWWIsSetToInactiveForThisUser"));
              }
              this.marketplacesUpdater++;
              break;
            }
            default:
              this.$toast.error(this.$t("ErrorUpdating"));
              break;
          }
        } else {
          this.$toast.error(this.$t("ErrorUpdating"));
        }
      } catch (error) {
        console.error("Error updating marketplace:", error);
        this.$toast.error(this.$t("ErrorUpdating"));
      }
    },
    syncMarketplacesIsActive() {
      if (this.listing && this.listing.marketplaces) {
        for (const marketplaceName in this.userMarketplaces) {
          const userMarketplace = this.userMarketplaces[marketplaceName];

          const listingMarketplace = this.listing.marketplaces.find(
            (marketplace) => marketplace.name === marketplaceName,
          );

          if (listingMarketplace) {
            listingMarketplace.isActive = userMarketplace.isActive;
          }
        }
      }
    },
    async marketplaceStatusUpdated(booleanValue, marketplace) {
      const response = await axios.post("/api/users/output-status-changed", {
        listing: { _id: this.listing?._id },
        isActive: booleanValue,
        marketplace: marketplace,
      });
      if (response && response.status === 200) {
        switch (response.data.message) {
          case "success":
            this.$toast.success(this.$t("Updated"));
            await this.getOutputStats();
            break;
          case "marketplace_active_limit_exceeded":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.$toast.error(
              `${marketplace.name} ${this.$t("LimitHasBeenReached")}`,
            );
            break;
          case "www_set_to_inactive":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.$toast.error(`${this.$t("WWWIsSetToInactiveForThisUser")}`);
            break;
          case "saleAML":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.$toast.error(this.$t("CompleteKnowYourCustomerFirst"));
            break;
          case "saleAMLFileMissing":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.$toast.error(this.$t("KYCMustIncludeFile"));
            break;
          case "saleAgreement":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.$toast.error(this.$t("ProjectMustHaveAnActiveSalesAgreement"));
            break;
          case "rentalAgreement":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.$toast.error(this.$t("ProjectMustHaveAnActiveRentAgreement"));
            break;
        }
      } else {
        marketplace.isActive = !booleanValue;
        this.marketplacesUpdater++;
        this.$toast.error(this.$t("ErrorUpdating"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global_variables.scss";

.outputs-widget-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 103;
}

.multi-order-select {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.widget-container {
  position: fixed;
  padding: 32px;
  visibility: hidden;
}

.drag-area {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
}

.link-small {
  line-height: 15px !important;
}

.output-toggle {
  min-width: 140px;
  flex-grow: 0;
}

.save-row {
  position: relative;
  height: 48px;
  width: 400px;
}

.output-settings {
  cursor: default;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 464px;
  z-index: 102;
  border-radius: 8px;
  position: fixed;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.outputs-confirmation-section {
  display: flex;
  gap: 14px;
  padding: 0 32px 32px 32px;
}

.confirmation-button {
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  font-family: Inter;
  font-weight: 700;
  height: 48px;
}

.export-header-row {
  display: flex;
  gap: 4px;
}

.checkbox-same-broker {
  display: flex;
  gap: 4px;
}

.export-header-title {
  color: #75787a;
}

.output-cancel {
  color: #75787a;
}

.output-cancel:hover {
  background-color: #f4f5f7;
}

.output-save {
  color: white;
  background-color: black;
}

.outputs-header {
  font-family: Geomanist;
  font-weight: 500;
  font-size: 14px;
}

.marketplace-limit {
  font-size: 12px;
  font-weight: 400;
  font-family: Inter;
  display: flex;
  justify-content: flex-end;
}

.output-settings-header {
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: 10px;
  border-radius: 8px 8px 0 0;
  background-color: $grey;
}

.marketplace-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.outputs-header-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.outputs-broker-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.drag-area {
  cursor: grab;
}

.drag-area:active {
  cursor: grabbing;
}

.output-settings-content {
  border-radius: 0 0 8px 8px;
  padding: 16px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.output-settings-content-header {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.output-settings-content-content {
  padding-top: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.external-link-row {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  gap: 6px;
}

.loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #a0a0a0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}
</style>
