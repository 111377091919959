<template>
  <div :id="id" class="input-field-container">
    <div
      ref="container"
      @click="focusTextarea"
      class="form-field-large"
      id="form-field"
      :style="fieldStyle"
    >
      <div
        :class="{ required: fieldRequired }"
        style="display: flex; width: 100%; flex-direction: column"
      >
        <label
          v-if="fieldPlaceholder"
          ref="label"
          :style="labelStyle"
          class="content"
          >{{ fieldPlaceholder }}</label
        >
        <textarea
          @focus="openTextarea"
          @focusout="unfocusTextarea"
          v-model="fieldValue"
          class="content"
          id="content"
          ref="textarea"
          :disabled="disabled"
          style="
            resize: none;
            overflow: hidden;
            outline: none;
            padding-top: 2px;
            height: 0px;
          "
        />
      </div>
    </div>
    <div
      v-if="fieldError"
      class="content error-message"
      style="margin: 12px 12px 0 12px"
    >
      {{
        fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    id: String,
    placeholder: String,
    required: Boolean,
    errorMessage: String,
    error: Boolean,
    value: String,
    disabled: Boolean,
    modelValue: String,
  },
  data() {
    return {
      fieldFocus: false,
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "7px 11px",
        gap: "12px",
        background: "white",
        cursor: "text",
      },
      labelStyle: {
        color: "#75787A",
        transition: "",
      },
    };
  },
  computed: {
    fieldValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    fieldPlaceholder() {
      return this.placeholder;
    },
    fieldRequired() {
      return this.required;
    },
    fieldError() {
      return this.error;
    },
    fieldErrorMessage() {
      return this.errorMessage;
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.adjustTextareaHeight();
        if (!newValue) {
          this.unfocusTextarea();
        } else {
          this.activatePlaceholder();
        }
      },
      immediate: true,
    },
    fieldValue: {
      handler(value) {
        this.$nextTick(() => this.adjustTextareaHeight());
        this.$emit("update:modelValue", value);
        if (!value) {
          this.$nextTick(() => this.unfocusTextarea());
        } else {
          this.$nextTick(() => this.activatePlaceholder());
        }
      },
      immediate: true,
      deep: true,
    },
    error(newError) {
      this.fieldError = newError;
      if (newError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        if (this.fieldFocus && this.$refs.label) {
          this.$refs.label.style.color = "#FF1E24";
        }
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        if (this.$refs.label) {
          this.$refs.label.style.color = "";
        }
        if (this.validateInput(this.fieldValue) && this.$refs.label) {
          this.$refs.label.style.color = "#FF5C01";
        }
      }
    },
    errorMessage(newErrorMessage) {
      this.fieldErrorMessage = newErrorMessage;
    },
  },
  methods: {
    initialize() {
      this.fieldValue = this.modelValue || "";
      if (this.fieldValue) {
        this.activatePlaceholder();
      }
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    focusTextarea() {
      this.$nextTick(() => this.$refs.textarea.focus());
    },
    openTextarea() {
      this.labelStyle.transition = "0.2s";
      this.fieldFocus = true;
      this.activatePlaceholder();
      if (this.fieldValue === "") {
        this.$nextTick(() => (this.$refs.textarea.style.height = "24px"));
      } else {
        this.$nextTick(() => this.adjustTextareaHeight());
      }
    },
    adjustTextareaHeight() {
      const textarea = this.$refs.textarea;
      if (textarea) {
        textarea.style.height = "24px";
        if (textarea.scrollHeight + 28 > 52) {
          this.$refs.container.style.height = textarea.scrollHeight + 28 + "px";
          textarea.style.height = textarea.scrollHeight + "px";
        } else {
          this.$refs.container.style.height = "52px";
        }
      }
    },
    unfocusTextarea() {
      if (!this.validateInput(this.fieldValue)) {
        this.fieldValue = "";
        if (this.$refs.textarea) {
          this.$refs.textarea.style.height = "0px";
        }
        if (this.$refs.container) {
          this.$refs.container.style.height = "52px";
        }
        this.deactivatePlaceholder();
      }
      this.fieldFocus = false;
      if (this.$refs.label) {
        this.$refs.label.style.color = "#75787A";
      }
    },
    activatePlaceholder() {
      if (this.$refs.label) {
        this.$refs.label.classList.add("form-field-large-active-placeholder");
        if (this.fieldFocus) {
          this.$refs.label.style.color = "#FF5C01";
        } else {
          this.labelStyle.color = "#75787A";
        }
        this.$refs.label.classList.add("required-mark");
      }
    },
    deactivatePlaceholder() {
      if (this.$refs.label) {
        this.$refs.label.classList.remove(
          "form-field-large-active-placeholder",
        );
        this.labelStyle.color = "#75787A";
      }
    },
  },
};
</script>

<style scoped>
.input-field-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.input-field {
  width: 100%;
  height: 24px;
  outline: none;
  cursor: text;
}

.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.form-field-large-active-placeholder {
  height: 12px !important;
  line-height: 12px !important;
  font-size: 11px !important;
}
</style>
