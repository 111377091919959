<template>
  <div class="overlay" id="overlay-property">
    <div id="panel-wrapper-property" class="modal-wrapper">
      <!--  <div
        @click="closeContactAdd"
        style="padding-left: 24px"
        id="wrapped-header-property"
      >
        <h3 style="align-self: center">
          {{
            isEdit
              ? $t("Edit") + " " + $t("Property").toLowerCase()
              : $t("AddNewProperty")
          }}
        </h3>
      </div> -->

      <div
        class="content-overlay"
        style="height: 100%; display: flex; flex-direction: column"
        id="content-wrapper"
      >
        <div class="sticky-header" style="z-index: 126">
          <div
            class="top-row flex-row"
            style="height: 24px; padding-left: 32px"
          >
            <h4 v-if="!isEdit" style="height: 24px">
              {{ $t("AddNewProperty") }}
            </h4>
            <h4 v-if="isEdit" style="height: 24px">
              {{ $t("ChangeProperty") }}
            </h4>
          </div>
        </div>
        <div style="padding: 24px 32px; height: 100%; overflow-y: scroll">
          <div class="flex-row" style="padding-bottom: 12px">
            <h6>{{ $t("GeneralInformation") }}</h6>
          </div>
          <div class="flex-row" style="gap: 8px">
            <BaseInput
              :id="'property-owner-name-field'"
              :placeholder="$t('PropertyName')"
              v-model="form.propertyName"
            />
            <!--   <BaseInput
              :id="'property-owner-address-field'"
              :placeholder="$t('Address')"
              v-model="form.address"
              :icon-left="'search20.svg'"
            /> -->
            <BaseInput
              :id="'property-owner-value-field'"
              :placeholder="$t('Value')"
              v-model="form.propertyValue"
              :icon-right="'euroSign.svg'"
            />
            <BaseInput
              :id="'property-owner-size-field'"
              :placeholder="$t('PropertySize')"
              v-model="form.propertySize"
              :icon-right="'m2_20.svg'"
            />
            <BaseInput
              :id="'property-owner-closedNetArea-field'"
              :placeholder="$t('ClosedNetArea')"
              v-model="form.closedNetArea"
              :icon-right="'m2_20.svg'"
            />
            <BaseTextarea
              :id="'project-add-note'"
              v-model="noteContent"
              v-if="!isEdit"
              :placeholder="$t('Note')"
            />
          </div>

          <div class="flex-row" style="margin-top: 32px">
            <h6>{{ $t("AssetType") }}</h6>
          </div>

          <div class="flex-row" style="gap: 8px; margin-top: 24px">
            <BaseInput
              :id="'property-owner-business-field'"
              :placeholder="$t('BusinessId')"
              v-model="form.businessId"
            />
            <BaseDropdown
              :id="'property-owner-currency-field'"
              v-model="form.assetType"
              :items="assetTypes"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="true"
              :placeholder="$t('AssetType')"
            />
            <BaseDropdown
              :id="'property-owner-currency-field'"
              v-model="form.assetClass"
              :items="assetClasses"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="true"
              :placeholder="$t('AssetClass')"
            />
          </div>

          <div class="flex-row" style="margin-top: 32px">
            <h6>{{ $t("Image") }}</h6>
          </div>
          <div class="flex-row" style="margin-top: 24px">
            <UploadImages
              v-if="!isEdit"
              :Imgs="[]"
              :upload-msg="$t('UploadImage')"
              :max="1"
              @changed="putImages($event)"
              class="image-uploader"
            />
            <UploadImages
              v-if="isEdit"
              :Imgs2="form.images"
              :upload-msg="$t('UploadImage')"
              :max="1"
              @changed="putImages($event)"
              class="image-uploader"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="buttons-row" style="padding-left: 24px">
            <button
              v-if="isEdit"
              class="delete-button"
              color="blue darken-1"
              text
              @click="isDeleteModalOpen = true"
            >
              <img
                style="padding-right: 8px"
                :src="require('@/assets/images/TrashBin.svg')"
                alt=""
              />
              {{ $t("Delete") }}
            </button>
            <button
              v-if="!isEdit"
              @click="changeSidepanelStateProperties(0)"
              class="cancel"
            >
              {{ $t("Cancel") }}
            </button>
            <button v-if="isEdit" @click="closeModal()" class="cancel">
              {{ $t("Cancel") }}
            </button>
            <button
              v-if="isEdit"
              class="save-button"
              color="blue darken-1"
              text
              @click="updateProperty()"
            >
              <img
                style="padding-right: 8px"
                :src="require('@/assets/images/Submit.svg')"
                alt=""
              />
              {{ $t("Submit") }}
            </button>
            <button v-if="!isEdit" class="save-button" @click="saveProperty()">
              <img
                style="padding-right: 8px"
                :src="require('@/assets/images/Submit.svg')"
                alt=""
              />
              {{ $t("Submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteProperty"
      v-if="isDeleteModalOpen"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { nanoid } from "nanoid";
import ClickOutside from "vue-click-outside";
import DeleteModal from "@/components/common/DeleteModal";
import UploadImages from "../../components/common/vue-upload-drop-images/dist/vue-upload-drop-images";
import BaseDropdown from "@/components/common/BaseDropdown";
import BaseInput from "@/components/common/BaseInput";
import BaseTextarea from "@/components/common/BaseTextarea";
import moment from "moment";

export default {
  components: {
    UploadImages,
    DeleteModal,
    BaseDropdown,
    BaseInput,
    BaseTextarea,
  },
  name: "SidepanelAdd",
  props: ["propertyData", "isEdit", "noRefresh", "fromAnotherPanel"],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showErrors: false,
      clientAddOpen: false,
      isDeleteModalOpen: false,
      noteContent: "",
      //validate: ["name", "object", "estateType", "contactPerson"],
      assetTypes: [
        { name: this.$t("GrossRent"), value: "gross_rent" },
        { name: this.$t("NetRent"), value: "net_rent" },
        { name: this.$t("TripleNet"), value: "triple_net" },
      ],
      assetClasses: [
        { name: this.$t("RentalPlot"), value: "rental_plot" },
        { name: this.$t("ResidentialBuilding"), value: "residential_building" },
        { name: this.$t("Apartment"), value: "apartment" },
        { name: this.$t("CommercialProperty"), value: "commercial_property" },
        { name: this.$t("ShopPremise"), value: "shop_premise" },
        { name: this.$t("ForestLand"), value: "forest_land" },
        { name: this.$t("Arable"), value: "arable" },
        {
          name: this.$t("IndirectlyOwnedHolding"),
          value: "indirectly_owned_holding",
        },
        { name: this.$t("Other"), value: "other" },
        { name: this.$t("Parking"), value: "parking" },
      ],
      dateChanged: false,
      errors: null,
      object: null,
      form: {
        propertyName: "",
        address: null,
        propertyValue: null,
        propertySize: null,
        closedNetArea: null,
        businessId: null,
        assetType: null,
        assetClass: null,
        notes: [],
        images: [],
        creator: {
          userId: null,
          name: null,
        },
      },
    };
  },
  created() {
    //oli mountedis
    //this.mapToFormData();
    if (this.isEdit) {
      this.mapToFormData();
    }
  },
  mounted() {
    this.hideOverlay();
    this.errors = this.getEmptyErrors();
    this.mapToFormData();
  },
  watch: {},
  beforeUnmount() {
    if (!this.fromAnotherPanel) {
      console.log("wwhy here");
      document.documentElement.style.overflow = "visible";
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["changeSidepanelStateProperties"]),
    closeModal() {
      this.$emit("closed");
      this.changeSidepanelStateProperties(0);
    },
    async saveProperty() {
      console.log("Property TO UPLOAD", this.form);
      this.form.creator.userId = this.user._id;
      this.form.creator.name = this.user.name;
      let property = this.form;
      property._id = nanoid(8);
      property = this.mapProperty(property);
      let response = await axios.post("/api/property", { property });
      if (response.data === 0) {
        this.$toast.error(this.$t("ErrorCreating"));
      } else {
        this.$emit("saveResponse", response.data);
        await this.changeSidepanelStateProperties(0);
        if (!this.noRefresh) {
          await this.$router.go(0);
        }
        this.$toast.success(
          this.$t("Property") + " " + this.$t("Created").toLowerCase(),
        );
      }
    },
    async updateProperty() {
      let property = this.form;
      property = this.mapProperty(property);
      const response = await axios.patch("/api/property", property);
      if (response.data === 0) {
        this.$toast.error(this.$t("ErrorUpdating"));
        return;
      } else {
        await this.changeSidepanelStateProperties(0);
        if (!this.noRefresh && !this.isEdit) {
          await this.$router.go(0);
        }
        // location.reload();
        await this.$emit("closed");
        this.$toast.success(
          this.$t("Property") + " " + this.$t("Updated").toLowerCase(),
        );
      }
    },
    async deleteProperty() {
      const deleteResponse = await axios.post(
        `/api/property/delete/${this.form._id}`,
      );
      if (deleteResponse.data === 0) {
        this.$toast.error(this.$t("ErrorDeleting"));
        return;
      } else {
        await this.$router.push({ name: "PropertyOwner" });
        this.$toast.success(
          this.$t("Property") + " " + this.$t("Deleted").toLowerCase(),
        );
      }
    },
    isFormValid(saleOrRent) {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          if (
            (field === "name" && saleOrRent) ||
            (field === "object" && !saleOrRent) ||
            (field === "estateType" && !saleOrRent)
          )
            return true;
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "name":
          if (!this.form.name) {
            this.errors.name = "FieldRequired";
            return false;
          }
          break;
        case "object":
          if (!this.object) {
            this.errors.object = "FieldRequired";
            return false;
          }
          break;
      }
      return true;
    },
    mapProperty(property) {
      if (this.noteContent) {
        property.notes.push({
          content: this.noteContent,
          linkedTo: property._id,
          isDisabled: true,
          createdAt: moment().format("DD/MM/YYYY"),
          parentName: property.name,
        });
      }
      return property;
    },
    mapToFormData() {
      if (this.isEdit && this.propertyData) {
        this.form = this.propertyData;
        this.form.propertyName = this.propertyData.name;
        this.form.propertyValue = this.propertyData.value;
        this.form.propertySize = this.propertyData.area;
      }
    },
    getEmptyErrors() {
      return {
        name: "",
        object: "",
        estateType: "",
        contactPerson: "",
      };
    },
    putImages(e) {
      this.form.images = e;
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-property {
  -webkit-transition:
    width 0.5s ease-in-out,
    right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition:
    width 0.5s ease-in-out,
    right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition:
    width 0.5s ease-in-out,
    right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition:
    width 0.5s ease-in-out,
    right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-property {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

:deep(.delete) {
  padding: 0 !important;
}

:deep(.plus) {
  display: none;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  border-bottom: 1px solid #e6e8ec;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.flex-column-gen {
  margin-top: 17px !important;
}

:deep(.mainMessage) {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

:deep(.beforeUpload .icon) {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}
.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  z-index: 126;
  border-top: 1px solid #e6e8ec;
  background: white;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

:deep(.theme--light.v-select .v-select__selections) {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

:deep(.not-valid fieldset) {
  border: 2px solid #ff1e24 !important;
}

.input-edit {
  margin-top: 12px;
}

:deep(.v-messages) {
  display: none;
}

:deep(.radio-group-label .v-label) {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
</style>
