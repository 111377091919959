import i18n from "../../i18n";
import { nanoid } from "nanoid";

const options = {
  state: {
    estateTypes: [
      {
        name: i18n.global.t("CommercialSpace"),
        value: "commercial",
      },
      { name: i18n.global.t("Apartment"), value: "apartment" },
      { name: i18n.global.t("House"), value: "house" },
      { name: i18n.global.t("HousePart"), value: "house_part" },
      { name: i18n.global.t("LandPlot"), value: "land" },
      { name: i18n.global.t("SummerHouse"), value: "cottage" },
      { name: i18n.global.t("Garage"), value: "garage" },
    ],
    transactionTypes: [
      { name: i18n.global.t("Purchase"), value: "sale" },
      { name: i18n.global.t("Rent"), value: "rent" },
      { name: i18n.global.t("ShortTermRent"), value: "short_term_rent" },
    ],
    extraTypes: {
      placeholder: {
        name: i18n.global.t("CommercialPurposeOfUse"),
        value: "placeholder",
        _id: () => nanoid(16),
      },
      land: [
        {
          name: i18n.global.t("CommercialPurposeOfUse"),
          value: "placeholder",
          _id: () => nanoid(16),
        },
        {
          name: i18n.global.t("ResidentialLand"),
          value: "residentialLand",
        },
        {
          name: i18n.global.t("ResidentialLandApartments"),
          value: "residentialLandApartment",
        },
        { name: i18n.global.t("CommercialLand"), value: "commercialLand" },
        { name: i18n.global.t("ProductionLand"), value: "productionLand" },
        { name: i18n.global.t("IndustrialLand"), value: "industrialLand" },
        { name: i18n.global.t("ForestLand"), value: "forestLand" },
        {
          name: i18n.global.t("ProfitYieldingLand"),
          value: "profityieldingLand",
        },
        { name: i18n.global.t("OtherUse"), value: "otherUse" },
      ],
      commercial: [
        {
          name: i18n.global.t("CommercialPurposeOfUse"),
          value: "placeholder",
          _id: () => nanoid(16),
        },
        {
          name: i18n.global.t("Office"),
          value: "office",
        },
        { name: i18n.global.t("TradeShop"), value: "tradeshop" },
        { name: i18n.global.t("Accomodation"), value: "accomodation" },
        { name: i18n.global.t("Services"), value: "services" },
        { name: i18n.global.t("WareHouse"), value: "warehouse" },
        { name: i18n.global.t("Production"), value: "production" },
        {
          name: i18n.global.t("WareHouseProduction"),
          value: "warehouseProduction",
        },
        { name: i18n.global.t("FoodServices"), value: "foodServices" },
        { name: i18n.global.t("OtherUsePossible"), value: "otherUsePossible" },
        { name: i18n.global.t("NotDefined"), value: "notDefined" },
      ],
    },
  },
  mutations: {},
  actions: {},
  getters: {},
};

export default options;
