export default [
  {
    _id: "4",
    name: "allValues",
    filterTitle: "allInvoices",
    isDefault: true,
    isSelected: true,
    conditions: [
      {
        name: "search",
        title: "Search",
        value: "",
        valueTitle: "",
      },
      {
        name: "brokerId",
        title: "Broker",
        value: "",
        valueTitle: "",
      },
      {
        name: "invoiceTypes",
        title: "invoiceTypes",
        value: [],
        valueTitle: "All",
      },
      {
        name: "status",
        title: "Status",
        value: [],
        valueTitle: "All",
      },
      {
        name: "accountingType",
        title: "accountingType",
        value: "accrual_accounting",
        valueTitle: "All",
      },
      {
        name: "invoiceDate",
        title: "InvoiceDate",
        value: "this_month",
        valueTitle: "",
      },
      {
        name: "invoiceDateFrom",
        title: "InvoiceDateFrom",
        value: "",
        valueTitle: "",
      },
      {
        name: "invoiceDateTo",
        title: "InvoiceDateTo",
        value: "",
        valueTitle: "",
      },
      /*  {
                name: "invoiceDateType",
                title: "invoiceDateType",
                value: "this_month",
                valueTitle: "CurrentMonth",
            },
            {
                name: "dateOfInvoiceFromDate",
                title: "DateOfInvoiceFromDate",
                value: "",
                valueTitle: "All",
            },
            {
                name: "dateOfInvoiceToDate",
                title: "DateOfInvoiceToDate",
                value: "",
                valueTitle: "All",
            }, */
    ],
  },
];
