export default [
  {
    _id: "3",
    name: "allValues",
    filterTitle: "AllProjects",
    isDefault: true,
    isSelected: true,
    conditions: [
      {
        name: "search",
        title: "Search",
        value: "",
        valueTitle: "",
      },
      {
        name: "brokerId",
        title: "Broker",
        value: "",
        valueTitle: "",
      },
      {
        name: "dealTypes",
        title: "dealTypes",
        value: [],
        valueTitle: "All",
      },
      {
        name: "estateTypes",
        title: "estateTypes",
        value: [],
        valueTitle: "",
      },
      {
        name: "extraTypes",
        title: "extraTypes",
        value: [],
        valueTitle: "",
      },
      {
        name: "createdFromDate",
        title: "createdFromDate",
        value: "",
        valueTitle: "All",
      },
      {
        name: "createdToDate",
        title: "createdToDate",
        value: "",
        valueTitle: "All",
      },
      {
        name: "floorMax",
        title: "floorMax",
        value: "",
        valueTitle: "",
      },
      {
        name: "floorMin",
        title: "floorMin",
        value: "",
        valueTitle: "",
      },
      {
        name: "months",
        title: "months",
        value: "",
        valueTitle: "",
      },
      {
        name: "createdFrom",
        title: "createdFrom",
        value: "",
        valueTitle: "",
      },
      {
        name: "createdTo",
        title: "createdTo",
        value: "",
        valueTitle: "",
      },
      {
        name: "parkingOption",
        title: "parkingOption",
        value: false,
        valueTitle: "",
      },
      {
        name: "priceMax",
        title: "priceMax",
        value: "",
        valueTitle: "",
      },
      {
        name: "priceMin",
        title: "priceMin",
        value: "",
        valueTitle: "",
      },
      {
        name: "priceMaxSqm",
        title: "priceMaxSqm",
        value: "",
        valueTitle: "",
      },
      {
        name: "priceMinSqm",
        title: "priceMinSqm",
        value: "",
        valueTitle: "",
      },
      {
        name: "roomMax",
        title: "roomMax",
        value: "",
        valueTitle: "",
      },
      {
        name: "roomMin",
        title: "roomMin",
        value: "",
        valueTitle: "",
      },
      {
        name: "roomsSeparate",
        title: "roomsSeparate",
        value: false,
        valueTitle: "",
      },
      {
        name: "sauna",
        title: "sauna",
        value: false,
        valueTitle: "",
      },
      {
        name: "balcony",
        title: "balcony",
        value: false,
        valueTitle: "",
      },
      {
        name: "statuses",
        title: "statuses",
        value: [],
        valueTitle: "",
      },
      {
        name: "storeRoom",
        title: "storeRoom",
        value: false,
        valueTitle: "",
      },
      {
        name: "createdFrom",
        title: "createdFrom",
        value: "",
        valueTitle: "",
      },
      {
        name: "createdTo",
        title: "createdTo",
        value: "",
        valueTitle: "",
      },
      {
        name: "city",
        title: "City",
        value: "",
        valueTitle: "",
      },
      {
        name: "cityParts",
        title: "City Parts",
        value: [],
        valueTitle: "",
      },
      {
        name: "cityValue",
        title: "City Value",
        value: "",
        valueTitle: "",
      },

      {
        name: "county",
        title: "County",
        value: "",
        valueTitle: "",
      },
      {
        name: "countyValue",
        title: "countyValue",
        value: "",
        valueTitle: "",
      },
    ],
  },
];
