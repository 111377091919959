<template>
  <div class="sub-block">
    <h5
      v-if="!showButtons"
      class="sub-heading"
      style="padding-left: 0px; padding-bottom: 12px"
    >
      <div v-if="showAdditionalText">{{ $t("Files") }}</div>
      <div
        class="flex-row"
        v-if="!clientSidepanelOpen && showAdditionalText"
        style="padding-left: 8px; max-width: 32px; max-height: 16px"
      >
        <div class="counter-icon">
          <p class="content-small" style="color: white">{{ fileCounter }}</p>
        </div>
      </div>
      <button
        v-if="!hideButton"
        :class="{
          'right-corner-location': buttonCornerLocation,
          'near-subheading-location': !buttonCornerLocation,
        }"
        @click="triggerFileInput"
        type="button"
        class="add-notes"
      >
        <img :src="require('@/assets/images/plus.svg')" alt="" />
        <span class="add-note-inside">{{ $t("AddFiles") }}</span>
      </button>
    </h5>
    <div v-if="isUploading" class="overlay">
      <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
      </div>
    </div>
    <div
      class="file-row flex-row"
      v-for="(el, index) in refactoredFiles"
      :key="index"
    >
      <span style="display: flex; gap: 8px">
        <img
          v-if="!el.amlId"
          :src="require('@/assets/images/action-delete.svg')"
          alt=""
          style="cursor: pointer"
          @click="confirmDelete(el)"
        />
        <span class="file-name" @click="previewFile(el.fileName, el.amlId)">
          {{ el.fileName }}
        </span>
      </span>
      <span
        class="content-small"
        style="font-weight: 400; color: #75787a; gap: 8px"
      >
        <span>{{ el.uploaderName }}</span>
        <span>{{ formatDate(el.createdAt) }}</span>
      </span>
    </div>
    <input
      style="display: none"
      type="file"
      multiple
      @change="handleFileChange"
      ref="uploadInput"
    />
    <DeleteModal
      @canceled="isDeleteModalOpen = false"
      @approved="deleteFile(fileWaitingDeletion)"
      v-if="isDeleteModalOpen"
    />
    <a href="" id="download-link" download></a>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import DeleteModal from "@/components/common/DeleteModal";
import moment from "moment";

export default {
  name: "FileUpload",
  components: {
    DeleteModal,
  },
  props: {
    id: String,
    files: Array,
    targetId: String,
    targetObject: String,
    showButtons: Boolean,
    buttonCornerLocation: Boolean,
    fileCounter: Number,
    amlPanel: Number,
    clientSidepanelOpen: Boolean,
    hideButton: Boolean,
    showAdditionalText: { type: Boolean, default: true },
  },
  emits: ["update:files", "success"],
  data() {
    return {
      refactoredFiles: [],
      isDeleteModalOpen: false,
      fileWaitingDeletion: null,
      isUploading: false,
      hovered: false,
      loaded: false,
      srcNormal: require("../../assets/images/fileIcon.svg"),
      srcHover: require("../../assets/images/trash-red.svg"),
      fileData: null,
      fileName: null,
    };
  },
  created() {
    this.refactorFiles(this.files);
    this.loaded = true;
  },
  mounted() {
    const fileData = sessionStorage.getItem("fileData");
    const fileName = sessionStorage.getItem("fileName");

    if (fileData && fileName) {
      this.setFileData({
        fileData: fileData,
        fileName: fileName,
      });
    } else {
      console.error("No file data found in session storage.");
    }
  },
  watch: {
    files(newFiles) {
      this.refactorFiles(newFiles);
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setFileData", "clearFileData"]),
    refactorFiles(files) {
      if (files && files.length > 0) {
        this.refactoredFiles = files.map((file) => {
          return {
            ...file,
            fileName: file.fileName.split("_._").pop(),
          };
        });
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    confirmDelete(el) {
      this.isDeleteModalOpen = true;
      this.fileWaitingDeletion = el;
    },
    async deleteFile(el) {
      let dataObject = {
        parentId: this.targetId,
        fileId: el._id,
      };
      const deleteResponse = await axios.post(
        `/api/${this.targetObject}/file/delete/${this.targetId + "_._" + el.fileName}`,
        dataObject,
      );
      if (deleteResponse.status === 200) {
        this.$emit("success");
        this.updateFilesAfterDeletion(el._id);
        this.isDeleteModalOpen = false;
        this.fileWaitingDeletion = null;
      }
    },
    updateFilesAfterDeletion(deletedFileId) {
      const updatedFiles = this.files.filter(
        (file) => file._id !== deletedFileId,
      );
      this.$emit("update:files", updatedFiles);
    },
    async previewFile(fileName, amlId) {
      if (!amlId) {
        const extension = fileName.split(".").pop().toLowerCase();
        if (
          ["jpeg", "jpg", "png", "pdf", "JPEG", "JPG", "PNG", "PDF"].includes(
            extension,
          )
        ) {
          this.clearFileData();
          const encodedFileName = encodeURIComponent(
            this.targetId + "_._" + fileName,
          );
          axios
            .post(`/api/${this.targetObject}/file/${encodedFileName}`)
            .then((response) => {
              const fileData = response.data.data;

              const newTab = window.open();

              let content = "";
              if (
                ["jpeg", "jpg", "png", "JPEG", "JPG", "PNG"].includes(extension)
              ) {
                content = `
              <html>
                <head>
                  <title>File Preview</title>
                </head>
                <body style="margin: 0; display: flex; align-items: center; justify-content: center;">
                  <img src="${fileData}" alt="${fileName}" style="max-width: 100%; max-height: 100%;" />
                </body>
              </html>
            `;
              } else if (extension === "pdf") {
                content = `
              <html>
                <head>
                  <title>File Preview</title>
                </head>
                <body style="margin: 0; display: flex; align-items: center; justify-content: center;">
                  <embed src="${fileData}" type="application/pdf" width="100%" height="100%" />
                </body>
              </html>
            `;
              }

              newTab.document.write(content);
              newTab.document.close();
            })
            .catch((error) => {
              console.error("Error fetching file:", error);
            });
        } else {
          const encodedFileName = encodeURIComponent(
            this.targetId + "_._" + fileName,
          );
          axios
            .post(`/api/${this.targetObject}/file/${encodedFileName}`)
            .then((response) => {
              const downloadUrl = response.data.data;
              window.location.href = downloadUrl;
            })
            .catch((error) => {
              console.error("Error downloading the file:", error);
            });
        }
      } else {
        this.downloadFileAml(fileName, amlId);
      }
    },
    async downloadFileAml(fileName, amlId) {
      const extension = fileName.split(".").pop().toLowerCase();
      if (
        ["jpeg", "jpg", "png", "pdf", "JPEG", "JPG", "PNG", "PDF"].includes(
          extension,
        )
      ) {
        this.clearFileData();
        await axios
          .post(`/api/project/${this.targetId}/aml/${amlId}/file/${fileName}`)
          .then((response) => {
            const fileData = response.data.data;

            const newTab = window.open();

            let content = "";
            if (
              ["jpeg", "jpg", "png", "JPEG", "JPG", "PNG"].includes(extension)
            ) {
              content = `
              <html>
                <head>
                  <title>File Preview</title>
                </head>
                <body style="margin: 0; display: flex; align-items: center; justify-content: center;">
                  <img src="${fileData}" alt="${fileName}" style="max-width: 100%; max-height: 100%;" />
                </body>
              </html>
            `;
            } else if (extension === "pdf") {
              content = `
              <html>
                <head>
                  <title>File Preview</title>
                </head>
                <body style="margin: 0; display: flex; align-items: center; justify-content: center;">
                  <embed src="${fileData}" type="application/pdf" width="100%" height="100%" />
                </body>
              </html>
            `;
            }

            newTab.document.write(content);
            newTab.document.close();
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      } else {
        await axios
          .post(`/api/project/${this.targetId}/aml/${amlId}/file/${fileName}`)
          .then((response) => {
            const downloadUrl = response.data.data;
            window.location.href = downloadUrl;
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
          });
      }
    },
    async uploadFiles(event) {
      let selectedFiles = event.target.files;
      const formData = new FormData();
      for (const [, value] of Object.entries(selectedFiles)) {
        formData.append("file", value);
      }
      formData.append("userName", this.user.name);

      this.isUploading = true;

      try {
        const response = await axios.post(
          `/api/${this.targetObject}/files/upload/${this.targetId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        if (response.status === 200) {
          this.$emit("success");
          const newFiles = response.data.files;
          this.$emit("update:files", newFiles);
          this.$refs.uploadInput.value = "";
        } else {
          this.$toast.error(this.$t("ErrorUploading"));
        }
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        this.isUploading = false;
      }
    },
    handleFileChange(event) {
      event.stopPropagation();
      event.preventDefault();
      if (event.target.files.length === 0) {
        return;
      }
      this.uploadFiles(event);
    },
    triggerFileInput(event) {
      event.stopPropagation();
      event.preventDefault();
      this.$refs.uploadInput.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-block {
  padding: 0px;
  border-bottom: 0px solid #e6e8ec !important;
  position: relative;
}

.sub-heading {
  padding: 12px;
}

.related-deals {
  font-size: 14px !important;
  line-height: 16px;
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.add-note {
  padding-top: 12px;
}

.add-notes {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-note-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.add-person {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 12px;
}

.file-row {
  padding: 12px;
  margin: 8px 0;
  width: 100%;
  border-radius: 8px;
  background: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploaded-date {
  margin-left: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.uploader-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.file-name {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.file-icon {
  background-image: url("../../assets/images/action-delete.svg");
  cursor: pointer;

  /*  &:hover {
    background-image: url("../../assets/images/trash-red.svg");
    cursor: pointer;
  } */
}

.overlay {
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  position: absolute;
  background: #d5d5d9;
  opacity: 0.5;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.near-subheading-location {
  margin-left: 8px;
}
.right-corner-location {
  margin-left: 312px;
}
.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}
.counter-icon {
  background: #939597;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 16px;
  height: 16px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
