<template>
  <div
    :style="customStyle"
    v-click-outside="closeDropdown"
    v-bind:class="{
      dropdown: !disabled,
      'dropdown dropdown-disabled': disabled,
    }"
  >
    <div
      @click="openDropdown"
      :style="customStyleButton"
      v-bind:class="{
        'dropbtn dropbtn-hover': !disabled,
        dropbtn: disabled,
        'drobtn-white': projectView,
      }"
      class="dropbtn"
    >
      <div v-if="!projectView && !propertyView" class="label-and-item-wrapper">
        <p class="justify-center" style="display: flex; align-items: center">
          <img
            class="icon-img"
            v-bind:src="require(`../../assets/images/${icon}`)"
            alt=""
          />
        </p>
      </div>
      <div
        v-if="projectView || propertyView"
        class="label-and-item-wrapper"
        v-bind:class="{
          'label-and-item-wrapper align-style': !propertyView,
          'outlined-small-button-add align-add-button': propertyView,
        }"
      >
        <img
          style="width: 16px; height: 16px; margin: 0"
          v-bind:src="require(`../../assets/images/${icon}`)"
          alt=""
          v-bind:class="{
            '': !propertyView,
            'whiten-pic': propertyView,
          }"
        />
        <p
          class="justify-center"
          v-bind:class="{
            'justify-center': !propertyView,
            'property-button-inside': propertyView,
          }"
          style="display: flex; align-items: center; padding-left: 8px"
        >
          <a class="button-small" v-if="projectView"> {{ $t("Options") }} </a>
          <a class="button-small" style="color: #ffffff" v-if="propertyView">
            {{ $t("Add") }}
          </a>
        </p>
      </div>
    </div>
    <div
      :id="dropdownId"
      style="display: none; z-index: 1"
      v-bind:class="{
        'dropdown-content dropdown-single dropdown-width scrollable':
          projectView,
        'dropdown-content dropdown-single scrollable':
          !projectView && !propertyView && !listingView,
        'dropdown-content dropdown-single dropdown-border ': propertyView,
        'dropdown-content dropdown-single dropdown-width scrollable-listing':
          listingView,
      }"
      class="dropdown-content dropdown-single"
    >
      <div
        v-for="item in dropdownItems"
        :key="item._id"
        class="dropdown-item-wrapper"
        @click="selectItem(item)"
      >
        <!--        <img-->
        <!--          v-if="selectedItem._id === item._id"-->
        <!--          :src="require('@/assets/images/checkmark.svg')"-->
        <!--          alt=""-->
        <!--        />-->

        <p
          v-if="item.value !== 'delete_listing'"
          v-bind:class="{
            'regular-size-text': regularSize,
            'small-size-text': !regularSize,
          }"
          :style="
            selectedItem && selectedItem[valueKey] === item[valueKey]
              ? 'font-weight: bold'
              : null
          "
        >
          {{ item[displayKey] }}
        </p>
        <p
          v-if="item.value === 'delete_listing'"
          style="color: red"
          class="small-size-text"
        >
          {{ item[displayKey] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  name: "IconDropdown",
  props: [
    "items",
    "displayKey",
    "valueKey",
    "disabled",
    "customStyle",
    "customStyleButton",
    "regularSize",
    "icon",
    "projectView",
    "object",
    "selectedItem",
    "propertyView",
    "listingView",
  ],
  data: () => ({
    dropdownId: null,
    dropdownItems: null,
  }),
  mounted() {
    this.dropdownId = nanoid(10);
    this.dropdownItems = [...this.items];
  },
  watch: {
    items() {
      this.dropdownItems = [...this.items];
    },
  },
  beforeUnmount() {
    window.onclick = null;
  },
  methods: {
    closeDropdown() {
      if (document.getElementById(this.dropdownId)) {
        document.getElementById(this.dropdownId).style.display = "none";
      }
    },
    selectItem(item) {
      if (this.object) {
        this.$emit("itemSelected", { item: item, object: this.object });
        this.$emit("updatingSelection", { item: item, object: this.object });
      } else {
        this.$emit("itemSelected", item);
        this.$emit("updatingSelection", item);
      }
      document.getElementById(this.dropdownId).style.display = "none";
    },
    openDropdown() {
      if (this.disabled) {
        return;
      }
      let dropdownContent = document.getElementById(this.dropdownId);
      if (dropdownContent.style.display === "none") {
        dropdownContent.style.display = "flex";
      } else {
        dropdownContent.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  //min-height: 100%;
  margin-top: 12px;
}

.scrollable-listing {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  margin-top: 106px;
  right: 26px;
}

.dropbtn {
  //background-color: white;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  /* identical to box height, or 133% */
  padding: 2px 4px 2px 4px;
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  flex-direction: row;
  /* black */
  cursor: default;
  color: #000000;
  box-sizing: border-box;
  justify-content: space-between;

  img {
    margin-left: 8px;
  }
}
.drobtn-white {
  background-color: white;
}
.dropbtn-hover {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.regular-size-text {
  font-size: 14px;
  line-height: 20px;
}

.small-size-text {
  font-size: 12px;
  line-height: 16px;
  align-content: center;
  //color: white;
}

.dropdown {
  display: inline-block;
}

.dropdown-item-wrapper {
  cursor: pointer;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  &:hover {
    background-color: #f4f5f7;
  }
}

.dropdown-single {
  position: absolute;
  flex-direction: column;
  background-color: white;
  overflow: scroll;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
  margin-right: 4px;
  p {
    color: black;
    text-decoration: none;
    display: block;
  }
}

.not-selected,
.selected {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
}

.selected {
  font-weight: 600;
}

.dropdown-width {
  width: 176px;
}

.checkmark-wrapper {
  min-width: 16px;
  height: 16px;
}

.dropdown-placeholder:empty:before {
  content: attr(data-placeholder);
  color: gray;
}

.default-item {
  color: #75787a;
}

.arrow-disabled {
  filter: invert(99%) sepia(1%) saturate(51%) hue-rotate(140deg)
    brightness(115%) contrast(88%);
}

.dropbtn-disabled {
  p {
    color: #f0f0f0 !important;
  }
}

.dropdown-disabled {
  opacity: 0.5;
}
.dropdown-border {
  border-radius: 8px;
}
.align-style {
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  border: 1px solid #e6e8ec;
  background-color: white;
  border-radius: 8px;
}
.align-add-button {
  flex-direction: row !important;
  flex-wrap: nowrap;
  padding: 8px 12px;
  height: 32px;
  background-color: #000000;
  border: 0px !important;
  align-content: center !important;
  align-items: center !important;
}
.outlined-small-button-add {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  gap: 8px;
  border: 1px solid #e6e8ec;
  font-family: "Inter", sans-serif;
  color: rgba(0, 0, 0, 1) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
}
.label-and-item-wrapper {
  display: flex;
  //flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.property-button-inside {
  padding-left: 0px !important;
}
.whiten-pic {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
    brightness(103%) contrast(102%);
}

.icon-img {
  height: 16px;
  margin: 1px !important;
}
</style>
