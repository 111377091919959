import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    openSidepanel(module, id) {
      const route = this.$route;
      const router = this.$router;

      if (!route || !router) {
        console.error("Route or Router is not defined");
        return;
      }

      const newRouteObject = {
        name: route.name,
        query: { ...route.query },
      };
      newRouteObject.query[module] = id;
      router.push(newRouteObject);
    },
  },
});
