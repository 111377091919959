<template>
  <div
    class="container"
    @click="handleGlobalClick"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop"
  >
    <input
      type="file"
      style="display: none"
      accept="image/*"
      ref="uploadInput"
      @change="previewImgs"
      multiple
    />
    <div v-if="!imageLoaded && Imgs.length > 0" class="overlay">
      <div class="loader"></div>
    </div>
    <div class="drop" v-show="dropped === 2"></div>
    <div v-if="error" class="error">{{ error }}</div>
    <div
      v-if="Imgs.length === 0"
      class="beforeUpload"
      style="padding: 30px"
      @click="triggerFileInput"
    >
      <img
        v-if="imageLoaded"
        class="icon"
        :src="require('@/assets/images/image.svg')"
        alt=""
      />
      <p class="mainMessage" v-if="imageLoaded">
        {{ uploadMsg ? uploadMsg : "Click to upload or drop here" }}
      </p>
      <div v-if="!imageLoaded">
        <div class="loader"></div>
      </div>
    </div>
    <div class="imgsPreview" v-show="Imgs.length > 0">
      <button
        v-if="!coverImageSelection"
        type="button"
        class="clearButton"
        @click="reset"
      >
        {{ clearAll ? clearAll : "Clear All" }}
      </button>
      <draggable v-model="Imgs" @change="changeOrder">
        <div v-for="(img, i) in Imgs" :key="i" class="imageHolder">
          <img
            :src="img.name"
            :class="{
              'cover-picture': img.selected,
              picture: true,
              large: img.isResized,
              'hidden-overlay': img.hidden,
            }"
            @click="toggleSize(img, i, $event)"
            :id="'img' + i"
          />
          <div
            class=""
            style="
              display: flex;
              position: absolute;
              top: 10px;
              right: 10px;
              gap: 4px;
            "
          >
            <div
              v-if="isVisible && !coverImageSelection"
              class="scalability"
              @click.stop="toggleSize(img, i)"
            >
              <img :src="require('@/assets/images/zoomImage.svg')" alt="" />
            </div>
            <div
              v-if="isVisible && !coverImageSelection"
              class="visibility"
              @click.stop="changeVisibility(img, i)"
            >
              <img
                v-if="!img.hidden"
                :src="require('@/assets/images/openEye.svg')"
                alt=""
              />
              <img
                v-if="img.hidden"
                :src="require('@/assets/images/closeEye.svg')"
                alt=""
              />
            </div>
            <div
              v-if="coverImageSelection && !img.selected"
              class="cover-image"
              @click.stop="setCoverImage(img, i)"
            >
              <img :src="require('@/assets/images/coverImage.svg')" alt="" />
            </div>
            <div
              v-if="!coverImageSelection"
              class="delete"
              @click.stop="deleteImg(i)"
            >
              <svg
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </div>
          </div>
          <div
            class="plus"
            @click="triggerFileInput"
            v-if="i === Imgs.length - 1 && !coverImageSelection && imageLoaded"
          >
            +
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { VueDraggableNext as draggable } from "vue-draggable-next";
import imageCompression from "browser-image-compression";
import Bowser from "bowser";

const error = ref("");
const files = ref([]);
const dropped = ref(0);
const Imgs = ref([]);
const imageLoaded = ref(true);
const isSafari = ref(false);
const uploadInput = ref(null);

const props = defineProps({
  watermark: Boolean,
  max: Number,
  uploadMsg: String,
  maxError: String,
  fileError: String,
  clearAll: String,
  Imgs2: {
    type: Array,
    default: () => [],
  },
  isVisible: Boolean,
  coverImageSelection: Boolean,
});

const emit = defineEmits(["changed", "setCoverImage"]);

watch(Imgs, () => {
  if (!props.watermark) {
    emit("changed", Imgs.value);
  }
});

watch(
  () => props.Imgs2,
  () => {
    Imgs.value = props.Imgs2;
    imageLoaded.value = true;
  },
);

onMounted(() => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  if (browser.getBrowserName() === "Safari") {
    isSafari.value = true;
  }
  if (props.Imgs2) {
    Imgs.value = props.Imgs2;
  }
  document.addEventListener("click", handleGlobalClick);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleGlobalClick);
});

const handleGlobalClick = (event) => {
  const isAnyImageResized = Imgs.value.some((img) => img.isResized);
  if (isAnyImageResized) {
    const clickedElement = event.target;

    if (!clickedElement.closest(".imageHolder")) {
      Imgs.value.forEach((img) => {
        img.isResized = false;
      });
    }
  }
};

const toggleSize = (image, index, event) => {
  if (event) {
    event.stopPropagation();
  }
  image.isResized = !image.isResized;

  if (image.isResized) {
    Imgs.value.forEach((img, i) => {
      if (i !== index) {
        img.isResized = false;
      }
    });
  }
};

const triggerFileInput = (event) => {
  event.stopPropagation();
  if (uploadInput.value) {
    uploadInput.value.click();
  } else {
    console.error("Upload input element is not available");
  }
};

const dragOver = () => {
  dropped.value = 2;
};

const dragLeave = () => {
  dropped.value = 0;
};

const drop = async (e) => {
  let status = true;
  let droppedFiles = Array.from(e.dataTransfer.files);
  if (e && droppedFiles) {
    if (
      isSafari.value &&
      droppedFiles.length === 1 &&
      e.dataTransfer.types.includes("text/uri-list")
    ) {
      dropped.value = 0;
      return;
    }
    for (const file of droppedFiles) {
      if (!file.type.startsWith("image")) status = false;
    }
    if (status) {
      if (props.max && droppedFiles.length + files.value.length > props.max) {
        error.value = props.maxError
          ? props.maxError
          : `Maximum files is ` + props.max;
      } else {
        files.value.push(...droppedFiles);
        await previewImgs();
      }
    } else {
      error.value = props.fileError ? props.fileError : `Unsupported file type`;
    }
  }
  dropped.value = 0;
};

const readAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = () => {
      reject(fr);
    };
    fr.readAsDataURL(file);
  });
};

const deleteImg = (index) => {
  Imgs.value.splice(index, 1);
  files.value.splice(index, 1);
  emit("changed", Imgs.value);
  uploadInput.value.value = null;
};

// const changeVisibility = (image, index) => {
const changeVisibility = (image) => {
  image.hidden = !image.hidden;
};

const reset = () => {
  uploadInput.value.value = null;
  Imgs.value = [];
  files.value = [];
  emit("changed", Imgs.value);
};

const changeOrder = () => {
  emit("changed", Imgs.value);
};

const previewImgs = async (event) => {
  if (event) {
    event.stopPropagation();
  }

  imageLoaded.value = false;
  if (
    props.max &&
    event &&
    event.currentTarget &&
    event.currentTarget.files.length + files.value.length > props.max
  ) {
    error.value = props.maxError
      ? props.maxError
      : `Maximum files is ` + props.max;
    return;
  }
  if (dropped.value === 0 && event) {
    files.value.push(...event.currentTarget.files);
  }
  error.value = "";

  if (files.value.length > 0) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      maxIteration: 10,
      initialQuality: 0.75,
    };

    const compressedImages = [];

    for (const file of files.value) {
      try {
        const compressedFile = await imageCompression(file, options);
        const base64Img = await readAsDataURL(compressedFile);
        compressedImages.push({
          name: base64Img,
          hidden: false,
          isResized: false,
        });
      } catch (error) {
        console.error(error);
      }
    }

    Imgs.value.push(...compressedImages);
    emit("changed", Imgs.value);
    imageLoaded.value = true;
    files.value = [];
  } else {
    files.value = [];
    imageLoaded.value = true;
  }
};
</script>

<style scoped lang="scss">
.picture {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.large {
  transform: scale(2.5);
  position: relative;
  z-index: 12;
  width: auto;
  height: auto;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.hidden-overlay {
  filter: brightness(0.25);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.picture {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

div.delete {
  border-radius: 20px;
  padding: 4px;
}

.error {
  color: red;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.beforeUpload {
  position: relative;
  text-align: center;
  border: 2px dashed #ccc;
  border-radius: 8px;
  height: 200px;
}

.icon {
  width: 30px;
  height: 30px;
}

.mainMessage {
  font-size: 16px;
  color: #333;
}

.imgsPreview {
  position: relative;
}

.imageHolder {
  position: relative;
  width: 150px;
  height: 150px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  display: inline-flex;
}

.cover-picture,
.hidden-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.scalability,
.visibility,
.cover-image,
.delete,
.plus {
  cursor: pointer;
  width: 32px !important;
  height: 32px !important;
}

.plus {
  color: #2d3748;
  background: #f7fafc;
  border-radius: 50%;
  font-size: 21pt;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px dashed;
  line-height: 23px;
  position: absolute;
  right: -42px;
  bottom: 43%;
}

.clearButton {
  color: #2d3748;
  margin: 8px 4px;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.error {
  color: red;
}

.images-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>
