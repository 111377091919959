<template>
  <vue-draggable-resizable
    :z="99"
    :resizable="false"
    drag-handle=".drag-area"
    class="widget-container"
    v-click-outside="closeWidget"
    h="auto"
  >
    <div class="drag-area invoice-payment-widget-header">
      <img
        width="104"
        height="11"
        :src="require('@/assets/images/menu.svg')"
        alt=""
      />
    </div>
    <div class="invoice-payment-widget-content">
      <div class="font-18-bold" style="display: flex">
        {{ $t("AddPaymentToInvoice") }} {{ invoice.number }}
      </div>
      <!-- <DatePick
          :nextMonthCaption="nextMonthCaption"
          :weekdays="weekdays"
          :prevMonthCaption="prevMonthCaption"
          :months="months"
          v-model="date">
        <template v-slot:default="{ toggle }">
          <div style="display: flex; gap: 12px; cursor: pointer" @click="toggle">
            <img width="20" height="20" :src="require('@/assets/images/calendar_20_20.svg')"
                 alt=""/>
            <div class="required" style="display: flex; flex-direction: column">
              <p style="color: #75787a" class="label12">{{ $t('DateOfPayment') }}</p>
              <div class="content-normal">{{ formatDate(date) }}</div>
            </div>
          </div>
        </template>
      </DatePick> -->
      <div style="display: flex; flex-direction: column; gap: 16px">
        <BaseInput
          :placeholder="$t('SumBeforeVAT')"
          :required="true"
          v-model="amount"
          type="number"
          :error="showErrorAmount"
          :error-message="showErrorAmount"
          :icon-right="'euroSign.svg'"
        />
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <div
            class="content-semibold"
            style="
              width: fit-content;
              padding: 2px 4px;
              background-color: #fff0d4;
              border-radius: 4px;
              white-space: nowrap;
            "
          >
            {{ $t("TotalSumWithVAT") }}:
            {{ $nMapper.price(amountWithVAT, "€") }}
          </div>
        </div>
      </div>
    </div>
    <div class="invoice-payment-widget-footer">
      <BaseButton size="large" :label="$t('Cancel')" @click="closeWidget">
      </BaseButton>
      <BaseButton
        size="large"
        primary="true"
        :label="$t('Save')"
        @click="updateInvoice"
      >
      </BaseButton>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import BaseButton from "@/components/common/BaseButton";
// import DatePick from "vue-date-pick";
import moment from "moment";
import BaseInput from "@/components/common/BaseInput";

export default {
  name: "InvoicePaymentWidget",
  props: ["invoice", "preFillUnpaid"],
  components: {
    BaseInput,
    BaseButton,
    VueDraggableResizable,
    // DatePick
  },
  data() {
    return {
      amount: null,
      amountWithVAT: 0,
      date: null,
      showErrorAmount: "",
      nextMonthCaption: this.$t("NextMonth"),
      prevMonthCaption: this.$t("PreviousMonth"),
      weekdays: [
        this.$t("Mon"),
        this.$t("Tue"),
        this.$t("Wed"),
        this.$t("Thu"),
        this.$t("Fri"),
        this.$t("Sat"),
        this.$t("Sun"),
      ],
      months: [
        this.$t("January"),
        this.$t("February"),
        this.$t("March"),
        this.$t("April"),
        this.$t("May"),
        this.$t("June"),
        this.$t("July"),
        this.$t("August"),
        this.$t("September"),
        this.$t("October"),
        this.$t("November"),
        this.$t("December"),
      ],
      VATMultiplier: {
        EST: {
          2023: 1.2,
          2024: 1.22,
        },
      },
    };
  },
  created() {
    this.date = moment().format();
    if (this.preFillUnpaid) {
      this.amount = this.invoice.unpaid;
    }
  },
  watch: {
    amount(value) {
      if (!this.checkForErrors()) {
        if (Math.sign(value) === 1) {
          const invoiceDateYear = moment(this.invoice.invoiceDate).year();
          this.amountWithVAT = (
            value *
            (invoiceDateYear > 2023 || null || isNaN(invoiceDateYear)
              ? this.VATMultiplier.EST[2024]
              : this.VATMultiplier.EST[2023])
          ).toFixed(2);
        }
      }
    },
  },
  methods: {
    closeWidget() {
      this.$emit("closeWidget");
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    checkForErrors() {
      if (!this.amount || this.amount === "0") {
        this.showErrorAmount = this.$t("FormErrors.FieldRequired");
      } else {
        let unpaidAmount = this.invoice.unpaid;
        if (this.amount > unpaidAmount) {
          this.showErrorAmount = `${this.$t("PaymentAmountExceedsUnpaidAmount")}: ${unpaidAmount} €`;
        } else {
          this.showErrorAmount = "";
        }
      }
      return !!this.showErrorAmount;
    },
    updateInvoice() {
      if (this.checkForErrors()) {
        return;
      }
      this.$emit("updateInvoice", {
        date: this.date,
        sum: this.amount,
        sumVAT: this.amountWithVAT,
      });
      this.$emit("closeWidget");
    },
  },
};
</script>

<style scoped>
.widget-container {
  box-shadow:
    0 2px 8px rgba(0, 0, 0, 0.11),
    2px 1px 9px rgba(0, 0, 0, 0.11),
    0 0 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  background: white;
  border-radius: 8px;
  top: calc(50% - 220px);
  right: calc(50% - 240px);
  min-width: 440px;
}

.invoice-payment-widget-header {
  background-color: #f4f5f7;
  border-bottom: 1px solid #e6e8ec;
  height: 35px;
  border-radius: 8px 8px 0 0;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.drag-area {
  cursor: grab;
}

.drag-area:active {
  cursor: grabbing;
}

.invoice-payment-widget-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid #e6e8ec;
}

.invoice-payment-widget-footer {
  border-radius: 0 0 8px 8px;
  height: 88px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 24px;
}

.add-payment-button::after {
  content: "*";
  color: #ff1e24;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

:deep(.vdpPositionBottom.vdpPositionLeft) {
  z-index: 102;
}

:deep(.vdpCell.today .vdpCellContent) {
  outline: 1px solid #ff5c01;
  color: #ff5c01;
  font-weight: 600;
}

/* :deep(.vdpCell .vdpCellContent ){
  color: #000000 !important;
}  */
:deep(.vdpArrowPrev:after) {
  border-right-color: #ff5c01;
}

:deep(.vdpArrowNext:after) {
  border-left-color: #ff5c01;
}

:deep(.vdpCell.selectable:hover .vdpCellContent, )
  .vdpCell.selected
  .vdpCellContent {
  color: #000000;
  background: #f4f5f7 !important;
  font-weight: 400;
}

:deep(.vdpCell.selected .vdpCellContent) {
  background: #ff5c01;
  color: #ffffff;
  font-weight: 600;
}

:deep(.v-input__control) {
  height: 20px;
}

:deep(.v-input__slot) {
  height: 20px;
}
</style>
