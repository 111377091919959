<template>
  <div
    @click.stop="changeState"
    v-bind:class="{
      'regular-width': !adminModule,
      'admin-panel-width': adminModule,
    }"
  >
    <div class="checkbox-wrapper">
      <img
        v-if="checked"
        :class="{
          'default-img': checkboxSize === 'normal',
          'default-img-large': checkboxSize === 'large',
        }"
        :style="customStyle"
        :src="require(`@/assets/images/${checkboxImage}`)"
      />
      <div class="checkbox-wrapper">
        <div
          v-if="!checked"
          :style="customStyle"
          v-bind:class="{
            unchecked: !checked && checkboxSize === 'normal',
            disabled: disabled && checkboxSize === 'normal',
            'unchecked-large': !checked && checkboxSize === 'large',
            'disabled-large': disabled && checkboxSize === 'large',
          }"
        />
      </div>
      <div v-if="label" class="checkbox-label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheCheckbox",
  props: [
    "checked",
    "disabled",
    "customStyle",
    "size",
    "adminModule",
    "checkboxColour",
    "label",
  ],
  data() {
    return {
      checkboxChecked: false,
      checkboxSize: "normal",
      checkboxImage: "checkbox.svg",
      checkboxImages: {
        normal: "checkbox.svg",
        large: "checkbox_24_24.svg",
        normalBlack: "checkbox_black.svg",
      },
    };
  },
  created() {
    this.initializeCheckbox();
  },
  methods: {
    changeState() {
      if (!this.disabled) {
        this.checkboxChecked = !this.checkboxChecked;
        this.$emit("clicked");
      }
    },
    initializeCheckbox() {
      if (this.size && this.size.toLowerCase() === "large") {
        this.checkboxSize = "large";
        this.checkboxImage = this.checkboxImages.large;
      }
      if (this.checkboxColour && this.checkboxColour === "black") {
        this.checkboxImage = this.checkboxImages.normalBlack;
      }
    },
  },
};
</script>

<style scoped>
.default-img {
  width: 16px;
  height: 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-label {
  text-wrap: nowrap;
  font-weight: 400;
  font-size: 14px;
  font-family: Inter;
}

.default-img-large {
  width: 24px;
  height: 24px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.unchecked {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #e6e8ec;
  cursor: pointer;
}

.unchecked-large {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid #e6e8ec;
  cursor: pointer;
}

.disabled {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #e6e8ec;
  background: #e6e8ec5e;
}

.disabled-large {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid #e6e8ec;
  background: #e6e8ec5e;
}
.regular-width {
  max-width: 32px;
  max-height: 32px;
  display: flex;
  align-items: center;
}
.admin-panel-width {
  max-width: 24px;
  max-height: 24px;
}
</style>
