<template>
  <div class="toast-container">
    <div v-if="visible" class="toast" :class="[type, className]" @click="hide">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "info",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "show",
    },
  },
  methods: {
    hide() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style>
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.toast-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  pointer-events: none;
  z-index: 1000;
}

.toast {
  padding: 16px 36px;
  width: 600px;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  pointer-events: all;
  transition:
    opacity 0.3s,
    transform 0.3s;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  background-color: white;
  font-weight: 600;
  font-size: 14px;
  border-width: 2px;
  border-style: solid;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.toast.info {
  background-color: blue;
}

.toast.success {
  border-color: #34af60;
  color: #34af60;
}

.toast.error {
  border-color: #f51f25;
  color: #f51f25;
}

.toast.show {
  animation: fadeIn 0.3s forwards;
}

.toast.hide {
  animation: fadeOut 0.3s forwards;
}

.hidden {
  display: none;
}
</style>
