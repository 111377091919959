<template>
  <div class="multi-order-select-wrapper">
    <div class="dropdown" @click.stop v-click-outside="closeDropdown">
      <div v-if="title" class="" style="margin-bottom: 12px">{{ title }}</div>
      <div class="dropdown-toggle" @click="toggleDropdown">
        <div class="dropdown-dropdown-selected-item">
          <div
            :class="[
              'dropdown-label',
              {
                'dropdown-label-up': isDropdownOpen || selectedItems.length > 0,
              },
            ]"
          >
            {{ label }}
          </div>
          <div class="selected-items">
            <div
              class="selected-item"
              v-for="(item, index) in selectedItems"
              :key="index"
            >
              {{ item.name }}
              <img
                :src="require('@/assets/images/cross_gray.svg')"
                alt="Deselect"
                class="cross-icon"
                @click.stop="deselectItem(item)"
              />
            </div>
          </div>
        </div>
        <img
          :src="require(`@/assets/images/dropdown_16_16_gray.svg`)"
          alt=""
          class="caret-icon"
          :class="[isDropdownOpen ? 'rotate-180' : 'rotate-0']"
        />
      </div>
      <div v-if="isDropdownOpen" class="dropdown-content">
        <input
          type="text"
          v-model="searchQuery"
          :placeholder="$t('Search')"
          class="item-search-input"
        />
        <div
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="toggleSelect(item)"
          class="selectable-item"
        >
          <div class="selected-order">
            <div
              class="list-item-circle"
              :class="[
                getSelectionIndex(item) + 1 > 0
                  ? 'list-item-circle-selected'
                  : 'list-item-circle-unselected',
              ]"
            >
              {{
                getSelectionIndex(item) + 1 > 0
                  ? getSelectionIndex(item) + 1
                  : ""
              }}
            </div>
          </div>
          <div class="selectable-item-name">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    required: true,
  },
  label: String,
  duplicateKey: {
    type: String,
    default: "email",
  },
  title: String,
});

const emit = defineEmits(["update:modelValue", "dropdownToggled"]);
const selectedItems = computed(() => props.modelValue);
const isDropdownOpen = ref(false);
const searchQuery = ref("");

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const closeDropdown = () => {
  setTimeout(() => {
    isDropdownOpen.value = false;
  }, 50);
};

// const isSelected = (item) => {
//   return selectedItems.value.some(
//     (selectedItem) =>
//       selectedItem[props.duplicateKey] === item[props.duplicateKey]
//   );
// };

const getSelectionIndex = (item) => {
  return selectedItems.value.findIndex(
    (selectedItem) =>
      selectedItem[props.duplicateKey] === item[props.duplicateKey],
  );
};

const toggleSelect = (item) => {
  const index = getSelectionIndex(item);
  if (index !== -1) {
    selectedItems.value.splice(index, 1);
  } else {
    selectedItems.value.push(item);
  }
  emit("update:modelValue", selectedItems.value);
};

const deselectItem = (item) => {
  const index = getSelectionIndex(item);
  if (index !== -1) {
    selectedItems.value.splice(index, 1);
  }
  emit("update:modelValue", selectedItems.value);
};

const filteredItems = computed(() => {
  if (!searchQuery.value) return props.items;
  return props.items.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

watch(
  selectedItems,
  (newSelectedItems) => {
    emit("update:modelValue", newSelectedItems);
  },
  { deep: true, immediate: true },
);

watch(isDropdownOpen, (isOpen) => {
  emit("dropdownToggled", isOpen);
});
</script>

<style scoped>
.item-search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  outline: none;
  background: none;
  box-shadow: none;
  font-size: 16px;
  color: inherit;
}

.multi-order-select-wrapper {
  width: 100%;
}

.caret-icon {
  position: absolute;
  right: 12px;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  padding: 10px;
  border: none;
  cursor: pointer;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #939597;
  display: flex;
  justify-content: space-between;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 100%;
  border-radius: 8px;
  gap: 8px;
  transform: translate(0, 4px);
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  box-shadow: 0px 0px 14px 0px #0000001a;
  overflow-y: auto;
  max-height: 228px;
}

.dropdown-content div {
  cursor: pointer;
  padding: 0px 6px;
}

.list-item-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.list-item-circle-unselected {
  border: 2px solid #e6e8ec;
}

.list-item-circle-selected {
  border: 2px solid #000000;
  color: white;
  background-color: black;
}

.dropdown-content .selectable-item:hover {
  background-color: #f4f5f7;
}

.selectable-item {
  display: flex;
  height: 44px;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
}

.selected-items {
  transform: translateY(-28px);
  display: flex;
  gap: 8px;
  font-size: 14px;
  overflow-x: auto;
  width: 400px;
  padding: 4px 0 0 0;
}

.cross-icon {
  position: absolute;
  right: -4px;
  top: -4px;
  cursor: pointer;
}

.selected-item {
  background-color: #e6e8ec;
  border-radius: 16px;
  padding: 4px 8px;
  height: 24px;
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #75787a;
  position: relative;
  text-wrap: nowrap;
}

.dropdown-label {
  display: inline-block;
  position: relative;
  transition:
    transform 0.2s ease,
    font-size 0.2s ease;
  transform: translateY(0px);
  font-size: 16px;
  color: #75787a;
  background-color: white;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-label-up {
  transform: translateY(-20px);
  font-size: 12px;
}

.rotate-180 {
  transform: rotate(180deg);
}
</style>
