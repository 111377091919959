<template>
  <div>
    <div
      :class="{ 'button-container': label }"
      @click="buttonClicked"
      :style="buttonStyle"
    >
      <div
        style="position: relative; width: 24px; height: 24px"
        v-if="isLoading"
        class="loader"
      ></div>
      <img
        class="icon-left"
        :style="iconStyle"
        v-if="iconLeft && !isLoading"
        alt=""
        :src="require(`@/assets/images/${iconLeft}`)"
      />
      <div
        style="white-space: nowrap"
        v-if="label"
        :class="labelClass"
        :style="labelStyle"
      >
        {{ label }}
      </div>
      <img
        :style="iconStyle"
        v-if="iconRight && !isLoading"
        alt=""
        :src="require(`@/assets/images/${iconRight}`)"
      />
    </div>
    <div
      tabindex="0"
      v-click-outside="closeDropdown"
      ref="buttonDropdown"
      class="button-dropdown-container"
      v-if="dropdownOpen"
    >
      <template v-if="items && items.length > 0">
        <div
          @click="selectItem(item)"
          class="button-dropdown-item"
          v-for="item in items"
          :key="item._id"
        >
          <div style="display: flex; flex-direction: column; width: 100%">
            <p class="content-small">{{ item[displayKey] }}</p>
            <div
              v-if="descriptionKey && item[descriptionKey]"
              class="content-small"
              style="color: #75787a; max-width: 100%; white-space: normal"
            >
              {{ $t(item[descriptionKey]) }}
            </div>
          </div>
          <img
            height="16"
            width="16"
            v-if="iconKey && item[iconKey]"
            :src="require(`@/assets/images/${$t(item[iconKey])}`)"
            alt="icon"
          />
        </div>
      </template>
      <template v-else>
        <div style="display: flex; width: 200px; padding: 16px">
          <p class="content-small" style="text-align: center">
            {{ emptyDropdownText }}
          </p>
        </div>
      </template>
    </div>
    <DeleteModal
      v-if="isConfirmModalOpen"
      @approved="emitSelectedItem()"
      :removing="true"
      @canceled="isConfirmModalOpen = false"
    />
    <ConfirmationModal
      @onConfirm="emitConfirmationModalConfirm"
      @onCancel="isStatusConfirmationModalOpen = false"
      :text-start-icon="'info.svg'"
      v-if="isStatusConfirmationModalOpen"
    >
      <p style="padding: 2px 0" class="content-normal">
        {{ selectedItem.statusConfirmationModalText }}
      </p>
    </ConfirmationModal>
  </div>
</template>

<script>
import DeleteModal from "@/components/common/DeleteModal";
import ConfirmationModal from "@/components/common/ConfirmationModal";

export default {
  name: "BaseButton",
  components: { ConfirmationModal, DeleteModal },
  props: [
    "label",
    "height",
    "iconLeft",
    "iconRight",
    "primary",
    "size",
    "items",
    "displayKey",
    "displayValue",
    "iconKey",
    "descriptionKey",
    "backgroundColor",
    "labelColor",
    "borderColor",
    "iconColor",
    "iconsStyle",
    "customStyle",
    "isLoading",
    "emptyDropdownText",
    "transparent",
  ],
  data() {
    return {
      buttonStyle: {
        height: "32px",
        padding: "7px 11px",
        gap: "8px",
      },
      iconStyle: {},
      labelStyle: {},
      labelClass: "button-small",
      dropdownOpen: false,
      selectedItem: null,
      isConfirmModalOpen: false,
      isStatusConfirmationModalOpen: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.primary) {
        this.buttonStyle.background = "#000";
        this.labelStyle.color = "#fff";
      }
      if (this.size === "small") {
        this.buttonStyle.height = "24px";
        this.buttonStyle.padding = "3px 5px";
        this.buttonStyle.gap = "6px";
      }
      if (this.size === "medium") {
        this.buttonStyle.height = "28px";
        this.buttonStyle.padding = "6px 8px";
        this.buttonStyle.gap = "8px";
      }
      if (this.size === "large") {
        this.buttonStyle.height = "40px";
        this.buttonStyle.padding = "11px 15px";
        this.labelStyle.lineHeight = "16px";
        this.labelClass = "content-normal";
      }
      if (this.transparent) {
        this.labelStyle.color = "#000000";
        this.buttonStyle.background = "transparent";
        this.buttonStyle.borderColor = "#000000";
      }
      if (this.size === "icon") {
        this.buttonStyle = {
          cursor: "pointer",
        };
      }
      if (this.backgroundColor) {
        this.buttonStyle.background = this.backgroundColor;
      }
      if (this.labelColor) {
        this.labelStyle.color = this.labelColor;
      }
      if (this.iconsStyle) {
        this.iconStyle = this.iconsStyle;
      }
      if (this.borderColor) {
        this.buttonStyle.borderColor = this.borderColor;
      }

      if (this.customStyle) {
        this.buttonStyle = this.customStyle;
      }
    },
    buttonClicked() {
      if (!this.items) {
        this.$emit("click");
      } else {
        this.openDropdown();
      }
    },
    selectItem(item) {
      if (item.showConfirmModal) {
        this.isConfirmModalOpen = true;
        this.selectedItem = item;
        return;
      }
      if (item.statusConfirmationModal) {
        this.isStatusConfirmationModalOpen = true;
        this.selectedItem = item;
        this.closeDropdown();
        return;
      }
      this.$emit("itemSelected", item);
      this.closeDropdown();
    },
    emitSelectedItem() {
      this.isConfirmModalOpen = false;
      this.$emit("itemSelected", this.selectedItem);
      this.selectedItem = null;
    },
    emitConfirmationModalConfirm() {
      this.isStatusConfirmationModalOpen = false;
      this.$emit("onConfirm", this.selectedItem);
      this.selectedItem = null;
    },
    openDropdown() {
      if (this.items.length < 1 && !this.emptyDropdownText) return;
      this.dropdownOpen = true;
      this.$nextTick(() => {
        const rect = this.$refs.buttonDropdown.getBoundingClientRect();
        if (rect.x + rect.width > window.innerWidth) {
          this.$refs.buttonDropdown.style.right = "0px";
        }

        // this.$refs.buttonDropdown.focus();
      });
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.button-container {
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  border: 1px solid #e6e8ec;
}

.button-container:hover {
  background: $grey;
}

.button-dropdown-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  border: 1px solid #e6e8ec;
  background-color: white;
  outline: none;
  min-width: 80px;
  max-width: 280px;
  max-height: 344px;
  overflow-y: scroll;
  z-index: 8;
  transform: translateY(4px);
}

.button-dropdown-item {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 8px 12px;
  cursor: pointer;
}

.button-dropdown-item:hover {
  background: $grey;
}

.loader {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #a0a0a0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
</style>
