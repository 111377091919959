import axios from "axios";

let controller = new AbortController();
let signal = controller.signal;
export default {
  newAbortController() {
    controller = new AbortController();
    signal = controller.signal;
  },
  getAbortController() {
    return controller;
  },
  async getListingsFiltered(filters) {
    return (
      await axios.post("/api/listings/filter/all/1", filters, {
        signal: signal,
      })
    ).data;
  },
  async getListingsFilteredPage(data) {
    return (
      await axios.post(
        "/api/listings/filter/paginated/:id/" + data.page,
        data,
        {
          signal: signal,
        },
      )
    ).data;
  },
  async getListingsPage(data) {
    return (
      await axios.post("/api/listings/paginated/page/" + data, data, {
        signal: signal,
      })
    ).data;
  },
  async getListingsWidgetData(data) {
    return (
      await axios.post("/api/listings/widgetdata/", data, {
        signal: signal,
      })
    ).data;
  },
  async getListingsWidgetDataArchived(data) {
    return (
      await axios.post("/api/listings/widgetdataarchived/0", data, {
        signal: signal,
      })
    ).data;
  },
  async getListingsFilteredPublic(filters) {
    return (
      await axios.post("/api/listings/filter/all-public/1", filters, {
        signal: signal,
      })
    ).data;
  },
  async getListingsFilteredArchived(data) {
    return (
      await axios.post(
        "/api/listings/filter/paginated/" + 0 + "/" + data.page,
        data,
        {
          signal: signal,
        },
      )
    ).data;
  },
  async getListingAutoSuggestions(listing) {
    return (
      await axios.post("/api/listing/auto-suggest/all", listing, {
        signal: signal,
      })
    ).data;
  },
  async getListingsPublicCustomCustomerOffers(customerId) {
    return await axios.post(
      `/api/listing/public/custom/customer-offers/${customerId}`,
    );
  },
  async updateListingPublicFunnelsMoveLeadAround(listingId, funnels) {
    return await axios.patch(
      `/api/listing/public/funnels/move-lead-around/${listingId}`,
      funnels,
    );
  },
  async updateListingActiveUntil(data) {
    return await axios.post(`/api/listing/update-status/active-until`, data);
  },
  async getListingStatus(listingId) {
    return await axios.get(`/api/listing/status/${listingId}`);
  },
  async updateListingIdentifier(listingId) {
    return await axios.get(`/api/listing/update/identifier/${listingId}`);
  },
};
