<template>
  <div class="save-button-wrapper" :id="id">
    <button
      v-if="!loading && !submitPlaceholder"
      class="save-button"
      @click="$emit('clicked')"
    >
      <img v-if="icon" :src="require('@/assets/images/Submit.svg')" alt="" />
      {{ $t("Save") }}
    </button>
    <button
      v-if="!loading && submitPlaceholder"
      class="save-button"
      @click="$emit('clicked')"
    >
      <img :src="require('@/assets/images/Submit.svg')" alt="" />
      {{ $t("Submit") }}
    </button>
    <div
      style="position: relative; width: 30px; height: 30px; margin: 12px 16px"
      v-if="loading"
      class="loader"
    ></div>
  </div>
</template>

<script>
export default {
  name: "SubmitButton",
  props: ["id", "loading", "submitPlaceholder", "icon"],
};
</script>

<style lang="scss" scoped>
.save-button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #000000;
  border: none;
  border-radius: 8px;
}
.save-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  gap: 8px;

  max-width: fit-content;
  height: 40px;

  font-family: "Inter", sans-serif;
  text-transform: none;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;
  background: #000000;
  border: none;
  border-radius: 8px;

  cursor: pointer;
}
</style>
