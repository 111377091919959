export default [
  {
    _id: "2",
    name: "allValues",
    filterTitle: "MyListings",
    isDefault: true,
    isSelected: true,
    conditions: [
      {
        name: "search",
        title: "Search",
        value: "",
        valueTitle: "",
      },
      {
        name: "areaMin",
        title: "areaMin",
        value: "",
        valueTitle: "",
      },
      {
        name: "areaMax",
        title: "areaMax",
        value: "",
        valueTitle: "",
      },
      {
        name: "balcony",
        title: "balcony",
        value: false,
        valueTitle: "",
      },
      {
        name: "booked",
        title: "is Booked",
        value: false,
        valueTitle: "",
      },
      {
        name: "brokerId",
        title: "Broker",
        value: "",
        valueTitle: "", // TODO
      },
      {
        name: "city",
        title: "City",
        value: "",
        valueTitle: "",
      },
      {
        name: "cityParts",
        title: "City Parts",
        value: [],
        valueTitle: "",
      },
      {
        name: "cityValue",
        title: "City Value",
        value: "",
        valueTitle: "",
      },
      {
        name: "conditions",
        title: "Conditions",
        value: [],
        valueTitle: "",
      },
      {
        name: "county",
        title: "County",
        value: "",
        valueTitle: "",
      },
      {
        name: "countyValue",
        title: "countyValue",
        value: "",
        valueTitle: "",
      },
      {
        name: "dealTypes",
        title: "dealTypes",
        value: [],
        valueTitle: "",
      },
      {
        name: "estateTypes",
        title: "estateTypes",
        value: [],
        valueTitle: "",
      },
      {
        name: "floorMax",
        title: "floorMax",
        value: "",
        valueTitle: "",
      },
      {
        name: "floorMin",
        title: "floorMin",
        value: "",
        valueTitle: "",
      },
      {
        name: "months",
        title: "months",
        value: "",
        valueTitle: "",
      },
      {
        name: "createdFrom",
        title: "createdFrom",
        value: "",
        valueTitle: "",
      },
      {
        name: "createdTo",
        title: "createdTo",
        value: "",
        valueTitle: "",
      },
      {
        name: "updatedFrom",
        title: "updatedFrom",
        value: "",
        valueTitle: "",
      },
      {
        name: "updatedTo",
        title: "updatedTo",
        value: "",
        valueTitle: "",
      },
      {
        name: "publishedFrom",
        title: "publishedFrom",
        value: "",
        valueTitle: "",
      },
      {
        name: "publishedTo",
        title: "publishedTo",
        value: "",
        valueTitle: "",
      },
      {
        name: "parkingOption",
        title: "parkingOption",
        value: false,
        valueTitle: "",
      },
      {
        name: "priceMax",
        title: "priceMax",
        value: "",
        valueTitle: "",
      },
      {
        name: "priceMin",
        title: "priceMin",
        value: "",
        valueTitle: "",
      },
      {
        name: "roomMax",
        title: "roomMax",
        value: "",
        valueTitle: "",
      },
      {
        name: "roomMin",
        title: "roomMin",
        value: "",
        valueTitle: "",
      },
      {
        name: "roomsSeparate",
        title: "roomsSeparate",
        value: false,
        valueTitle: "",
      },
      {
        name: "sauna",
        title: "sauna",
        value: false,
        valueTitle: "",
      },
      {
        name: "statuses",
        title: "statuses",
        value: [],
        valueTitle: "",
      },
      {
        name: "storeRoom",
        title: "storeRoom",
        value: false,
        valueTitle: "",
      },
      {
        name: "lastUpdated",
        title: "lastUpdated",
        value: false,
        valueTitle: "",
      },
      {
        name: "firstPublished",
        title: "firstPublished",
        value: false,
        valueTitle: "",
      },
      {
        name: "waterMark",
        title: "waterMark",
        value: "",
        valueTitle: "",
      },
      {
        name: "amlFiles",
        title: "amlFiles",
        value: "",
        valueTitle: "",
      },
      {
        name: "contracts",
        title: "contracts",
        value: "",
        valueTitle: "",
      },
    ],
  },
];
