import axios from "axios";

let controller = new AbortController();
let signal = controller.signal;
export default {
  newAbortController() {
    controller = new AbortController();
    signal = controller.signal;
  },
  getAbortController() {
    return controller;
  },
  async getProjectById(id) {
    return (await axios.get(`/api/project/${id}`, { signal: signal })).data;
  },
  async getUnfinishedTasksCount(projectId) {
    return (
      await axios.get(`/api/tasks/project/unfinished/count/${projectId}`, {
        signal: signal,
      })
    ).data;
  },
  async getUnfinishedEventsCount(projectId) {
    return (
      await axios.get(
        `/api/tasks/project/unfinished/events/count/${projectId}`,
        {
          signal: signal,
        },
      )
    ).data;
  },
  async getProjects() {
    return (
      await axios.get("/api/project", {
        signal: signal,
      })
    ).data.deals;
  },
  async getProjectsCustom(body) {
    return (
      await axios.post("/api/project/custom", body, {
        signal: signal,
      })
    ).data;
  },
  async toggleInterestOutputs(projectId, body) {
    return await axios.post(
      `/api/project/interest/change-outputs/${projectId}`,
      body,
    );
  },
  async saveFunnel(projectId, funnel) {
    return await axios.post(`/api/project/leads/${projectId}`, funnel);
  },
  async updateFunnel(projectId, funnel) {
    return await axios.patch(`/api/project/leads/${projectId}`, funnel);
  },
  async deleteFunnel(projectId, funnel) {
    return await axios.post(`/api/project/leads/delete/${projectId}`, funnel);
  },
  async changeInterestStatus(projectId, body) {
    return await axios.post(`/api/project/interest/status/${projectId}`, body);
  },
  async changeInterestWideSearch(projectId, body) {
    return await axios.post(
      `/api/project/interest/widesearch/${projectId}`,
      body,
    );
  },
  async changeInterestComment(projectId, body) {
    return await axios.post(`/api/project/interest/comment/${projectId}`, body);
  },
  async deleteInterest(projectId, body) {
    return (
      await axios.post(`/api/project/interest/delete/${projectId}`, body, {
        signal: signal,
      })
    ).data;
  },
  // async getProjectsFiltered(filters) {
  //   return (
  //     await axios.post("/api/projects/filter/all/1", filters, {
  //       signal: signal,
  //     })
  //   ).data;
  // },
  //   async getProjectsFilteredArchived(filters) {
  //   return (
  //     await axios.post("/api/projects/filter/all/0", filters, {
  //       signal: signal,
  //     })
  //   ).data;
  // },
};
