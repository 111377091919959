<template>
  <div id="overlay-interests" class="overlay">
    <v-card class="modal-wrapper">
      <div class="sticky-header">
        <div class="modal-header">
          <h4 v-if="!existingInterest" class="modal-title">
            {{ this.$t("AddInterest") }}
          </h4>
          <h4 v-else class="modal-title">{{ this.$t("EditInterest") }}</h4>
        </div>
      </div>
      <v-divider
        style="top: 96px; z-index: 111; position: fixed; width: 560px"
      ></v-divider>
      <div class="content-wrapper" v-if="loaded">
        <div class="interest-section" v-if="!interest.development">
          <div class="interest-section-header">
            <h5>{{ this.$t("Subblock.GeneralData") }}</h5>
            <div class="clear-button" type="button" @click="clearForm('main')">
              <p class="content-small">
                {{ this.$t("Clear") }}
              </p>
            </div>
          </div>
          <div class="interest-section-form">
            <div class="flex-row">
              <BaseDropdown
                :key="interest.transactionType"
                :id="'interest-transaction-types'"
                :placeholder="this.$t('TransactionType')"
                :dropdown-allow-null="true"
                :items="correctTransactionTypes"
                v-model="interest.transactionType"
                :disabled="interest.transactionType && module === 'projects'"
                :required="true"
                type="dropdown"
                :error="showErrors && !interest.transactionType"
              />
            </div>

            <div class="flex-row">
              <BaseDropdown
                :key="interest.estateType"
                :id="'interest-estate-types'"
                :placeholder="this.$t('EstateType')"
                :dropdown-allow-null="false"
                :items="estateTypes"
                v-model="interest.estateType"
                :required="true"
                type="dropdown"
                :error="showErrors && !interest.estateType"
                @update:modelValue="resetExtras($event)"
              />
            </div>
            <div
              class="flex-row"
              v-if="correctExtraTypes && correctExtraTypes.length"
            >
              <DropdownMulti
                :key="'extras' + multiUpdater"
                :custom-style-button="'width: 100% !important; height: 52px;'"
                :custom-style="'width: 100% !important; height: 52px;'"
                :items="correctExtraTypes"
                :display-key="'name'"
                :value-key="'name'"
                :width="'100%'"
                :regular-size="true"
                :default-item="extraTypes.placeholder"
                :selected="interest.extraTypes"
                :pre-selected-items="interest.extraTypes"
                @selectedItems="interest.extraTypes = [...arguments]"
              />
            </div>
          </div>
        </div>

        <div
          v-if="!interest.development"
          class="interest-section white-background"
        >
          <div class="interest-section-header">
            <h5>{{ this.$t("Area") }}</h5>
            <div
              class="clear-button"
              type="button"
              @click="clearForm('address')"
            >
              <p class="content-small">
                {{ this.$t("Clear") }}
              </p>
            </div>
          </div>
          <div :key="addressUpdater" class="interest-section-form">
            <BaseDropdown
              v-if="counties"
              :id="'interest-counties'"
              :placeholder="this.$t('County')"
              :items="counties"
              :return-object="true"
              v-model="interest.address.county"
              type="dropdown"
              @update:modelValue="setCounty"
            />
            <div
              class="flex-row"
              style="
                display: flex;
                margin-top: 12px;
                gap: 12px;
                flex-wrap: nowrap;
              "
            >
              <DropdownMulti
                :items="cities(interest)"
                :selected-items="interest.address.cities"
                :width="'221px'"
                :height="'52px'"
                :display-key="'name'"
                :value-key="'value'"
                :default-value="$t('City')"
                @input="updateSelectedCities"
              />
              <DropdownMulti
                :items="cityParts(interest)"
                :selected-items="interest.address.cityParts"
                :width="'221px'"
                :height="'52px'"
                :display-key="'name'"
                :value-key="'value'"
                :default-value="$t('CityPart')"
                @input="updateSelectedCityParts"
              />
            </div>
          </div>
        </div>

        <div class="interest-section">
          <div class="interest-section-header">
            <h5>{{ this.$t("GeneralSpecifications") }}</h5>
            <div
              class="clear-button"
              type="button"
              @click="clearForm('general')"
            >
              <p class="content-small">
                {{ this.$t("Clear") }}
              </p>
            </div>
          </div>
          <div class="interest-section-form">
            <div class="price-row flex-row">
              <BaseInput
                id="min-price"
                type="number"
                :format="'price'"
                v-model="interest.minPrice"
                :placeholder="this.$t('MinPrice')"
              />
              <BaseInput
                id="max-price"
                type="number"
                :format="'price'"
                v-model="interest.maxPrice"
                :placeholder="this.$t('MaxPrice')"
              />
            </div>
            <div class="price-row flex-row">
              <BaseInput
                id="min-price-m2"
                type="number"
                v-model="interest.minPricePerSquareMeter"
                :placeholder="this.$t('MinPrice')"
                icon-right="euro_m2.svg"
              />

              <BaseInput
                id="max-price-m2"
                type="number"
                v-model="interest.maxPricePerSquareMeter"
                :placeholder="this.$t('MaxPrice')"
                icon-right="euro_m2.svg"
              />
            </div>
            <div class="price-row flex-row">
              <BaseInput
                id="min-general-surfaceArea"
                type="number"
                v-model="interest.minGeneralSurfaceArea"
                :placeholder="'Min ' + this.$t('generalSurfaceArea')"
                icon-right="SquareMeter.svg"
              />

              <BaseInput
                id="max-general-surfaceArea"
                type="number"
                v-model="interest.maxGeneralSurfaceArea"
                :placeholder="'Max ' + this.$t('generalSurfaceArea')"
                icon-right="SquareMeter.svg"
              />
            </div>
            <div class="price-row flex-row">
              <BaseInput
                id="min-general-surfaceArea"
                type="number"
                v-model="interest.minNumberOfRooms"
                :placeholder="'Min ' + this.$t('roomCount')"
              />

              <BaseInput
                id="max-number-of-rooms"
                type="number"
                v-model="interest.maxNumberOfRooms"
                :placeholder="'Max ' + this.$t('roomCount')"
              />
            </div>
            <div class="price-row flex-row">
              <BaseInput
                id="min-floor"
                type="number"
                v-model="interest.minFloor"
                :placeholder="this.$t('FloorFrom')"
              />

              <BaseInput
                id="max-floor"
                type="number"
                v-model="interest.maxFloor"
                :placeholder="this.$t('FloorTo')"
              />
            </div>
          </div>
          <div class="extras-section">
            <p class="content-small-bold">{{ this.$t("Extras") }}</p>
            <div class="extras-row">
              <BaseToggle
                v-model="interest.extras.balcony"
                :label="this.$t('Balcony')"
              />
              <BaseToggle
                v-model="interest.extras.parking"
                :label="this.$t('Parking')"
              />
              <BaseToggle
                v-model="interest.extras.roomsSeparate"
                :label="this.$t('RoomsSeparate')"
              />
              <BaseToggle
                v-model="interest.extras.storeRoom"
                :label="this.$t('Storeroom')"
              />
              <BaseToggle
                v-model="interest.extras.sauna"
                :label="this.$t('Sauna')"
              />
            </div>
          </div>
        </div>

        <div class="interest-section white-background">
          <div class="interest-section-header">
            <h5>{{ this.$t("Comment") }}</h5>
          </div>
          <div class="interest-section-form">
            <BaseTextarea id="interest-note" v-model="interest.comment" />
          </div>
        </div>
      </div>
      <div class="buttons-container">
        <v-divider
          style="z-index: 111; position: absolute; width: 560px"
        ></v-divider>

        <div
          class="flex-row"
          style="
            justify-content: space-between;
            padding-right: 50px;
            padding-top: 24px;
            padding-left: 24px;
          "
        >
          <div>
            <button
              v-show="
                (existingInterest && projectId) ||
                (existingInterest && !customerData.isProspect)
              "
              style="align-self: flex-start"
              class="delete-button"
              color="blue darken-1"
              text
              @click="deleteInterest()"
            >
              <img
                style="padding-right: 8px"
                :src="require('@/assets/images/TrashBin.svg')"
                alt=""
              />
              {{ this.$t("Delete") }}
            </button>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              gap: 8px;
            "
          >
            <button
              class="cancel-button"
              color="blue darken-1"
              text
              @click="closeModal()"
            >
              {{ this.$t("Cancel") }}
            </button>

            <button
              class="save-button"
              color="blue darken-1"
              text
              @click="submitInterest()"
            >
              <img
                style="padding-right: 8px"
                :src="require('@/assets/images/Submit.svg')"
                alt=""
              />
              {{ this.$t("Submit") }}
            </button>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import DropdownMulti from "@/components/common/DropdownMulti";
import { nanoid } from "nanoid";
import BaseInput from "@/components/common/BaseInput";
import BaseTextarea from "@/components/common/BaseTextarea";
import BaseDropdown from "@/components/common/BaseDropdown";
import BaseToggle from "@/components/common/BaseToggle.vue";
import _ from "lodash";
import contactApi from "@/http/contact";
import projectApi from "@/http/project";

const locationsEstonia = require("../../components/common/locations_estonia2");
export default {
  name: "InterestPanel",
  props: [
    "customerId",
    "existingInterest",
    "dealType",
    "customerData",
    "projectId",
  ],
  components: {
    DropdownMulti,
    BaseInput,
    BaseTextarea,
    BaseToggle,
    BaseDropdown,
  },
  data() {
    return {
      module: "contacts",
      initialCounty: true,
      loaded: false,
      showErrors: false,
      errors: {
        transactionType: null,
        estateType: null,
      },
      loading: false,

      interest: {
        _id: null,
        transactionType: null,
        estateType: null,
        extraTypes: [],
        minPrice: null,
        maxPrice: null,
        minPricePerSquareMeter: null,
        maxPricePerSquareMeter: null,
        area: null,
        funnels: [],
        marketplaces: [
          {
            name: "kv",
            isActive: false,
          },
          {
            name: "city24",
            isActive: false,
          },
          {
            name: "kv24",
            isActive: false,
          },
          {
            name: "www",
            isActive: false,
          },
          {
            name: "www2",
            isActive: false,
          },
          {
            name: "www3",
            isActive: false,
          },
          {
            name: "www4",
            isActive: false,
          },
        ],
        address: {
          county: null,
          cities: null,
          cityParts: null,
          street: null,
          houseNr: null,
        },
        minGeneralSurfaceArea: null,
        maxGeneralSurfaceArea: null,
        minNumberOfRooms: null,
        maxNumberOfRooms: null,
        minFloor: null,
        maxFloor: null,
        extras: {
          balcony: false,
          parking: false,
          roomsSeparate: false,
          storeRoom: false,
          sauna: false,
        },
        comment: "",
        statusCode: 1,
      },

      counties: null,
      citiesUpdater: 0,
      cityPartsUpdater: 0,
      addressUpdater: 0,
      selectedCities: [],
      selectedCityParts: [],
      correctExtraTypes: [],
      multiUpdater: 0,
      estateTypes: [
        {
          name: this.$t("CommercialSpace"),
          value: "commercial",
        },
        { name: this.$t("Apartment"), value: "apartment" },
        { name: this.$t("House"), value: "house" },
        { name: this.$t("HousePart"), value: "house_part" },
        { name: this.$t("LandPlot"), value: "land" },
        { name: this.$t("SummerHouse"), value: "cottage" },
        { name: this.$t("Garage"), value: "garage" },
      ],
      transactionTypes: [
        { name: this.$t("Purchase"), value: "sale" },
        { name: this.$t("Rent"), value: "rent" },
        { name: this.$t("ShortTermRent"), value: "short_term_rent" },
      ],
      extraTypes: {
        placeholder: {
          name: this.$t("CommercialPurposeOfUse"),
          value: "placeholder",
          _id: () => nanoid(16),
        },
        land: [
          {
            name: this.$t("CommercialPurposeOfUse"),
            value: "placeholder",
            _id: () => nanoid(16),
          },
          {
            name: this.$t("ResidentialLand"),
            value: "residentialLand",
          },
          {
            name: this.$t("ResidentialLandApartments"),
            value: "residentialLandApartment",
          },
          { name: this.$t("CommercialLand"), value: "commercialLand" },
          { name: this.$t("ProductionLand"), value: "productionLand" },
          { name: this.$t("IndustrialLand"), value: "industrialLand" },
          { name: this.$t("ForestLand"), value: "forestLand" },
          { name: this.$t("ProfitYieldingLand"), value: "profityieldingLand" },
          { name: this.$t("OtherUse"), value: "otherUse" },
        ],
        commercial: [
          {
            name: this.$t("CommercialPurposeOfUse"),
            value: "placeholder",
            _id: () => nanoid(16),
          },
          {
            name: this.$t("Office"),
            value: "office",
          },
          { name: this.$t("TradeShop"), value: "tradeshop" },
          { name: this.$t("Accomodation"), value: "accomodation" },
          { name: this.$t("Services"), value: "services" },
          { name: this.$t("WareHouse"), value: "warehouse" },
          { name: this.$t("Production"), value: "production" },
          {
            name: this.$t("WareHouseProduction"),
            value: "warehouseProduction",
          },
          { name: this.$t("FoodServices"), value: "foodServices" },
          { name: this.$t("OtherUsePossible"), value: "otherUsePossible" },
          { name: this.$t("NotDefined"), value: "notDefined" },
        ],
      },
    };
  },
  created() {
    this.initialize();
  },
  mounted() {
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay") {
        this.closeModal();
        e.target.style.display = "none";
      }
    };
  },
  watch: {
    "interest.estateType": function (estateType) {
      this.correctExtraTypes = this.extraTypes[estateType];
    },
    correctExtraTypes() {
      this.multiUpdater++;
    },
  },
  computed: {
    correctTransactionTypes() {
      if (!this.dealType) {
        return this.transactionTypes;
      } else if (this.dealType === "Purchase") {
        return this.transactionTypes.filter(
          (transactionType) => transactionType.value === "sale",
        );
      } else if (this.dealType === "AquireLease") {
        return this.transactionTypes.filter(
          (transactionType) =>
            transactionType.value === "rent" ||
            transactionType.value === "short_term_rent",
        );
      }
      return this.transactionTypes;
    },
  },
  methods: {
    async initialize() {
      if (this.existingInterest) {
        this.interest = _.cloneDeep(this.existingInterest);

        //map project extras to dropdown items format
        if (this.extraTypes[this.interest.estateType]) {
          this.correctExtraTypes = this.extraTypes[this.interest.estateType];
          let extras = [];

          extras = this.correctExtraTypes.filter((type) =>
            this.interest.extraTypes.includes(type.value),
          );
          this.interest.extraTypes = extras;
        }
      }

      let counties = [];
      locationsEstonia.locations.counties.forEach((location) => {
        counties.push({
          name: location.NIMETUS,
          value: location.KOOD,
        });
      });
      this.counties = counties;

      if (this.projectId) this.module = "projects";
      console.log("moodul: ", this.module);
      this.loaded = true;
    },
    resetExtras(ev) {
      if (this.existingInterest && this.existingInterest.estateType === ev)
        return;
      if (this.existingInterest && this.existingInterest.estateType === ev)
        return;

      console.log("ongi");
      this.interest.extraTypes = [];
      this.multiUpdater++;
    },
    isFieldValid(fieldName, interest) {
      switch (fieldName) {
        case "transactionType":
          if (!interest.transactionType) {
            this.errors.transactionType = "FieldRequired";
            return false;
          }
          break;
        case "estateType":
          if (!interest.estateType) {
            this.errors.estateType = "FieldRequired";
            return false;
          }
          break;
      }
      return true;
    },
    setCounty() {
      //workaround for dropdown
      if (this.initialCounty) {
        this.citiesUpdater++;
        this.cityPartsUpdater++;
        this.initialCounty = false;
      } else {
        this.interest.address.cities = [];
        this.interest.address.cityParts = [];
        this.citiesUpdater++;
        this.cityPartsUpdater++;
      }
    },
    cities(interest) {
      let cities = null;
      if (interest.address.county) {
        let citiesTemp = [];
        locationsEstonia.locations.cities.forEach((location) => {
          if (location.YLEMKOMP_KOOD === interest.address.county.value) {
            let name = location.NIMETUS;
            let value = location.KOOD;
            let parent = location.YLEMKOMP_KOOD;
            citiesTemp.push({ name, value, parent });
          }
        });
        citiesTemp.forEach((city, index) => {
          if (city.name === "Tallinn") {
            const element = city;
            citiesTemp.splice(index, 1);
            citiesTemp.splice(1, 0, element);
          }
        });
        cities = citiesTemp;
      } else {
        cities = [];
      }
      return cities;
    },
    cityParts(interest) {
      let cityParts = [];
      if (interest.address.cities && interest.address.cities.length > 0) {
        locationsEstonia.locations.districts.forEach((location) => {
          if (
            interest.address.cities.some(
              (interestCity) => interestCity.value === location.YLEMKOMP_KOOD,
            )
          ) {
            let name = location.NIMETUS;
            let value = location.KOOD;
            let parent = location.YLEMKOMP_KOOD;
            cityParts.push({ name, value, parent });
          }
        });
      } else {
        cityParts = [];
      }
      return cityParts;
    },
    setCity(interest, city) {
      if (city.length === 0) {
        interest.address.cities = null;
        interest.address.cityParts = null;
        this.cityPartsUpdater++;
        this.citiesUpdater++;
      } else {
        interest.address.cities = city;
        const cityPartList = this.cityParts(interest);
        if (interest.address.cityParts) {
          interest.address.cityParts = interest.address.cityParts.filter(
            (citypart) => {
              if (cityPartList.some((part) => part.value === citypart.value)) {
                return citypart;
              }
            },
          );
        }
        this.cityPartsUpdater++;
      }
    },
    setCityParts(interest, cityPart) {
      if (cityPart.length === 0) {
        interest.address.cityParts = null;
      } else {
        interest.address.cityParts = cityPart;
      }
    },

    clearForm(section) {
      if (section === "main") {
        this.interest.estateType = null;
        this.interest.transactionType = null;
        this.interest.estateTypeExtras = [];
      }
      if (section === "address") {
        this.interest.address = {
          county: null,
          cities: null,
          cityParts: null,
          street: null,
        };
        this.addressUpdater++;
      }
      if (section === "general") {
        this.interest.minPrice = null;
        this.interest.maxPrice = null;
        this.interest.minPricePerSquareMeter = null;
        this.interest.maxPricePerSquareMeter = null;
        this.interest.maxPrice = null;
        this.interest.minGeneralSurfaceArea = null;
        this.interest.maxGeneralSurfaceArea = null;
        this.interest.minNumberOfRooms = null;
        this.interest.maxNumberOfRooms = null;
        this.interest.minFloor = null;
        this.interest.maxFloor = null;
      }
    },
    closeModal() {
      this.$emit("closed", "false");
    },

    checkIfInterestSubmittable(interest) {
      let interestSubmittable = false;
      for (const [key, value] of Object.entries(interest)) {
        if (key === "_id") {
          //_id exists always
          continue;
        }
        if (key === "address") {
          // eslint-disable-next-line no-unused-vars
          for (const [keyAddress, valueAddress] of Object.entries(value)) {
            //check if address object has any values
            if (valueAddress) {
              interestSubmittable = true;
            }
          }
        } else if (key === "funnels") {
          if (value.length > 0) {
            interestSubmittable = true;
          }
        } else if (key === "addresss") {
          const isEmpty = Object.values(value).every(
            (x) => x === null || x === "",
          );
          if (!isEmpty) {
            interestSubmittable = true;
          }
        } else if (value) {
          interestSubmittable = true;
        }
      }
      return interestSubmittable;
    },

    async submitInterest() {
      if (this.loading) return;
      this.loading = true;

      let interest = this.interest;

      const itemsAreCorrect = (arr) => arr.every((i) => typeof i === "string");
      //convert dropdown objects to string values
      if (!interest.development) {
        if (!itemsAreCorrect(interest.extraTypes)) {
          interest.extraTypes = [].concat(...interest.extraTypes);
          interest.extraTypes = interest.extraTypes.map((extra) => extra.value);
        }
      }

      //check which forms have values in them
      let interestSubmittable = this.checkIfInterestSubmittable(interest);

      if (interestSubmittable) {
        interest.autoSuggestions = [];
      } else {
        return;
      }
      if (
        !this.isFieldValid("estateType", interest) ||
        !this.isFieldValid("transactionType", interest)
      ) {
        this.showErrors = true;
      } else {
        this.showErrors = false;
      }
      if (this.showErrors) {
        return;
      }
      if (!this.existingInterest) {
        await this.saveInterest(interest);
      } else {
        await this.updateInterest(interest);
      }
      this.loading = false;
      this.closeModal();
    },
    async saveInterest(interest) {
      if (this.module === "contacts") {
        await axios.post(`/api/contact/interest/${this.customerId}`, interest);
      } else if (this.module === "projects") {
        await axios.post(`/api/project/interest/${this.projectId}`, interest);
      }
      this.$toast.success(this.$t("InterestCreated"));
    },
    async updateInterest(interest) {
      if (this.module === "contacts") {
        await axios.post(
          `/api/contact/interest/update/${this.customerId}`,
          interest,
        );
      } else if (this.module === "projects") {
        await axios.post(
          `/api/project/interest/update/${this.projectId}`,
          interest,
        );
      }
      this.$toast.success(this.$t("InterestUpdated"));
    },
    async deleteInterest() {
      try {
        this.loading = true;
        if (!this.projectId) {
          await contactApi.deleteInterest(this.customerId, this.interest);
        } else {
          let interest = this.interest;
          await projectApi.deleteInterest(this.projectId, { interest });
        }

        this.loading = false;
        this.closeModal();
      } catch (e) {
        this.$toast.error(this.$t("ErrorDeleting"));
      }
    },
    updateSelectedCities(selectedItems) {
      this.interest.address.cities = selectedItems;
    },
    updateSelectedCityParts(selectedItems) {
      this.interest.address.cityParts = selectedItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  right: 0;
  top: 0%;
  height: 100vh;
  bottom: 0%;
  background-color: #ffffff;
  padding-top: 0px !important;
  z-index: 7;
  width: 560px;
  overflow: scroll;
  border: 1px solid #e6e8ec;
}

.v-window {
  overflow: scroll;
}

.modal-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 32px;
  padding-left: 32px;
  justify-content: space-between;
}
.interest-section {
  padding: 32px 72px 32px 32px;
  background: #fafafa;
}
.interest-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.interest-section-form {
  margin-top: 24px;
}
.modal-title {
  font-size: 24px;
}

.white-background {
  background: #ffffff;
}
.content-wrapper {
  margin-top: 96px;
  padding: 0px 0px 113px;
}

:deep(.v-window) {
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 13;
}

.buttons-container {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
  border-radius: 0px !important;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  position: fixed;
  background: white;
  padding-right: 56px;
  width: 560px;
  border-radius: 0px !important;
}

.cancel-button {
  margin-right: 8px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  width: 103px;
  height: 40px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.delete-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 40px;
}

.clear-row {
  width: 100%;
  justify-content: flex-end;
}

.clear-button {
  display: flex;
  flex-direction: row;
  gap: 2px;
  cursor: pointer;
  p {
    text-decoration: underline;
  }
}

:deep(.v-text-field--outlined fieldset) {
  background-color: white !important;
}

.price-row {
  gap: 12px;
  flex-wrap: inherit;
}

:deep(.v-sheet.v-card) {
  border-radius: 8px;
}

.min-price,
.max-price {
  max-width: 48.6%;
}

.add-another-row {
  margin-top: 24px !important;
  margin-left: 16px !important;
}

.link-small {
  cursor: pointer;
}
.extras-section {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.extras-row {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 24px;
}

:deep(textarea) {
  color: black !important;
}

:deep(.not-valid fieldset) {
  border: 2px solid #ff1e24 !important;
}
</style>
