<template>
  <div class="build-notification">
    <p class="content-bold">{{ $t("NewVersionNotification") }}</p>
    <div @click="$emit('update')" class="refresh-button">
      <p>{{ $t("Upgrade") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildUpdateNotification",
};
</script>

<style lang="scss" scoped>
.build-notification {
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  width: 100%;
  height: 64px;
  background: #fffacc;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 1px 0px;
  p {
    color: #a89963;
  }
}
.refresh-button {
  /* .button */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: #000000;
  border-radius: 8px;
  cursor: pointer;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
  }
}
</style>
