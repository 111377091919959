<template>
  <div class="contact-simple-info-card">
    <div class="contact-first-row">
      <div class="contact-main-info">
        <span v-if="customerName">
          <p :class="mainInfoFieldClass" @click="nameOnPress">
            {{ customerName }}
          </p>
        </span>

        <span v-if="phoneNumber">
          <span class="main-info-divider">|</span>
          <p :class="mainInfoFieldClass" @click="call">
            {{ phoneNumber }}
          </p>
        </span>
        <span v-if="email">
          <span class="main-info-divider">|</span>
          <p :class="mainInfoFieldClass" @click="sendEmail">
            {{ email }}
          </p>
        </span>
      </div>

      <ContactTags :tags-object="contact.tags" />
    </div>

    <UserNameContainer :item="contact.creator" :is-owner="true" />
  </div>
</template>

<script>
import ContactTags from "@/components/Kliendiregister/ContactTags";
import UserNameContainer from "@/components/Admin/UserNameContainer";
export default {
  name: "ContactSimpleInfoCard",
  components: { UserNameContainer, ContactTags },
  props: ["contact"],
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
    mainInfoFieldClass() {
      if (this.contact.customerName) return "hover-option";
      return "blurred";
    },
    customerName() {
      return this.contact.customerName
        ? this.contact.customerName
        : "xxxxxxxx xxxxxxx";
    },
    phoneNumber() {
      if (this.contact.phoneNumber) return this.contact.phoneNumber;
      if (this.contact.havePhoneNumber) return "+xxx xx xxx xxx";
      return null;
    },
    email() {
      if (this.contact.email) return this.contact.email;
      if (this.contact.haveEmail) return "xxxxxxx@xxxxxxxx.xxx";
      return null;
    },
  },
  methods: {
    nameOnPress() {
      if (this.contact.customerName) this.$emit("nameOnPress");
    },
    call() {
      if (!this.contact.phoneNumber) return;
      window.open(`tel:${this.contact.phoneNumber}`);
    },
    sendEmail() {
      if (!this.contact.email) return;
      window.open(`mailto:${this.contact.email}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.main-info-divider {
  padding: 0 8px;
}

.contact-main-info {
  display: flex;

  span {
    display: flex;
  }
}

.contact-first-row {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.contact-simple-info-card {
  display: grid;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $grey500;
}
</style>
