<template>
  <div style="display: flex">
    <div
      class="user-name-container content-small"
      @click="(event) => userNameOnPress(event)"
    >
      <span>
        {{ item.name }}
      </span>
      <span v-if="isOwner"> ({{ $t("Owner").toLowerCase() }}) </span>
    </div>
    <BrokerInfoPanel
      v-if="brokerInfoPanelOpen"
      :item="item"
      :position="brokerInfoPanelPosition"
      @closed="brokerInfoPanelOpen = false"
    />
  </div>
</template>

<script>
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";

export default {
  name: "UserNameContainer",
  props: ["item", "isOwner"],
  components: {
    BrokerInfoPanel,
  },
  data: () => ({
    brokerInfoPanelOpen: false,
    brokerInfoPanelPosition: null,
  }),
  methods: {
    userNameOnPress(event) {
      this.brokerInfoPanelPosition = {
        left: event.clientX + "px",
        top: event.clientY + "px",
      };
      this.brokerInfoPanelOpen = !this.brokerInfoPanelOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.user-name-container {
  background: $grey;
  padding: 2px 4px;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  &:hover {
    background-color: $orange;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
