<template>
  <div class="settings-wrapper">
    <div class="setting-row">
      <div class="setting-header">
        <div class="content-semibold">
          {{ $t("Interest") }} {{ $t("Status").toLocaleLowerCase() }}
        </div>
        <BaseToggle
          v-model="localInterest.statusCode"
          @click="
            $emit(
              'changeInterestStatus',
              localInterest.statusCode,
              localInterest,
            )
          "
          :label="$t('Active')"
        />
      </div>
      <div class="setting-description content-small">
        {{ $t("InterestStatusDescription") }}
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-header">
        <div class="content-semibold">{{ $t("Wide") }} {{ $t("search") }}</div>
        <BaseToggle
          v-model="localInterest.wideSearch"
          @click="
            $emit(
              'changeInterestWideSearch',
              localInterest.wideSearch,
              localInterest,
            )
          "
          :label="$t('Active')"
          :disabled="!localInterest.statusCode"
        />
      </div>
      <div class="setting-description content-small">
        {{ $t("InterestWideSearchDescription") }}
      </div>
    </div>
    <div class="setting-row" v-if="projectId">
      <div class="setting-header">
        <div class="content-semibold">{{ $t("ExportToWWW") }}</div>
        <BaseToggle
          class="output-toggle"
          :modelValue="
            localInterest.marketplaces.find((market) => market.name === 'www')
              .isActive
          "
          :label="$t('Active')"
          @update:modelValue="toggleOutput()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseToggle from "@/components/common/BaseToggle";
export default {
  name: "InterestSettings",
  components: { BaseToggle },
  props: ["interest", "projectId"],
  data() {
    return {
      localInterest: { ...this.interest },
    };
  },
  watch: {
    interest: {
      handler(newVal) {
        this.localInterest = { ...newVal };
      },
      deep: true,
    },
  },
  methods: {
    toggleOutput() {
      const marketplaceIndex = this.localInterest.marketplaces.findIndex(
        (market) => market.name === "www",
      );
      this.localInterest.marketplaces[marketplaceIndex].isActive =
        !this.localInterest.marketplaces[marketplaceIndex].isActive;

      this.$emit("toggled", {
        interestId: this.localInterest._id,
        marketplaces: this.localInterest.marketplaces,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/global_variables";

.settings-wrapper {
  border-top: 1px solid #e6e8ec;
  padding: 24px;
  display: grid;
  gap: 24px;
}

.setting-description {
  color: $grey950 !important;
}

.setting-row {
  display: grid;
  gap: 4px;
}

.setting-header {
  display: flex;
  gap: 16px;
}
</style>
