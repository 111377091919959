<template>
  <button class="cancel-button" @click="$emit('clicked')">
    {{ $t("Cancel") }}
  </button>
</template>

<script>
export default {
  name: "CancelButton",
};
</script>

<style lang="scss" scoped>
.cancel-button {
  margin-right: 8px;
  background-color: transparent;
  max-width: fit-content;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}
</style>
