<template>
  <div class="interest-listings" :id="interest._id + 'column'">
    <div
      v-show="
        (interest.autoSuggestions && interest.autoSuggestions.length) ||
        interest.funnels.length
      "
      class="interest-funnels-header"
    >
      <div
        :style="{ width: item.width, textAlign: 'left' }"
        v-for="item in headerItems"
        :key="item.value"
      >
        <p class="label10">
          {{ item.text }}
        </p>
      </div>
    </div>
    <div
      class="interest-funnels-wrapper"
      :id="interest._id + 'inside-column'"
      v-if="interest.funnels.length >= 1"
    >
      <div
        v-for="funnel in interest.funnels"
        :key="funnel._id"
        v-bind:class="{
          'funnel-row': chosenListingPreviewId !== funnel.listingId,
          'funnel-row listing-preview-opened':
            chosenListingPreviewId === funnel.listingId,
        }"
      >
        <div
          v-bind:class="{
            'name-wrapper-project': projectId,
            'name-wrapper': !projectId,
          }"
        >
          <p
            :id="'suggestion-title' + funnel.listingId + interest._id"
            @click="openListingPanel(funnel.listingId, interest._id)"
            v-bind:class="{
              'content-small fit pointer':
                chosenListingPreviewId !== funnel.listingId,
              'content-small-semibold fit pointer':
                chosenListingPreviewId === funnel.listingId,
            }"
          >
            {{ funnel.listingName }}
          </p>
        </div>
        <div v-if="interest.estateType !== 'land'" class="rooms-wrapper">
          <p
            v-bind:class="{
              'content-small  pointer':
                chosenListingPreviewId !== funnel.listingId,
              'content-small-semibold  pointer':
                chosenListingPreviewId === funnel.listingId,
            }"
          >
            {{ funnel.rooms ? funnel.rooms : "-" }}
          </p>
        </div>
        <div class="area-wrapper">
          <p
            v-bind:class="{
              'content-small  pointer':
                chosenListingPreviewId !== funnel.listingId,
              'content-small-semibold  pointer':
                chosenListingPreviewId === funnel.listingId,
            }"
          >
            {{ funnel.generalSurfaceArea ? funnel.generalSurfaceArea : "-" }}
          </p>
        </div>
        <div class="price-wrapper">
          <p
            v-bind:class="{
              'content-small  pointer':
                chosenListingPreviewId !== funnel.listingId,
              'content-small-semibold  pointer':
                chosenListingPreviewId === funnel.listingId,
            }"
          >
            {{ funnel.price ? $nMapper.price(funnel.price) : "-" }}
          </p>
        </div>

        <div class="step-wrapper">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <p
                v-if="funnel.step === 'Offers'"
                :id="'suggestion-step' + funnel.listingId + interest._id"
                v-bind="attrs"
                v-on="on"
                v-bind:class="{
                  'content-small  pointer':
                    chosenListingPreviewId !== funnel.listingId,
                  'content-small-semibold  pointer':
                    chosenListingPreviewId === funnel.listingId,
                }"
              >
                {{ $t("SendOffer") }}
              </p>
              <p
                v-else
                :id="'suggestion-step' + funnel.listingId + interest._id"
                v-bind="attrs"
                v-on="on"
                v-bind:class="{
                  'content-small  pointer':
                    chosenListingPreviewId !== funnel.listingId,
                  'content-small-semibold  pointer':
                    chosenListingPreviewId === funnel.listingId,
                }"
              >
                {{ $t(funnel.step) }}
              </p>
              <img
                class="suggestion-step-arrow"
                v-bind="attrs"
                v-on="on"
                :src="require('@/assets/images/direction=down.svg')"
                alt=""
              />
            </template>
            <v-card>
              <div
                class="steps-menu-row"
                v-for="(step, index) in funnelSteps"
                :key="step + index"
                @click="changeStep(funnel, step.value, interest._id)"
              >
                <p class="content-small">
                  {{ step.name }}
                </p>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div class="date-wrapper">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                class="suggestion-dot-menu"
                :src="require('@/assets/images/dot_menu.svg')"
                alt=""
              />
            </template>
            <v-card>
              <div class="steps-menu-row" @click="deleteFunnel(funnel)">
                <p class="edit-button-text">
                  {{ $t("Remove") }}
                </p>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>

    <div
      class="interest-funnels-wrapper gray-background"
      :id="interest._id + 'inside-column'"
      v-if="
        autoSuggestionsLoaded &&
        interest.autoSuggestions &&
        interest.autoSuggestions.length
      "
    >
      <div
        v-for="funnel in interest.autoSuggestions"
        :key="funnel._id"
        v-bind:class="{
          'funnel-row': chosenListingPreviewId !== funnel._id,
          'funnel-row listing-preview-opened':
            chosenListingPreviewId === funnel._id,
        }"
      >
        <div
          v-bind:class="{
            'name-wrapper-project': projectId,
            'name-wrapper': !projectId,
          }"
        >
          <p
            :id="'suggestion-title' + funnel._id + interest._id"
            @click="openListingPanel(funnel._id, interest._id)"
            v-bind:class="{
              'content-small fit pointer':
                chosenListingPreviewId !== funnel._id,
              'content-small-semibold fit pointer':
                chosenListingPreviewId === funnel._id,
            }"
          >
            {{ funnel.listingName }}
          </p>
        </div>
        <div
          v-if="interest.estateType !== 'land'"
          @click="openListingPanel(funnel._id, interest._id)"
          class="rooms-wrapper"
        >
          <p
            v-bind:class="{
              'content-small  pointer': chosenListingPreviewId !== funnel._id,
              'content-small-semibold  pointer':
                chosenListingPreviewId === funnel._id,
            }"
          >
            {{ funnel.rooms ? funnel.rooms : "-" }}
          </p>
        </div>
        <div
          @click="openListingPanel(funnel._id, interest._id)"
          class="area-wrapper"
        >
          <p
            v-bind:class="{
              'content-small  pointer': chosenListingPreviewId !== funnel._id,
              'content-small-semibold  pointer':
                chosenListingPreviewId === funnel._id,
            }"
          >
            {{ funnel.generalSurfaceArea ? funnel.generalSurfaceArea : "-" }}
          </p>
        </div>
        <div
          @click="openListingPanel(funnel._id, interest._id)"
          class="price-wrapper"
        >
          <p
            v-bind:class="{
              'content-small  pointer': chosenListingPreviewId !== funnel._id,
              'content-small-semibold  pointer':
                chosenListingPreviewId === funnel._id,
            }"
          >
            {{ funnel.price ? $nMapper.price(funnel.price) : "-" }}
          </p>
        </div>
        <div class="step-wrapper">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <p
                :id="'suggestion-step' + funnel._id + interest._id"
                v-bind="attrs"
                v-on="on"
                v-bind:class="{
                  'content-small  pointer':
                    chosenListingPreviewId !== funnel._id,
                  'content-small-semibold  pointer':
                    chosenListingPreviewId === funnel._id,
                }"
              >
                {{ $t("Suggestion") }}
              </p>

              <img
                class="suggestion-step-arrow"
                v-bind="attrs"
                v-on="on"
                :src="require('@/assets/images/direction=down.svg')"
                alt=""
              />
            </template>
            <v-card>
              <div
                class="steps-menu-row"
                v-for="(step, index) in funnelSteps"
                :key="step + index"
                @click="
                  createFunnelFromSuggestion(funnel, step.value, interest._id)
                "
              >
                <p
                  v-bind:class="{
                    'content-small  pointer':
                      chosenListingPreviewId !== funnel._id,
                    'content-small-semibold  pointer':
                      chosenListingPreviewId === funnel._id,
                  }"
                >
                  {{ step.name }}
                </p>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div class="date-wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import contactApi from "@/http/contact";
import projectApi from "@/http/project";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { nanoid } from "nanoid";

export default {
  name: "InterestListings",
  props: [
    "interest",
    "chosenListingPreviewId",
    "chosenInterestId",
    "customerId",
    "projectName",
    "projectId",
  ],
  data() {
    return {
      localInterest: JSON.parse(JSON.stringify(this.interest)),
      autoSuggestionsLoaded: false,
      module: "contacts",
      customer: null,
      project: null,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  created() {
    this.setupInterests();
  },
  computed: {
    ...mapGetters(["user"]),
    headerItems() {
      if (this.localInterest.estateType === "land") {
        return [
          {
            text: this.$t("Object"),
            value: "object",
            width: !this.projectId ? "226px" : "370px",
          },
          { text: this.$t("M2"), value: "area", width: "38px" },
          { text: this.$t("PriceEur"), value: "price", width: "64px" },
          { text: this.$t("Status"), value: "status", width: "96px" },
        ];
      }
      return [
        {
          text: this.$t("Object"),
          value: "object",
          width: !this.projectId ? "226px" : "370px",
        },
        { text: this.$t("Rooms"), value: "rooms", width: "40px" },
        { text: this.$t("M2"), value: "area", width: "38px" },
        { text: this.$t("PriceEur"), value: "price", width: "64px" },
        { text: this.$t("Status"), value: "status", width: "96px" },
      ];
    },
    funnelSteps() {
      return [
        { name: this.$t("SendOffer"), value: "Offers" },
        { name: this.$t("Interested"), value: "Interested" },
        { name: this.$t("Viewed"), value: "Viewed" },
        {
          name: this.$t("Negotiation & Contract"),
          value: "Negotiation & Contract",
        },
        { name: this.$t("LostArchived"), value: "Lost / Archived" },
      ];
    },
  },
  methods: {
    ...mapActions(["addLeadIdToOffersCount", "removeLeadInOffersCount"]),

    async setupInterests() {
      if (this.projectId) this.module = "projects";

      if (this.module === "contacts")
        this.customer = await contactApi.getContactById(this.customerId);
      if (this.module === "projects")
        this.project = (await projectApi.getProjectById(this.projectId))[0];

      this.getInterestSuggestions();
    },
    async getInterestSuggestions() {
      this.autoSuggestionsLoaded = false;
      await this.findMatches();
      let pollingCount = 0;
      const interval = setInterval(async () => {
        if (pollingCount === 3) {
          clearInterval(interval);
        } else {
          await this.findMatches();
          pollingCount++;
        }
      }, 5000);
      this.autoSuggestionsLoaded = true;
    },
    async findMatches() {
      let response = await axios.get(
        `/api/interest/auto-suggest/${this.localInterest._id}`,
      );
      this.localInterest.autoSuggestions = response.data;
      this.$emit(
        "autoSuggestionsCount",
        this.localInterest._id,
        this.localInterest.autoSuggestions.length,
      );
    },
    async deleteFunnel(funnel) {
      const deletedFunnel = funnel;

      if (this.module === "contacts") {
        await contactApi.deleteFunnel(this.customer.customerId, deletedFunnel);
      } else if (this.module === "projects") {
        await projectApi.deleteFunnel(this.projectId, deletedFunnel);
      }

      await axios.post(
        `/api/listing/leads/delete/${funnel.listingId}`,
        deletedFunnel,
      );
      this.$emit("funnelRemoved");
      this.$emit("refreshLeads");
    },
    openListingPanel(listingId, interestId) {
      this.$emit("openListingPreview", listingId, interestId);
    },
    closeListingPanel(listingId, interestId) {
      this.$emit("closeListingPreview", listingId, interestId);
    },
    async changeStep(funnel, step, interestId) {
      let previousStep = funnel.step;
      let newStep = step;
      let changedFunnel = funnel;
      changedFunnel.previousStep = previousStep;
      changedFunnel.step = newStep;
      changedFunnel.interestId = interestId;

      if (this.module === "contacts") {
        if (step === "Offers") {
          await this.addLeadIdToOffersCount(this.customer.customerId);
        } else if (changedFunnel.previousStep === "Offers") {
          await this.removeLeadInOffersCount(this.customer.customerId);
        }
      }
      if (this.module === "projects") {
        await projectApi.updateFunnel(this.projectId, changedFunnel);
      } else if (this.module === "contacts") {
        await contactApi.updateFunnel(this.customer.customerId, changedFunnel);
      }

      await axios.patch(`/api/listing/${funnel.listingId}`, changedFunnel);
      this.$emit("funnelRemoved");
      this.$emit("refreshLeads");
    },
    async createFunnelFromSuggestion(suggestion, step, interestId) {
      try {
        if (step === "Offers" && this.module === "contacts") {
          await this.addLeadIdToOffersCount(this.customer.customerId);
        }
        let newFunnel = {
          leadId: null,
          previousStep: "Matches",
          step: step,
          module: "contacts",
          listingId: suggestion._id,
          listingName: suggestion.listingName,
          interestId: interestId,
          creator: {
            userId: this.user._id,
            name: this.user.name,
            email: this.user.email,
            companyId: this.user.companyId,
            companyName: this.user.companyName,
          },
          _id: nanoid(16),
        };

        if (this.module === "projects") {
          newFunnel.leadId = this.projectId;
          newFunnel.projectName = this.projectName;
          newFunnel.module = "projects";
          await projectApi.saveFunnel(this.projectId, newFunnel);
        } else if (this.module === "contacts") {
          newFunnel.leadId = this.customer.customerId;
          await contactApi.saveFunnel(this.customer.customerId, newFunnel);
        }

        const removeBody = {
          listingId: suggestion._id,
          interestId: interestId,
        };
        await axios.post("/api/interest/auto-suggest/remove", removeBody);

        await axios.patch(`/api/listing/${suggestion._id}`, newFunnel);

        this.$emit("funnelRemoved");
        this.$emit("refreshLeads");
        await this.getInterestSuggestions();
      } catch (e) {
        console.log(e);
        this.$toast.error(this.$t("ErrorUpdating"));
      }
    },
  },
  watch: {
    interest: {
      handler(newVal) {
        this.localInterest = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.gray-background {
  background: #f4f5f7;
  padding: 12px;
}

.interest-funnels-wrapper {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  gap: 1px;
}

.name-wrapper {
  min-width: 226px;
  max-width: 226px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.name-wrapper-project {
  min-width: 370px;
  max-width: 370px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.fit {
  min-width: 100%;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.step-wrapper {
  text-align: right;
  min-width: 96px;
  max-width: 96px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;

  img {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
}

p {
  display: block !important;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.steps-menu-row {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e6e8ec;
  }
}

.date-wrapper {
  min-width: fit-content;
  text-align: right;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex: 1;
  gap: 4px;
  justify-content: flex-start;

  img {
    cursor: pointer;
  }
}

.interest-listings {
  border-top: 1px solid #e6e8ec;
}

.funnel-row {
  width: 100%;
  justify-content: space-between;
  max-width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-basis: 100%;
  padding: 4px 8px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  cursor: pointer;
}

.suggestion-dot-menu {
  width: 16px;
  height: 16px;
}

.listing-preview-opened {
  background: #ff5c01;
  border-radius: 4px;

  p {
    color: white !important;
  }

  .suggestion-step-arrow,
  .suggestion-dot-menu {
    filter: invert(98%) sepia(100%) saturate(6%) hue-rotate(130deg)
      brightness(104%) contrast(102%);
  }
}

.interest-funnels-header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 8px;
  padding-left: 8px;
  gap: 12px;
  height: 20px;
  border-bottom: 1px solid #000000;
}

.label10 {
  text-align: left !important;
  color: black !important;
}

.rooms-wrapper {
  min-width: 40px;
  max-width: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.area-wrapper {
  min-width: 38px;
  max-width: 38px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.price-wrapper {
  min-width: 64px;
  max-width: 64px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
</style>
