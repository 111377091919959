<template>
  <span
    v-if="!smallFont"
    :class="'default-deal-type-con ' + dealType?.toLowerCase()"
  >
    {{ $t(dealType) }}
  </span>
  <span
    v-else-if="smallFont"
    :class="'content-small ' + dealType"
    style="
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 4px;
      border-width: 0;
      padding: 0 4px;
      flex-direction: row;
      display: inline-flex;
    "
  >
    {{ $t(dealType) }}
  </span>
</template>

<script>
export default {
  name: "DealTypeContainer",
  props: ["dealType", "customStyle", "smallFont"],
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.default-deal-type-con {
  //overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 4px;
  border-width: 0;
  padding: 0 4px;
  flex-direction: row;
  display: inline-flex;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.sale {
  color: #0aaf60 !important;
  background: #f2ffe0;
}

.leaseout {
  color: #f81ca0 !important;
  background: #fff2f7;
}

.purchase {
  color: #0151af !important;
  background: #dbe8f7;
}

.aquirelease {
  color: #ff8a00 !important;
  background: #fff0d4;
}

.referral {
  color: #b54616 !important;
  background: #f4f5f7;
}
</style>
