<template>
  <div :id="id" class="input-field-container">
    <div
      ref="container"
      :key="id + '-' + fieldUpdater"
      @click="focusInputElement"
      class="form-field-large"
      :style="fieldStyle"
    >
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        style="display: block"
        v-if="iconLeft"
        :src="require(`@/assets/images/` + iconLeft)"
        alt=""
      />
      <div
        :class="{ required: fieldRequired }"
        style="display: flex; width: 100%; flex-direction: column"
      >
        <label
          v-if="fieldPlaceholder"
          ref="label"
          :style="labelStyle"
          class="content"
          >{{ fieldPlaceholder }}</label
        >
        <input
          @focusin="focusInputElementPlaceholder"
          @keyup.esc="closeDropdown"
          :disabled="fieldDisabled"
          @focusout="unfocusInput"
          ref="input"
          class="input-field content"
          v-model="fieldValue"
        />
      </div>
      <div
        v-if="this.fieldValue"
        @click="selectDropdownitem(null)"
        class="remove-button"
      >
        <img
          :src="require(`@/assets/images/close.svg`)"
          width="24"
          height="24"
        />
      </div>
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        v-if="fieldDropdownArrow"
        :src="require(`@/assets/images/dropdown_20_20.svg`)"
        alt=""
      />
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        v-if="iconRight"
        :src="require(`@/assets/images/` + iconRight)"
        alt=""
      />
    </div>

    <div
      ref="dropdown"
      v-click-outside="clickoutside"
      v-if="fieldDropdownOpen || (fieldDropdownOpen && fieldDropdownAddNew)"
      class="dropdown-container"
      :style="fieldDropdownStyle"
    >
      <div
        v-if="fieldDropdownItems.length > 0"
        style="
          position: relative;
          overflow-y: auto;
          max-height: 200px !important;
        "
        :class="{
          'dropdown-medium': fieldDropdownAllowNull,
          'dropdown-small': fieldDropdownAllowNull && fieldDropdownAddNew,
        }"
      >
        <div
          tabindex="0"
          @keyup.esc="closeDropdown"
          @keyup.enter="selectDropdownitem(item)"
          @click="selectDropdownitem(item)"
          :id="id + '-dropdown-item-' + index"
          v-for="(item, index) in fieldDropdownItems"
          :key="item.value ? item.value : index"
          class="content dropdown-item"
          style="display: flex; flex-wrap: nowrap"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        style="background-color: white"
        v-else-if="fieldValue && fieldDropdownItems.length === 0"
      >
        <p class="content no-results">{{ $t("NoResultsFound") }}</p>
      </div>
      <div
        tabindex="0"
        v-if="fieldDropdownAddNew"
        @keyup.esc="closeDropdown"
        @keyup.enter="dropdownAddNewButton"
        @click="dropdownAddNewButton"
        style="display: flex; gap: 8px; justify-content: center"
        class="dropdown-item add-new"
      >
        <img
          :src="require(`@/assets/images/action-add_16_16.svg`)"
          width="22"
          height="22"
        />
        <p class="content">{{ $t("AddNew") }}</p>
      </div>
    </div>
    <div
      v-if="fieldError"
      class="content error-message"
      style="margin: 12px 12px 0 0"
    >
      {{
        fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BaseProjectSearch",
  props: [
    "id",
    "value",
    "placeholder",
    "required",
    "showDropdownArrow",
    "dropdownAddNew",
    "dropdownAllowNull",
    "iconLeft",
    "iconRight",
    "error",
    "errorMessage",
    "dropdownOffsetY",
    "defocus",
    "getClient",
    "showCompletedProjects",
  ],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      fieldValue: "",
      fieldPlaceholder: "",
      fieldRequired: false,
      fieldDisabled: false,
      fieldDropdownArrow: false,
      fieldUpdater: 0,
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "7px 11px",
        gap: "12px",
        background: "white",
        cursor: "text",
      },
      labelStyle: {
        color: "#75787A",
      },
      fieldDropdownStyle: {
        marginTop: "8px",
        left: "0",
        top: "4px",
        right: "0",
      },
      fieldDropdownItems: [],
      fieldDropdownResponse: [],
      fieldDropdownOpen: false,
      fieldError: false,
      fieldErrorMessage: "",
      fieldDropdownAddNew: true,
      fieldDropdownAllowNull: true,
      allSearchData: [],
    };
  },
  mounted() {
    this.initialize();
  },
  watch: {
    async showCompletedProjects(value) {
      let response;
      if (value) {
        response = await axios.post(`/api/project/search`, {
          client: this.getClient ? this.getClient : false,
        });
      } else {
        response = await axios.post(`/api/project/search`, {
          status: "not_done",
          client: this.getClient ? this.getClient : false,
        });
      }
      this.allSearchData = JSON.parse(JSON.stringify(response));
      this.fieldDropdownItems = response.data;
      this.fieldDropdownResponse = response.data;
    },
    fieldValue(value) {
      this.findProjects(value);
    },
    defocus() {
      this.deactivatePlaceholder();
    },
    error() {
      this.fieldError = this.error;
      if (this.fieldError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        if (this.fieldFocus) {
          this.$refs.label.style.color = "#FF1E24";
        }
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        this.$refs.label.style.color = "";
        if (this.validateInput(this.fieldValue)) {
          this.$refs.label.style.color = "#FF5C01";
        }
      }
    },
    errorMessage() {
      this.fieldErrorMessage = this.errorMessage;
    },
  },
  methods: {
    clickoutside() {
      if (!this.fieldFocus) {
        this.closeDropdown();
      }
    },
    async findProjects(value) {
      if (!value) {
        let response;
        if (this.showCompletedProjects) {
          response = await axios.post(`/api/project/search`, {
            client: this.getClient ? this.getClient : false,
          });
        } else {
          response = await axios.post(`/api/project/search`, {
            status: "not_done",
            client: this.getClient ? this.getClient : false,
          });
        }
        this.allSearchData = JSON.parse(JSON.stringify(response));
        this.fieldDropdownItems = response.data;
        this.fieldDropdownResponse = response.data;
      } else {
        this.fieldDropdownItems = [];
        this.fieldDropdownResponse.forEach((item) => {
          if (item.name.toLowerCase().includes(value.toLowerCase())) {
            this.fieldDropdownItems.push(item);
          }
        });
      }
    },
    initialize() {
      if (this.value) {
        console.log("unit: ", this.value);
        this.fieldValue = this.value.name
          ? this.value.name
          : this.value.apartmentNumber
            ? this.value.apartmentNumber
            : "";
        this.$refs.input.style.height = "24px";
        this.$nextTick(() => this.$refs.label.classList.add("required-mark"));
        this.$nextTick(() =>
          this.$refs.label.classList.add("form-field-large-active-placeholder"),
        );
        //this.activatePlaceholder();
        this.closeDropdown();
      }

      if (this.dropdownOffsetY) {
        this.fieldDropdownStyle.top = `${this.dropdownOffsetY}px`;
      }
      if (this.required) {
        this.fieldRequired = this.required;
      }
      if (this.dropdownAddNew) {
        this.fieldDropdownAddNew = true;
      }
      if (this.error) {
        this.fieldError = this.error;
      }
      if (this.errorMessage) {
        this.fieldErrorMessage = this.errorMessage;
      }
      if (this.showDropdownArrow) {
        this.fieldDropdownArrow = true;
      }
      if (this.dropdownAllowNull === false) {
        this.fieldDropdownAllowNull = false;
      }
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
    },
    focusInputElement() {
      this.$refs.input.focus();
    },
    async unfocusInput() {
      if (!this.validateInput(this.fieldValue)) {
        this.$refs.input.style.height = "0px";
        this.deactivatePlaceholder();
      }
      this.fieldFocus = false;
      this.labelStyle.color = "#75787A";
    },
    openDropdown() {
      if (!this.validateInput(this.fieldValue)) {
        this.$nextTick(() => (this.$refs.input.style.height = "24px"));
        this.activatePlaceholder();
        this.fieldDropdownItems = [];
      }
      this.fieldDropdownOpen = true;
    },
    closeDropdown() {
      this.fieldDropdownOpen = false;
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    focusInputElementPlaceholder() {
      this.activatePlaceholder();
      if (!this.fieldDropdownOpen) {
        this.openDropdown();
        this.findProjects();
      }
      this.fieldFocus = true;
      this.$refs.input.style.transition = "0.2s";
      this.$refs.input.style.height = "24px";
    },
    activatePlaceholder(fieldActive = true) {
      if (!this.$refs.label || !this.labelStyle) return;
      this.$nextTick(() => this.$refs.label.classList.add("required-mark"));
      this.$nextTick(() =>
        this.$refs.label.classList.add("form-field-large-active-placeholder"),
      );
      if (this.fieldError) {
        this.labelStyle.color = "#FF1E24";
        return;
      }
      if (fieldActive) {
        this.labelStyle.color = "#FF5C01";
        return;
      }
      if (!fieldActive) {
        this.labelStyle.color = "#75787A";
      }
    },
    deactivatePlaceholder() {
      if (this.defocus) {
        this.$refs.input.style.height = "0px";
      }
      this.$refs.label.classList.remove("form-field-large-active-placeholder");
      this.$refs.label.classList.remove("required-mark");
      this.labelStyle.color = "#75787A";
    },
    selectDropdownitem(item) {
      if (!item) {
        if (this.value) {
          this.$emit("change", null);
        }
        this.closeDropdown();
        this.deactivatePlaceholder();
        this.$nextTick(() => (this.$refs.input.style.height = "0px"));
        this.fieldValue = "";
        return;
      }
      this.fieldValue = item.name;
      this.labelStyle.color = "#75787A";
      this.closeDropdown();
      if (!this.value || item._id !== this.value._id) {
        this.$emit("change", item);
      }
      this.activatePlaceholder();
      this.$refs.input.style.transition = "0.2s";
      this.$refs.input.style.height = "24px";
    },
    dropdownAddNewButton() {
      this.closeDropdown();
      this.$emit("addNew");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.input-field-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.input-field {
  width: 100%;
  height: 0px;
  outline: none;
  cursor: text;
}

.form-field-large-active-placeholder {
  height: 12px !important;
  line-height: 12px !important;
  font-size: 11px !important;
}

.error-message {
  color: #ff1e24 !important;
}

.dropdown-container {
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 1px 10px 1px rgba(0, 0, 0, 0.08),
    0px 3px 10px -2px rgba(0, 0, 0, 0.03);
  z-index: 110;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  max-height: 300px;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
}

.required label::after {
  position: absolute !important;
  line-height: unset !important;
}

.required-mark::after {
  font-size: 12px !important;
  line-height: unset !important;
  position: absolute !important;
}

.dropdown-item {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}

.dropdown-item:focus {
  outline: 0;
  background-color: #f4f5f7;
}

.dropdown-item:hover {
  background: #f4f5f7;
}

.show-undone-toggle-container {
  padding-bottom: 12px;
}

.remove-button {
  display: flex;
  cursor: pointer;

  img {
    filter: invert(47%) sepia(3%) saturate(10%) hue-rotate(334deg)
      brightness(98%) contrast(91%);
  }
}

.no-results {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}

.add-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  align-items: center;
  background-color: white;
  gap: 8px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $orange;

  &:hover {
    cursor: pointer;
  }
}
</style>
