import * as Msal from "msal";
//let clientIdEu = "3c08cb13-9217-4edc-88b2-127fe92d04f7";
let clientIdEu = "12e9b688-64bd-44aa-96c7-436ac2a65a2b";

//let clientIdCom = "0ca624dd-2e38-4bdb-8eb3-be8c7972ee2a";
let clientIdCom = "12e9b688-64bd-44aa-96c7-436ac2a65a2b";

let redirectUri;
let clientId;

const environment = window.location.host;

//set correct redirect uri
redirectUri = "https://" + environment + "/";
if (environment.includes("localhost")) redirectUri = "http://localhost:3005";

//set correct AD client id
if (
  environment.includes("runproperty.eu") ||
  environment.includes("localhost")
) {
  clientId = clientIdEu;
}

if (environment.includes("runproperty.com")) {
  clientId = clientIdCom;
}

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: redirectUri,
  },
  useRefreshTokens: true,
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case Msal.LogLevel.Error:
            console.error(message);
            return;
          case Msal.LogLevel.Info:
            console.info(message);
            return;
          case Msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case Msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

const ms_scopes = [
  "User.Read",
  "Calendars.ReadWrite",
  "offline_access",
  "openid",
  "profile",
];
export const loginRequest = {
  scopes: ms_scopes,
};

export const tokenRequest = {
  scopes: ms_scopes,
  forceRefresh: false, // use token from cache
};
