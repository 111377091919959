export default [
  {
    _id: "1",
    name: "allValues",
    filterTitle: "MyContactsOnly",
    isDefault: true,
    isSelected: true,
    conditions: [
      {
        name: "tag",
        title: "Tag",
        value: "show_all",
        valueTitle: "All",
        items: {
          show_all: {
            name: "ShowAll",
            abbreviation: "",
            propertyValue: "show_all",
            isClicked: false,
          },
          property_owner: {
            name: "PropertyOwner",
            propertyValue: "property_owner",
            abbreviation: "PO",
            isClicked: false,
          },
          landlord: {
            name: "CustomerField.CustomerType.Options.LANDLORD",
            abbreviation: "L",
            propertyValue: "landlord",
            isClicked: false,
          },
          buyer: {
            name: "CustomerField.CustomerType.Options.BUYER",
            abbreviation: "B",
            propertyValue: "buyer",
            isClicked: false,
          },
          tenant: {
            name: "CustomerField.CustomerType.Options.TENANT",
            abbreviation: "T",
            propertyValue: "tenant",
            isClicked: false,
          },
          other: {
            name: "CustomerField.CustomerType.Options.OTHER",
            abbreviation: "O",
            propertyValue: "other",
            isClicked: false,
          },
          photographer: {
            name: "Photographer",
            abbreviation: "PH",
            propertyValue: "photographer",
            isClicked: false,
          },
          valuator: {
            name: "Valuator",
            abbreviation: "VA",
            propertyValue: "valuator",
            isClicked: false,
          },
          notary: {
            name: "Notary",
            abbreviation: "NO",
            propertyValue: "notary",
            isClicked: false,
          },
          bank_representative: {
            name: "BankRepresentative",
            abbreviation: "BA",
            propertyValue: "bank_representative",
            isClicked: false,
          },
        },
      },
      {
        name: "search",
        title: "Search",
        value: "",
        valueTitle: "",
      },
      {
        name: "transaction",
        title: "Transaction",
        value: [],
        valueTitle: "All",
        items: [
          {
            name: "AllTransactionTypes",
            value: ["sale", "rent", "short_term_rent"],
          },
          { name: "Purchase", value: "sale" },
          { name: "Rent", value: "rent" },
          {
            name: "ShortTermRent",
            value: "short_term_rent",
          },
        ],
      },
      {
        name: "birthday",
        title: "Birthday",
        value: "All",
        valueTitle: "All",
        items: [],
      },
      {
        name: "estate",
        title: "Estate",
        value: [],
        valueTitle: "All",
        items: [
          {
            name: "AllEstateTypes",
            value: [
              "commercial",
              "apartment",
              "house",
              "house_part",
              "land",
              "cottage",
              "garage",
            ],
          },
          {
            name: "CommercialSpace",
            value: "commercial",
          },
          { name: "Apartment", value: "apartment" },
          { name: "House", value: "house" },
          { name: "HousePart", value: "house_part" },
          { name: "LandPlot", value: "land" },
          { name: "SummerHouse", value: "cottage" },
          { name: "Garage", value: "garage" },
        ],
      },
      {
        name: "ownership",
        title: "Ownership",
        value: [],
        valueTitle: "All",
        items: [
          {
            name: "AllOwnershipTypes",
            value: ["PRIVATE+", "PRIVATE", "GROUP", "COMPANY"],
          },
          { name: "MyContacts", value: "PRIVATE" },
          { name: "MyContacts+", value: "PRIVATE+" },
          { name: "DepartmentContacts", value: "GROUP" },
          { name: "CompanyContacts", value: "COMPANY" },
        ],
      },
      {
        name: "brokerId",
        title: "Broker",
        value: "",
        valueTitle: "All",
        items: [],
      },
      {
        name: "createdAt",
        title: "CreatedAt",
        value: false,
        valueTitle: "",
      },
      {
        name: "createdFrom",
        title: "createdFrom",
        value: false,
        valueTitle: "",
      },
      {
        name: "createdTo",
        title: "createdTo",
        value: false,
        valueTitle: "",
      },
    ],
  },
];
