<template>
  <div
    class="invoice-status-container content-medium"
    :class="invoiceLabelClass"
  >
    {{ statusName }}
  </div>
</template>

<script>
export default {
  name: "InvoiceStatusContainer",
  components: {},
  props: ["status"],
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
    invoiceLabelClass() {
      switch (this.status) {
        case "paid":
          return "invoice-label-paid";
        case "make_invoice":
          return "invoice-label-make-invoice";
        case "partially_paid":
          return "invoice-label-partially-paid";
        case "not_paid":
          return "invoice-label-not-paid";
        default:
          return "invoice-status-missing";
      }
    },
    statusName() {
      switch (this.status) {
        case "paid":
          return this.$t("Paid");
        case "make_invoice":
          return this.$t("MakeInvoice");
        case "partially_paid":
          return this.$t("PartiallyPaid");
        case "not_paid":
          return this.$t("NotPaid");
        default:
          return "-";
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.invoice-label-not-paid {
  background: $red300;
}

.invoice-label-partially-paid {
  background: $yellow300;
}

.invoice-label-make-invoice {
  background: $grey500;
}

.invoice-label-paid {
  background: $green300;
}

.invoice-status-container {
  padding: 0 4px;
  border-radius: 4px;
  color: black;
}
</style>
