import { computed } from "vue";
import { useStore } from "vuex";

const accessColleaguesOptions = {
  sort: {
    field: "name", // field name
    order: 1, // 1, -1, 0
  },
};

const moduleAccessCommands = {
  default: (document, user, users) => {
    if (document.access?.users?.[user._id]) {
      return true;
    }

    if (user.access.accessLevel === "group_admin" && user.access.groups) {
      if (!Array.isArray(document.access?.usersArray)) return false;

      const owner = document.access.usersArray.find(
        (docUser) => docUser.ownershipType === "owner",
      );

      if (!owner) return false;

      const ownerUser = users.find((user) => user._id === owner._id);

      if (!ownerUser) return false;
      for (const groupId in user.access.groups) {
        const group = user.access.groups[groupId];

        if (group.isAdmin) {
          if (ownerUser.access.groups[groupId]) {
            return true;
          }
        }
      }
    }

    return false;
  },
  projects: {
    delete: (document, user) => {
      if (user.access.accessLevel === "group_admin" && user.access.users) {
        for (const userId in user.access.users) {
          const userInDocument = document.access.users[userId];
          if (userInDocument && userInDocument.ownershipType === "owner") {
            return true;
          }
        }
      }
      return false;
    },
  },
  listings: {
    delete: (document, user) => {
      const userInDocument = document.access.users[user._id];
      if (userInDocument && userInDocument.ownershipType === "owner") {
        return true;
      }
      if (user.access.accessLevel === "group_admin" && user.access.users) {
        for (const userId in user.access.users) {
          const userInDocument = document.access.users[userId];
          if (userInDocument && userInDocument.ownershipType === "owner") {
            return true;
          }
        }
      }
      return false;
    },
  },
  developments: {
    delete: (document, user) => {
      const userInDocument = document.access.users[user._id];
      return !!(
        user.access.accessLevel === "group_admin" &&
        userInDocument &&
        userInDocument.ownershipType === "owner"
      );
    },
  },
  invoices: {
    delete: (document, user) => {
      if (user.access.accessLevel === "group_admin" && user.access.users) {
        for (const userId in user.access.users) {
          const userInDocument = document.access.users[userId];
          if (userInDocument && userInDocument.ownershipType === "owner") {
            return true;
          }
        }
      }
    },
    edit: (document, user) => {
      const userInDocument = document.access.users[user._id];
      if (userInDocument && userInDocument.ownershipType === "owner") {
        return true;
      } else if (
        user.access.accessLevel === "group_admin" &&
        user.access.users
      ) {
        for (const userId in user.access.users) {
          const userInDocument = document.access.users[userId];
          if (userInDocument && userInDocument.ownershipType === "owner") {
            return true;
          }
        }
      }
      return false;
    },
  },
};

export function useAccess() {
  const store = useStore();
  const user = computed(() => store.getters["user"]);
  const users = computed(() => store.getters["users"]);

  const hasAccess = (document, module = null, command = null) => {
    if (user.value.access.accessLevel === "company_admin") return true;
    if (module && command) {
      return moduleAccessCommands[module][command](
        document,
        user.value,
        users.value,
      );
    } else {
      return moduleAccessCommands["default"](document, user.value, users.value);
    }
  };

  const accessColleagues = (options = accessColleaguesOptions) => {
    let returnArray = [];
    switch (user.value.access.accessLevel) {
      case "company_admin":
        returnArray = users.value;
        break;
      case "group_admin":
        returnArray = users.value.filter((user) => user.access.users[user._id]);
    }
    return returnArray.sort((a, b) => {
      return (
        options.sort.order *
        a[options.sort.field].localeCompare(b[options.sort.field])
      );
    });
  };

  const groupColleagues = () => {
    const returnUsers = [];
    for (const groupId in user.value.access.groups) {
      for (const colleague of users.value) {
        if (colleague.access.groups[groupId]) {
          returnUsers.push(colleague);
        }
      }
    }
    return returnUsers;
  };

  return {
    user,
    users,
    hasAccess,
    accessColleagues,
    groupColleagues,
  };
}
