<template>
  <div class="delete-overlay">
    <div class="main-wrapper">
      <p v-if="!removing && !customMessage" class="content">
        {{ $t("ConfirmDelete") }}
      </p>
      <p v-if="removing && !customMessage" class="content">
        {{ $t("ConfirmRemove") }}
      </p>
      <p v-if="customMessage" class="content">{{ customMessage }}</p>
      <div class="buttons-row">
        <button id="cancel-button" @click="canceled" class="cancel">
          {{ $t("Cancel") }}
        </button>
        <button
          v-if="!removing"
          @click="approved"
          id="delete-button"
          class="delete-button"
        >
          <div
            style="position: relative; width: 30px; height: 30px"
            v-if="isLoading"
            class="loader"
          ></div>
          <p v-if="!isLoading" style="color: white">{{ $t("Delete") }}</p>
        </button>
        <button
          v-if="removing"
          @click="approved"
          class="delete-button"
          style="color: white"
        >
          {{ $t("Remove") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DeleteModal",
  props: [
    "files",
    "targetId",
    "uploaderName",
    "targetObject",
    "removing",
    "customMessage",
  ],
  data: () => ({
    refactoredFiles: [],
    isUploading: false,
    hovered: false,
    isLoading: false,
    srcNormal: require("../../assets/images/fileIcon.svg"),
    srcHover: require("../../assets/images/trash-red.svg"),
  }),
  created() {},
  watch: {},
  computed: {},
  methods: {
    canceled() {
      this.$emit("canceled");
    },
    approved() {
      this.$emit("approved");
      this.isLoading = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-button {
  background-color: #ff1e24;
  padding: 12px 24px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  align-items: center;
  display: flex;
  color: white;
}
.delete-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 13;
}
.main-wrapper {
  z-index: 10;
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 8px;
}
.buttons-row {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 40px;
  gap: 8px;
}
.content {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* black */
  color: rgba(0, 0, 0, 1);
}
</style>
