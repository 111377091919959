<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="copy-button" @click.stop="copy">
        <img :src="require('@/assets/images/copy.svg')" alt="" />
      </div>
    </template>
    <span>{{ $t("Copy") }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "CopyButton",
  props: ["copyText", "toastMessage"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    copy() {
      navigator.clipboard.writeText(this.copyText);
      if (this.toastMessage) {
        this.$toast.success(this.$t(`${this.toastMessage}`));
      } else {
        this.$toast.success(this.$t("LinkCopied"));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: #f4f5f7;
  border-radius: 1000px;
  cursor: pointer;
  img {
    max-height: 16px;
    height: 16px;
    max-width: 16px;
    width: 16px;
  }
  &:hover {
    background: #ff5c01;
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(290deg)
        brightness(103%) contrast(101%);
    }
  }
}
</style>
