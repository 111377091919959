<template>
  <div
    v-if="show"
    @click="$emit('onPress')"
    class="project-simple-info-card"
    :class="cardBorderColor"
  >
    <div class="project-first-row">
      <DealTypeContainer :small-font="false" :deal-type="project.dealType" />
      <PipelineInfoContainer
        :projectPipelineStepTimeLog="project.pipelineStepTimeLog"
      />
    </div>
    <div class="link">
      {{ project.name }}
    </div>
  </div>
</template>

<script>
import DealTypeContainer from "@/components/common/DealTypeContainer";
import PipelineInfoContainer from "@/components/Deals/PipelineInfoContainer";
export default {
  name: "SimpleInfoCard",
  components: { PipelineInfoContainer, DealTypeContainer },
  props: ["project", "hideArchived"],
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
    show() {
      return !(this.hideArchived && this.isArchived());
    },
    cardBorderColor() {
      if (this.isArchived()) {
        return this.dealWon ? "project-win-border" : "project-lost-border";
      }
      return null;
    },
  },
  methods: {
    isArchived() {
      return (
        this.project.pipelineStep === "Done" ||
        this.project.pipelineStep === "archived"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.project-first-row {
  display: flex;
  gap: 8px;
}

.project-simple-info-card {
  display: grid;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $grey500;
  cursor: pointer;
}

.project-win-border {
  border: 2px solid $green300;
}

.project-lost-border {
  border: 2px solid $red300;
}
</style>
