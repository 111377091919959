export default [
  {
    _id: "7",
    name: "allValues",
    filterTitle: "MyDashboard",
    isDefault: true,
    isSelected: true,
    conditions: [
      {
        name: "widgets",
        title: "Widgets",
        value: [],
        valueTitle: "",
      },
    ],
  },
];
