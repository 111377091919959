import { reactive, createVNode, render } from "vue";
import ToastComponent from "@/components/TheToast.vue";

const ToastPlugin = {
  install(app) {
    const toastData = reactive({
      type: "",
      message: "",
      visible: false,
      className: "show",
    });

    const show = (message, type = "info") => {
      toastData.type = type;
      toastData.message = message;
      toastData.visible = true;
      toastData.className = "show";
      updateToast();

      setTimeout(() => {
        toastData.className = "hide";
        updateToast();

        setTimeout(() => {
          toastData.visible = false;
          updateToast();
        }, 300);
      }, 5000);
    };

    const toastSuccess = (message) => {
      show(message, "success");
    };

    const toastError = (message) => {
      show(message, "error");
    };

    const updateToast = () => {
      const vNode = createVNode(ToastComponent, toastData);
      vNode.appContext = app._context;
      render(vNode, container);
    };

    app.config.globalProperties.$toast = {
      show,
      success: toastSuccess,
      error: toastError,
    };

    const container = document.createElement("div");
    document.body.appendChild(container);
    updateToast();
  },
};

export default ToastPlugin;
