<template>
  <div id="widget-overlay" class="widget-overlay">
    <div id="widget" v-click-outside="closePanel">
      <div
        class="flex-row"
        style="cursor: pointer"
        @click="showTypes = !showTypes"
        v-click-outside="
          () => {
            showTypes = false;
          }
        "
      >
        <div class="field-icon-con">
          <div
            class="type-con"
            :style="
              'background: ' +
              eventTypes.find((e) => e.value === task.type).color
            "
          />
          <div
            :style="
              'z-index: 1;' + (showTypes ? 'display: flex;' : 'display: none;')
            "
            class="dropdown-single scrollable"
          >
            <div
              v-for="type in taskEventTypes"
              :key="type.value"
              class="dropdown-item-wrapper"
              @click="
                task.type = type.value;
                showTypes = true;
                updateTask();
              "
            >
              <p :style="task.type === type.value ? 'font-weight: bold' : null">
                {{ type.name }}
              </p>
            </div>
          </div>
        </div>

        <v-col>
          <div class="entry-field-label flex-row">
            {{ $t("Type") }}
          </div>
          {{ eventTypes.find((e) => e.value === task.type).name }}
        </v-col>
      </div>
      <div class="flex-row" style="display: flex; align-items: center">
        <div class="field-icon-con">
          <date-pick
            @input="
              (date) => {
                setDate(date);
                updateTask();
              }
            "
          >
            <template v-slot:default="{ toggle }">
              <img
                @click="toggle"
                :src="require('@/assets/images/calendar.svg')"
                class="field-icon"
              />
            </template>
          </date-pick>
        </div>

        <span class="flex justify-space-between">
          <v-col>
            <date-pick
              @input="
                (date) => {
                  setDate(date);
                  updateTask();
                }
              "
            >
              <template v-slot:default="{ toggle }">
                <div
                  @click="toggle"
                  class="entry-field-label"
                  style="cursor: pointer"
                >
                  {{ $t("Field.dueDate") }}
                </div>
                <div @click="toggle" style="cursor: pointer">
                  <span style="font-size: 12px" v-if="task.dates.endDate">
                    {{ formatDate(task.dates.endDate) }}
                  </span>
                </div>
              </template>
            </date-pick>
          </v-col>
          <span
            style="width: 16px; height: 16px; cursor: pointer"
            v-if="task.category === 'task' && task.dates.endDate !== null"
          >
            <img
              @click="
                task.dates.endDate = null;
                task.dates.startDate = null;
                updateTask();
              "
              class="icon-img"
              v-bind:src="require(`@/assets/images/close.svg`)"
              style="
                background-color: #e6e8ec;
                border-radius: 4px;
                margin-right: 4px;
              "
              alt=""
            />
          </span>
        </span>
      </div>

      <TaskRowWithDropdown
        :items="projects"
        :display-key="'name'"
        :value-key="'id'"
        :label="'Project'"
        :icon="'projects.svg'"
        :allow-delete="true"
        :selectedItem="task.linkedProject"
        :fieldRequired="this.task.type === 'open_house'"
        :updater="projectUpdater"
        @itemSelected="updateProject"
      />

      <TaskRowWithDropdown
        :items="contacts"
        :display-key="'name'"
        :value-key="'id'"
        :label="'Contact'"
        :icon="'clients.svg'"
        :allow-delete="true"
        :selectedItem="task.linkedContact"
        :updater="contactUpdater"
        @itemSelected="contactProject"
      />

      <TaskRowWithDropdown
        :items="colleagues"
        :display-key="'name'"
        :value-key="'userId'"
        :label="'Owner'"
        :icon="'users.svg'"
        :selectedItem="task.assignedTo"
        @itemSelected="
          (item) => {
            task.assignedTo = item;
            updateTask();
          }
        "
      />

      <div
        class="flex-row"
        @click="isDeleteModalOpen = true"
        style="
          cursor: pointer;
          display: flex;
          align-items: center;
          padding-left: 2px;
          margin-top: 16px;
        "
      >
        <div class="field-icon-con" style="display: flex; align-items: center">
          <img
            style="width: 16px; height: 16px"
            :src="require('@/assets/images/TrashBin.svg')"
          />
        </div>

        <v-col>
          <div style="color: #ff1e24">
            {{ $t("Delete") }}
          </div>
        </v-col>
      </div>
    </div>
    <DeleteModal
      @canceled="isDeleteModalOpen = false"
      @approved="deleteTaskOrEvent"
      v-if="isDeleteModalOpen"
    />
  </div>
</template>

<script>
import { nanoid } from "nanoid";
// import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import DeleteModal from "@/components/common/DeleteModal";
import TaskRowWithDropdown from "@/components/Tasks/TaskRowWithDropdown";

import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "CalendarWidget",
  components: {
    // DatePick,
    DeleteModal,
    TaskRowWithDropdown,
  },
  props: ["position", "clickedTask"],
  data() {
    return {
      projectUpdater: 0,
      contactUpdater: 0,
      showTypes: false,
      isFullDay: false,
      isDeleteModalOpen: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      eventTypes: [
        { name: this.$t("Generic"), value: "generic", color: "#939597" },
        { name: this.$t("Meeting"), value: "meeting", color: "#f81ca0" },
        {
          name: this.$t("NotaryMeeting"),
          value: "notary_meeting",
          color: "#4c8dd9",
        },
        {
          name: this.$t("ClientVisit"),
          value: "client_visit",
          color: "#ff5c01",
        },
        {
          name: this.$t("OpenHouse"),
          value: "open_house",
          color: "#7200E2",
        },
      ],
      projects: [],
      contacts: [],
      colleagues: [],
      task: {
        _id: nanoid(20),
        createdAt: Date.now(),
        content: "",
        assignedTo: {
          userId: null,
          name: null,
        },
        isCompleted: false,
        type: "generic",
        category: "task",
        dates: {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        linkedProject: {
          id: null,
          name: null,
        },
        linkedContact: {
          id: null,
          name: null,
        },
        isPrivate: false,
      },
    };
  },
  async created() {
    await this.setup();
  },
  mounted() {
    let top = this.task.top;
    let left = this.task.left;
    let realWidth = document.getElementById("widget-overlay").clientWidth;
    let realHeight = document.getElementById("widget-overlay").clientHeight;
    if (realWidth - left < 300) {
      left = left - (realWidth - left);
      left = left - (340 - (realWidth - left));
    }
    if (realHeight - top < 300) {
      top = top - (realHeight - top);
      top = top - (340 - (realHeight - top));
    }
    top = top - 20;

    document.getElementById("widget").style.setProperty("left", left + "px");
    document.getElementById("widget").style.setProperty("position", "absolute");

    document.getElementById("widget").style.setProperty("top", top + "px");
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeWidget);
    window.removeEventListener("resize", this.onResize);
  },
  watch: {},
  computed: {
    taskEventTypes() {
      const taskEventTypes = this.eventTypes.filter(function (event) {
        return event.value !== "open_house";
      });
      return taskEventTypes;
    },
    ...mapGetters(["user", "users"]),
  },
  methods: {
    async closePanel() {
      this.$emit("closePanel");
      //this.$emit("updateKey");
    },
    async updateTask() {
      await axios.patch(`/api/task/${this.task._id}`, this.task);
      this.$emit("updateKey");
      this.$emit("updateTask", this.task);
    },

    formatDate(date) {
      return moment(date).format("dddd, DD.MM");
    },
    deleteTaskOrEvent() {
      this.$emit("deleteTask", this.task._id);
    },
    async setup() {
      this.task = this.clickedTask;

      Promise.all([
        await this.setupProjects(),
        await this.setupContacts(),
        this.setupColleagues(),
      ]);
    },
    updateProject(item) {
      this.task.linkedProject = item;
      this.updateTask();
      this.projectUpdater++;
    },
    contactProject(item) {
      this.task.linkedContact = item;
      this.updateTask();
      this.contactUpdater++;
    },
    mapTask(task) {
      if (task.dates.startDate) {
        task.dates.startDate = moment(task.dates.startDate).format();
      }
      if (task.dates.endDate) {
        task.dates.endDate = moment(task.dates.endDate).format();
      }
    },
    setDate(date) {
      this.task.dates.endDate = date;
      this.task.dates.startDate = date;
    },
    setupColleagues() {
      let myself = {
        userId: this.user._id,
        name: this.user.name,
      };

      this.colleagues.push(myself);
      this.users.forEach((user) => {
        if (user._id === this.user._id) {
          return;
        }
        let formattedUser = {
          userId: user._id,
          name: user.name,
        };
        this.colleagues.push(formattedUser);
      });
      this.colleagues.sort((a, b) => a.name.localeCompare(b.name));
      if (!this.clickedTask) {
        this.task.assignedTo.userId = this.user._id;
        this.task.assignedTo.name = this.user.name;
      }
      if (this.clickedTask) {
        this.task.assignedTo.userId = this.clickedTask.assignedTo.userId;
        this.task.assignedTo.name = this.clickedTask.assignedTo.name;
      }
    },
    async setupContacts() {
      const contactsResponse = await axios.get("/api/contacts/names");
      const contacts = contactsResponse.data;
      contacts.customers.forEach((contact) => {
        let formattedContact = {
          id: contact.customerId,
          name: contact.customerName,
        };
        this.contacts.push(formattedContact);
      });
      this.contacts.sort((a, b) => a.name.localeCompare(b.name));
    },

    async setupProjects() {
      const projectResponse = await axios.get("/api/projects/names");
      const projects = projectResponse.data;
      projects.deals.forEach((project) => {
        let formattedProject = {
          id: project._id,
          name: project.name,
          createdAt: project.createdAt,
        };
        this.projects.push(formattedProject);
      });
      this.projects.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    changeTaskType(type) {
      if (type === "task") {
        this.task.dates.startDate = null;
        this.task.dates.startTime = null;
      }
      this.task.category = type;
    },
    /*  moveWidgetX(newWidth, oldWidth) {
      const difference = newWidth - oldWidth;
      const widget = document.getElementById("widget");
      let oldPosition = parseInt(widget.style.left, 10);
      const newPosition = oldPosition + difference;

      widget.style.setProperty("left", newPosition + "px");
    }, */
    /* moveWidgetY(newHeight, oldHeight) {

    },*/
    /* setWidgetPosition() {
      let yPosition = this.position.top;
      let xPosition = this.position.right;
      let widget = document.getElementById("widget");
      let widgetWidth = widget.offsetWidth;

      widget.style.setProperty("top", yPosition + "px");
      widget.style.setProperty("left", xPosition + 50 + "px");

      if (this.windowWidth - xPosition < widgetWidth) {
        widget.style.setProperty("left", xPosition - widgetWidth - 50 + "px");
      }
    }, */

    closeWidget() {
      this.$emit("close");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.cancel {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: normal;
}

.entry-title {
  font-size: 18px;
  outline: none;
  border: none;
  overflow: hidden;
  height: 36px;
  line-height: 18px;
}

.entry-field-value {
  margin-top: 0 !important;
  height: 16px;
  display: contents;
}

.dropdown-item-wrapper {
  cursor: pointer;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  &:hover {
    background-color: #f4f5f7;
  }
}

.dropdown-single {
  position: absolute;
  flex-direction: column;
  background-color: white;
  overflow: scroll;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 4;
  p {
    color: black;
    text-decoration: none;
    display: block;
  }
}

.type-con {
  background: #939597;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.field-icon-con {
  width: 32px;
  height: 20px;
}

.field-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.entry-field-label {
  color: $grey950 !important;
  font-size: 12px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 0px !important;
  padding-bottom: 0px !important;
  &:focus {
    outline: none !important;
  }
}

.col {
  padding: 0;
}

.widget-overlay {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#widget {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: fixed;
  padding: 12px;
  width: 200px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}
.title-underline {
  height: 2px;
  width: 100%;
  background: #000;
}
.widget-title {
  outline: none;
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #75787a;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 16px;
}
.type-selector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //padding: 2px;
  width: 172px;
  height: 32px;
  margin-top: 24px;
  background: #fafafa;
  //border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 1000px;
}
.selected-type {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  background: #ff5c01;
  border-radius: 1000px;
  flex-grow: 1;
  p {
    height: 16px;

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 10px;
  }
}
.not-selected-type {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  border-radius: 1000px;
  flex-grow: 1;
  p {
    height: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 10px;
  }
}
.dates-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 16px;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
.add-start-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 176px;
  height: 32px;
  background: #fafafa;
  border: 2px dashed #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin-top: 8px;
  cursor: pointer;
}
.end-date-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 8px;
}
.date-button {
  cursor: pointer;
  /* button */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  height: 32px;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  min-width: 84px;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #75787a;
    flex: none;
    margin: 0 8px;
  }
  #end-time-dropdown {
  }
}
input[type="time"] {
  padding-left: 8px;
  color: #75787a;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  //background: none;
  //display: none;
}
.all-day-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
}
.dropdown-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #75787a;
  flex: none;
  margin: 0 8px;
}
.event-type-wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 70%;
  p {
    height: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
  }
}
.buttons-con {
  gap: 16px;
}
.add-button {
  cursor: pointer !important;
  padding-left: 12px;
  padding-right: 12px;
  max-width: fit-content;
  p {
    color: white;
  }
}
</style>
