const nMapper = {
  price(price, currency) {
    if (!price && currency) return "0" + " " + currency;
    if (!price && !currency) return "0";

    let parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let formattedPrice = parts.join(".");

    if (currency) {
      return formattedPrice + " " + currency;
    }

    return formattedPrice;
  },
};

export default {
  install(app) {
    app.config.globalProperties.$nMapper = nMapper;
    app.provide("nMapper", nMapper);
  },
};
