<template>
  <div
    v-if="user && isCollapsed !== undefined"
    class="navbar"
    :class="{ collapsed: isCollapsed }"
  >
    <div class="upper-side">
      <div class="ok-wrap" style="width: 48px !important">
        <img
          style="border-radius: 30px; cursor: pointer"
          :src="
            isCollapsed
              ? require('@/assets/images/logo_white.svg')
              : require('@/assets/images/logo_white_big.svg')
          "
          @click="projectPage()"
        />
      </div>

      <v-list class="links-wrap" nav dense>
        <!-- Dashboard -->
        <v-list-item
          v-if="showDashboard"
          v-tooltip="isCollapsed ? $t('Dashboard') : null"
          link
          :to="{ name: 'DashBoard' }"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('DashBoard', 'dashboard.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Dashboard") }}
            </div>
          </div>
        </v-list-item>

        <div
          class="flex-row separator"
          v-if="showDashboard"
          style="margin-top: 4px; margin-bottom: 8px"
        ></div>

        <!-- Projects -->
        <v-list-item
          v-if="showProjects"
          v-tooltip="isCollapsed ? $t('Projects') : null"
          link
          :to="{ name: 'TheDeals' }"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('TheDeals', 'ProjectIcon.svg')"
              alt=""
              class="menu-icon"
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Projects") }}
            </div>
          </div>
        </v-list-item>

        <!-- Listings -->
        <v-list-item
          v-if="showListings"
          v-tooltip="isCollapsed ? $t('Listings') : null"
          link
          :to="{ name: 'TheListings' }"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('TheListings', 'building.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Listings") }}
            </div>
          </div>
        </v-list-item>

        <!-- Property Developer -->
        <v-list-item
          v-if="showPropertyDeveloper"
          v-tooltip="isCollapsed ? $t('DevelopmentProjects') : null"
          name="navbar-property-developer-button"
          link
          :to="{ name: 'PropertyDevelopment' }"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('PropertyDevelopment', 'PropertyDeveloper.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("DevelopmentProjects") }}
            </div>
          </div>
        </v-list-item>

        <!-- Property Owner -->
        <v-list-item
          v-if="showPropertyOwner"
          v-tooltip="isCollapsed ? $t('Properties') : null"
          name="navbar-propertyowner-button"
          link
          :to="{ name: 'PropertyOwner' }"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('PropertyOwner', 'PropertyOwner.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Properties") }}
            </div>
          </div>
        </v-list-item>

        <div
          class="flex-row separator"
          v-if="
            !user.config ||
            (user.config && user.access?.features?.modules?.calendar?.enabled)
          "
          style="margin-bottom: 8px; margin-top: 4px"
        ></div>

        <!-- Calendar -->
        <v-list-item
          v-if="showCalendar"
          v-tooltip="isCollapsed ? $t('Calendar') : null"
          link
          :to="{ name: 'Calendar' }"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('Calendar', 'menu/calendar.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Calendar") }}
            </div>
          </div>
        </v-list-item>

        <!-- Contacts -->
        <v-list-item
          v-if="showContacts"
          v-tooltip="isCollapsed ? $t('Contacts') : null"
          name="navbar-contacts-button"
          link
          to="/contacts?page=1"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('Kliendiregister', 'menu/client.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Contacts") }}
            </div>
          </div>
        </v-list-item>

        <div
          class="flex-row separator"
          v-if="showTransactions"
          style="margin-bottom: 8px; margin-top: 4px"
        ></div>

        <!-- Transactions -->
        <v-list-item
          v-if="showTransactions"
          v-tooltip="isCollapsed ? $t('Transactions') : null"
          link
          to="/transactions?page=1"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('TheTransactions', 'transactions.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Transactions") }}
            </div>
          </div>
        </v-list-item>

        <!-- Invoices -->
        <v-list-item
          v-if="showInvoices"
          v-tooltip="isCollapsed ? $t('Invoices') : null"
          link
          to="/invoices/?page=1"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('TheInvoices', 'invoice.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Invoices") }}
            </div>
          </div>
        </v-list-item>

        <div
          class="flex-row separator"
          v-if="
            showSettings &&
            (!user.config ||
              (user.config &&
                user.access?.features?.modules?.invoices?.enabled))
          "
          style="margin-top: 4px; margin-bottom: 8px"
        ></div>

        <!-- Admin -->
        <v-list-item
          v-if="showSettings"
          v-tooltip="isCollapsed ? $t('Admin') : null"
          name="navbar-admins-button"
          link
          :to="{ name: 'Admin' }"
          tabindex="0"
        >
          <div class="navbar-btn-wrapper">
            <img
              :src="getIcon('Admin', 'menu/admin.svg')"
              class="menu-icon"
              alt=""
            />
            <div
              class="navbar-text"
              :class="[isCollapsed ? '' : 'expanded-text']"
              v-if="!isCollapsed"
            >
              {{ $t("Admin") }}
            </div>
          </div>
        </v-list-item>
      </v-list>
    </div>
    <div
      @click="toggleNavbar"
      class="accordion-btn"
      :class="[isCollapsed ? '' : 'accordion-btn-expanded']"
    >
      <!-- <img :src="require('@/assets/images/chevron-left_gray.svg')" /> -->
      <div class="navbar-btn-wrapper">
        <img
          :src="require('@/assets/images/chevron-right_gray.svg')"
          :class="[isCollapsed ? '' : 'rotate-180']"
          class="menu-icon"
        />
        <div
          class="navbar-text"
          :class="[isCollapsed ? '' : 'expanded-text']"
          v-if="!isCollapsed"
        >
          {{ $t("Minimize") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "NavBar",

  data: () => ({
    isCollapsed: undefined,
  }),
  async mounted() {
    await this.getUserNavbarState();
  },
  watch: {
    isCollapsed: {
      handler(newVal) {
        this.emitExpandedEvent(newVal);
      },
    },
    user: {
      async handler() {
        await this.getUserNavbarState();
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
    active() {
      return true;
    },
    showDashboard() {
      let disabledCompanies = ["_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.dashboard?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showProjects() {
      let disabledCompanies = ["7QxJBWjXZYrUUBq5OuZo", "_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.projects?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showTransactions() {
      let disabledCompanies = ["7QxJBWjXZYrUUBq5OuZo", "_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.transactions?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showInvoices() {
      let disabledCompanies = ["7QxJBWjXZYrUUBq5OuZo", "_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.invoices?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showCalendar() {
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.calendar?.enabled
      ) {
        return false;
      }
      return true;
    },
    showListings() {
      let disabledCompanies = ["_dw_1O8jj0QTYIRahjbF"];
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showContacts() {
      return true;
    },
    showSettings() {
      return (
        this.user.access.accessLevel === "company_admin" ||
        this.user.access.accessLevel === "group_admin"
      );
    },
    showScorecard() {
      let enabledCompanies = [
        "UNhloeIRQy9J19caCGXU",
        "wcgQphbhIZdITwYe2VIZ",
        "5p1EOo4yqjeiYCN3R16w",
        "yAuL6x3yKIacfejp0MYl",
      ];
      if (enabledCompanies.includes(this.user.companyId)) {
        return true;
      }
      return false;
    },
    showPropertyOwner() {
      let enabledCompanies = [
        "sTtxCiAQFhBSUWlb53__",
        "pMlBRbc8fIHcfE4P2hIz",
        "7QxJBWjXZYrUUBq5OuZo",
        "_dw_1O8jj0QTYIRahjbF",
        "vU_FgUHWcfNiEjBGy6bz",
      ];
      if (
        (this.user.isAdmin &&
          !this.user.config &&
          this.user.companyId === "yAuL6x3yKIacfejp0MYl") ||
        enabledCompanies.includes(this.user.companyId)
      ) {
        return true;
      }
      return false;
    },
    showPropertyDeveloper() {
      let enabledCompanies = [
        "sTtxCiAQFhBSUWlb53__",
        "pMlBRbc8fIHcfE4P2hIz",
        "vU_FgUHWcfNiEjBGy6bz",
        "Nh-UVddKpP82a8gglcus",
        "d4awXn68uHcnmKO8cJax",
        "5p1EOo4yqjeiYCN3R16w",
        "0Kum6B4gJpw8WmlHTbvB",
        "XgueSGJcSiOjBSk_Sqnz",
        "JzuCm7o_scgsbbbqPfYu",
        "2STeziIYJsENkW5As9LQ",
        "SPSJGI2EHB4qiWDgbH9F",
        "3UKuGiqNzcwEH8ZvfPgG",
        "zYdkwF7AXv8eicJdDsQx",
        "MNZQVHzhsWfzHKXvshL0",
        "oSwq8a1X1r9k4zQVDH-E",
        "YvQ5kL6oWP3P1F8tglur",
        "wNeu-3EudSwfkOtBHOZu",
        "bm7DM4QdrnLppKsMGgmY",
        "H9k9xcvU04W7Ymsh5SDD",
        "9l-DjKBHeWQxSvNGG7MV",
        "1HviP3w7wquaiCxGDZ1_",
        "xmwei_Rt9ryfpkxL4BL8",
        "uQutr5MJ30fThJ1HCdopq",
      ];
      let enabledUsers = [
        "63fcdd8c42639d100ee7c492",
        "62b047ed1324246fe1c6fddd",
        "61fbf086a8519ada0a01cae4",
        "632b415d1ec9deb58a1980c0",
        "61fc0607a8519ada0a01d0c6",
        "64245690c7091ddabc71501b",
        "6418862598eab2726c7d8661",
        "6269299bba02d13fcc1276fd",
        "63fe4ef426a0ce8471269387",
        "633ed455991bd949509fd13f",
        "643fe6bb1c813d21e34bca6e",
        "6418862598eab2726c7d8661",
        "6432f847e76e41b7b5ea206a",
        "64297f907f58556aaf78c862",
        "64297f6a7f58556aaf78c751",
        "643c10d25e6d1388fa98c324",
        "6432f840e76e41b7b5ea204a",
        "642c2a97f003b870bb6ffff7",
        "643c10db5e6d1388fa98c354",
        "64297f7c7f58556aaf78c7db",
        "64297f747f58556aaf78c7a2",
        "6404bea20840174311e802d8",
        "6404be9f0840174311e802c8",
        "6404be8f0840174311e80268",
        "64297f777f58556aaf78c7b5",
        "64297f937f58556aaf78c875",
        "63fcdda942639d100ee7c529",
        "64297f7f7f58556aaf78c7f0",
        "64297f877f58556aaf78c829",
        "64270d70fa627388e01e3627",
        "6555f33c2f7bea8162f1d6dc",
      ];
      if (
        this.user.config &&
        this.user.access?.features?.modules?.developments?.enabled
      ) {
        return true;
      }

      if (
        (this.user.isAdmin &&
          !this.user.config &&
          this.user.companyId === "yAuL6x3yKIacfejp0MYl") ||
        enabledCompanies.includes(this.user.companyId) ||
        enabledUsers.includes(this.user._id)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getIcon(routeName, icon) {
      return this.$route.name === routeName
        ? require(`@/assets/images/${icon}`)
        : require(`@/assets/images/${icon.replace(".svg", "_gray.svg")}`);
    },
    async getUserNavbarState() {
      const navbarStateResponse = await axios.get(
        `/api/admin/get-navbar-state`,
      );
      let navbarState = navbarStateResponse.data;

      if (navbarState === "collapsed") {
        this.isCollapsed = true;
        this.emitExpandedEvent(true);
      } else if (navbarState === "expanded") {
        this.isCollapsed = false;
        this.emitExpandedEvent(false);
      }
    },
    emitExpandedEvent(isCollapsed) {
      if (isCollapsed) {
        this.$emit("collapsed");
      } else {
        this.$emit("expanded");
      }
    },
    toggleNavbar() {
      this.isCollapsed = !this.isCollapsed;
    },
    projectPage() {
      if (this.user.companyId === "_dw_1O8jj0QTYIRahjbF") {
        this.$router.push("/property-owner");
      } else if (!this.user.config) {
        this.$router.push("/projects");
      } else {
        this.$router.push("/listings");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-icon {
  width: 20px;
  height: 20px;
}

.accordion-btn {
  cursor: pointer;
}

.navbar-btn-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

.navbar {
  z-index: 40;
  background-color: black;
  width: 228px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 12px 0;
  font-size: 14px;
}

.navbar.collapsed {
  width: 68px !important;
}

.ok-wrap {
  display: flex;
  //margin-top: 32px;
}

.links-wrap {
  margin-top: 68px !important;
  margin: 0;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  list-style-type: none;
}

.upper-side {
  min-width: 64px;
}

.links-wrap v-list-item span {
  margin-left: 10px;
}

.v-list-item {
  width: 100%;
  min-height: 44px !important;
  display: flex;
  z-index: 2;
  align-items: center;
  padding: 0 12px;
  &:before {
    border-radius: 8px;
  }
}

.rotate-180 {
  font-weight: 500;
  font-family: Inter;
}

.rotate-180 {
  transform: rotate(180deg);
}

.expanded-text {
  color: #c3c4c6;
}

.separator {
  height: 1px;
  width: 100%;
  background: #2b2b2b;
}

:deep(.v-list) {
  background-color: black !important;
}

:deep(.v-list-item:hover) {
  background-color: #424242 !important;
}
:deep(.v-list-item--active:hover) {
  background-color: #ffffff !important;
}

:deep(
    .v-list-item--active
      > .v-list-item__content
      > div.navbar-btn-wrapper
      > div.expanded-text
  ) {
  color: black !important;
}

:deep(.v-list-item--active) {
  background-color: #ffffff !important;
}

:deep(
    .v-list-item--active > .v-list-item__overlay,
    .v-list-item[aria-haspopup="menu"][aria-expanded="true"]
      > .v-list-item__overlay
  ) {
  opacity: 0 !important;
}

.accordion-btn {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.accordion-btn-expanded {
  width: 254px;
}

/* .v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 8px !important;
} */

/* .v-list--nav.v-list--dense .v-list-item:not(:first-child):not(:only-child) {
  margin-top: 8px !important;
} */
</style>
