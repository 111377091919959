<template>
  <vue-draggable-resizable
    :z="999"
    :resizable="false"
    drag-handle=".drag-area"
    class="widget-container"
    @dragstop="onDragStop"
    v-click-outside="closeModal"
  >
    <div class="booking-details-widget">
      <div class="widget-header drag-area">
        <div>
          <img :src="require('@/assets/images/menu.svg')" alt="" />
        </div>
      </div>

      <div class="modal-content">
        <div
          v-if="!bulkBooking"
          class="modal-row flex-row"
          style="margin-top: 0"
        >
          <img
            :src="require('@/assets/images/objects.svg')"
            alt=""
            style="width: 20px; height: 20px"
          />
          <p class="listing-title">
            {{ listing.listingName }}
          </p>
        </div>
        <div
          class="flex-row modal-row"
          v-if="bulkBooking && listings && listings.length === 1"
          style="margin-top: 0"
        >
          <img
            :src="require('@/assets/images/objects.svg')"
            alt=""
            style="width: 20px; height: 20px"
          />
          <p class="listing-title">
            {{ listings[0].listingName }}
          </p>
        </div>

        <div class="modal-row flex-row" style="margin-top: 0">
          <img
            :src="require('@/assets/images/info.svg')"
            alt=""
            style="width: 20px; height: 20px"
          />
          <div style="display: flex; flex-direction: column; gap: 4px">
            <div class="booking-info-header">
              <div class="flex-row">
                <p>{{ $t("BookingType") }}</p>
                <p style="color: red">*</p>
              </div>
            </div>
            <div class="booking-type-label">
              <p
                v-for="(type, index) in bookingTypes"
                :key="index"
                :class="{ 'selected-type': type.value === newBookingType }"
                :id="'type-' + type.value"
                @click="selectBookingType(type.value)"
              >
                {{ type.name }}
              </p>
            </div>
          </div>
        </div>

        <div class="modal-row flex-row" style="margin-top: 0">
          <img
            :src="require('@/assets/images/calendar.svg')"
            alt=""
            style="width: 20px; height: 20px"
          />
          <div style="display: flex; flex-direction: column; gap: 4px">
            <div class="booking-info-header">
              <div class="flex-row">
                <p>{{ $t("BookedUntil") }}</p>
                <p style="color: red">*</p>
              </div>
            </div>
            <VueDatePicker
              v-model="listing.bookedUntil"
              @update:model-value="addBookingDate($event)"
              :locale="currentLocale"
              :enable-time-picker="false"
              :format="format"
              :cancel-text="$t('Cancel')"
              :select-text="$t('Select')"
              :placeholder="formatBookedUntilDate(listing.bookedUntil)"
              style="width: auto; border-radius: 12px"
            ></VueDatePicker>
            <!-- <date-pick
              :isDateDisabled="isFutureDate"
              v-model="listing.bookedUntil"
              style="display: flex"
              :key="updateCalendarChoice"
              @input="addBookingDate($event)"
            >
              <template v-slot:default="{ toggle }">
                <p @click.stop="toggle" style="cursor: pointer" class="content">
                  {{ formatBookedUntilDate(listing.bookedUntil) }}
                </p>
              </template>
            </date-pick> -->
          </div>
        </div>

        <div
          class="flex-row modal-row booker-search"
          v-if="!newBookerContact"
          style="margin-top: 0"
        >
          <TaskLinkedToSearch
            :items="contacts"
            icon-name="clients.svg"
            v-model="newBookerContact"
            v-if="editBooker"
            ref="search"
            booking="true"
            modal-type="client"
            :label="$t('Booker').toLowerCase()"
            @change="selectBooker"
          >
          </TaskLinkedToSearch>
        </div>

        <div
          class="flex-row modal-row"
          v-else-if="newBookerContact && !editBooker"
          style="margin-top: 0"
        >
          <img
            :src="require('@/assets/images/clients.svg')"
            alt=""
            style="width: 20px; height: 20px"
          />
          <div
            @click="toggleBookerEdit"
            class="booker-selection"
            style="display: flex; flex-direction: column; gap: 4px"
          >
            <div class="booking-info-header">
              <p>{{ $t("Booker") }}</p>
            </div>
            <p>
              {{ newBookerContact.name }}
            </p>
          </div>
        </div>

        <div class="modal-row comment flex-row" style="margin-top: 0">
          <img
            :src="require('@/assets/images/chat-no-bubble.svg')"
            alt=""
            style="width: 20px; height: 20px"
          />
          <div
            @click="openCommentEdit"
            v-if="!newBookingComment && !editComment"
          >
            <p class="link-small">
              +
              {{
                $t("addComment").charAt(0).toUpperCase() +
                $t("addComment").slice(1)
              }}
            </p>
          </div>
          <textarea
            id="textarea"
            class="textarea-booking"
            rows="2"
            v-if="editComment"
            ref="commentbox"
            @focusout="saveComment"
            v-model="newBookingComment"
          />
          <div
            v-if="newBookingComment && !editComment"
            @click="openCommentEdit"
            style="display: flex; flex-direction: column; gap: 4px"
          >
            <div class="booking-info-header">
              <p>{{ $t("Comment") }}</p>
            </div>
            <p>
              {{ newBookingComment }}
            </p>
          </div>
        </div>
      </div>
      <v-divider class="booking-modal-divider"></v-divider>
      <div class="modal-footer">
        <div v-if="!bulkBooking" class="remove-button" @click="removeBooking">
          <p>{{ $t("RemoveBooking") }}</p>
        </div>
        <div class="other-buttons">
          <BaseButton
            class="button-cancel"
            size="large"
            :label="$t('Cancel')"
            @click="closeModal('cancel')"
          >
          </BaseButton>
          <BaseButton
            size="large"
            primary="true"
            :label="$t('Save')"
            @click="saveBooking"
          >
          </BaseButton>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import BaseButton from "../common/BaseButton";
// import DatePick from "vue-date-pick";

import "vue-date-pick/dist/vueDatePick.css";
import moment from "moment";
import TaskLinkedToSearch from "../CalendarView/TaskLinkedToSearch";
import axios from "axios";
import Listing from "@/http/listing";
import { mapGetters } from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

export default {
  name: "BookingDetailsModal",
  props: ["openedListing", "unit", "bulkBooking", "listings", "isUnit"],
  components: {
    TaskLinkedToSearch,
    BaseButton,
    VueDraggableResizable,
    VueDatePicker,
  },
  setup() {
    const { locale: i18nLocale } = useI18n();

    const currentLocale = ref(i18nLocale.value);

    const format = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return currentLocale.value === "et" || currentLocale.value === "ee"
        ? `${day}.${month}.${year}`
        : `${day}/${month}/${year}`;
    };

    return {
      format,
      currentLocale,
    };
  },
  data() {
    return {
      posX: 0,
      posY: 0,
      updateCalendarChoice: 0,
      listing: null,
      datePickOpen: false,
      bookedUntil: null,
      editComment: false,
      editBooker: true,
      newBookingComment: null,
      newBookerContact: null,
      contactSearchOpen: false,
      newBookingType: "oral",
      bookingTypes: [
        { name: this.$t("Oral"), value: "oral" },
        { name: this.$t("Written"), value: "written" },
        { name: this.$t("Notarial"), value: "notarial" },
      ],
      contacts: [],
    };
  },
  computed: {
    ...mapGetters(["sidePanelContactAdd", "newAddedContact"]),
  },
  watch: {
    newAddedContact() {
      if (this.newAddedContact) {
        this.addNewContactToList(this.newAddedContact);
      }
    },
  },
  async created() {
    this.changeOverlappingElement();
    if (!this.bulkBooking) {
      this.listing = this.openedListing;
    } else {
      this.listing = {
        bookingComment: null,
        bookingType: null,
        bookingContact: null,
        bookedUntil: null,
      };
    }
    if (this.unit) {
      this.listing = this.unit;
      this.listing.listingName = this.unit.name
        ? this.unit.name
        : this.unit.objects[0].address;
    }
    await this.setupFields();
    await this.setupContacts();
  },
  beforeUnmount() {
    let listingOverlay = document.getElementById("listing-overlay");
    if (listingOverlay) {
      listingOverlay.style.removeProperty("position");
    }
  },
  methods: {
    addNewContactToList(newContact) {
      this.contacts.push(newContact);
      this.contacts.sort((a, b) => a.name.localeCompare(b.name));

      this.newBookerContact = this.contacts.find(
        (contact) => contact.id === newContact.id,
      );
      this.editBooker = false;
    },
    async setupFields() {
      if (this.listing.bookingComment) {
        this.newBookingComment = this.listing.bookingComment;
      }
      if (this.listing.bookingType) {
        this.newBookingType = this.listing.bookingType;
      }
      if (this.newBookingType === "verbal") {
        this.newBookingType = "oral";
      }
      if (this.listing.bookerContact) {
        this.newBookerContact = this.listing.bookerContact;
        this.editBooker = false;
      }
      if (!this.listing.bookedUntil) {
        this.listing.bookedUntil = moment().format("YYYY-MM-DD");
      }
    },
    async setupContacts() {
      const contactsResponse = await axios.get("/api/contacts/names");
      const contacts = contactsResponse.data;
      contacts.customers.forEach((contact) => {
        let formattedContact = {
          id: contact.customerId,
          name: contact.customerName,
          organization: contact.organization,
          phoneNumber: contact.phoneNumber,
        };
        this.contacts.push(formattedContact);
      });
      this.contacts.sort((a, b) => a.name.localeCompare(b.name));
    },
    async addBookingDate(event) {
      this.statusModalOpen = true;
      this.listing.bookedUntil = event;
      this.updateCalendarChoice++;
    },
    changeOverlappingElement() {
      if (
        document.getElementById("overlay-customer") ||
        document.getElementById("offers-sidepanel-overlay")
      ) {
        document
          .getElementById("listing-overlay")
          .style.setProperty("position", "static", "important");
      }
    },
    selectBooker(value) {
      if (!value) {
        if (this.listing.bookerContact) {
          this.newBookerContact = this.listing.bookerContact;
        } else {
          this.newBookerContact = null;
        }
      } else {
        this.newBookerContact = this.contacts.find(
          (contact) => contact.id === value,
        );
      }
      this.editBooker = false;
    },
    toggleBookerEdit() {
      this.editBooker = !this.editBooker;
      if (this.listing.bookerContact) {
        this.newBookerContact = this.listing.bookerContact;
      }
      if (this.editBooker) {
        this.newBookerContact = null;
        this.$nextTick(() => {
          this.$refs.search.openSearch();
        });
      }
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date < currentDate;
    },
    formatBookedUntilDate(date) {
      return moment(date).format("dddd, DD.MM.YYYY");
    },
    closeModal(event) {
      if (this.sidePanelContactAdd) return;
      this.$emit("close-modal", event);
    },
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;
    },
    async saveBooking() {
      if (!this.bulkBooking) {
        let listingLastStatus = null;
        if (this.unit) {
          listingLastStatus = await Listing.getListingStatus(
            this.unit.listing._id,
          );
        } else {
          listingLastStatus = await Listing.getListingStatus(this.listing._id);
        }

        this.listing.bookerContact = this.newBookerContact;
        this.listing.bookingType = this.newBookingType;

        let statusObject = {
          listingId: this.listing._id,
          status: "booked",
          bookedUntil: this.listing.bookedUntil,
          statusChangedDate: this.listing?.statusChangedDate,
          bookingComment: this.listing.bookingComment,
          bookerContact: this.listing.bookerContact,
          bookingType: this.listing.bookingType,
          lastStatus:
            listingLastStatus.data.status === "booked"
              ? listingLastStatus.data.lastStatus
              : listingLastStatus.data.status,
          activeUntil: this.listing.activeUntil,
        };
        if (this.unit) {
          statusObject.unitId = this.unit._id;
        }
        if (!this.listing.statusChangedDate) {
          statusObject.statusChangedDate = Date.now();
        }

        if (!this.unit) {
          const statusUpdateResponse = await axios.post(
            `/api/listing/update-status`,
            statusObject,
          );

          if (statusUpdateResponse.status === 200) {
            // statusObject.activeUntil = statusUpdateResponse.data.expiryDate;
            this.$emit("update-listing", statusObject);
            this.closeModal();
          } else if (statusUpdateResponse.status > 499) {
            this.$toast.error(this.$t("ErrorUpdating"));
          }
        }
        if (this.unit || this.isUnit) {
          this.$emit("statusChanged", statusObject);
          this.closeModal();
        }
      } else if (this.bulkBooking) {
        this.listing.bookerContact = this.newBookerContact;
        this.listing.bookingType = this.newBookingType;

        let statusObject = {
          status: "booked",
          bookedUntil: this.listing.bookedUntil,
          statusChangedDate: this.listing?.statusChangedDate,
          bookingComment: this.listing.bookingComment,
          bookerContact: this.listing.bookerContact,
          bookingType: this.listing.bookingType,
          activeUntil: this.listing.activeUntil,
        };

        if (!this.listing.statusChangedDate) {
          statusObject.statusChangedDate = Date.now();
        }

        if (this.unit || this.isUnit) {
          this.$emit("statusChanged", statusObject);
          this.closeModal();
          return;
        }
        this.$emit("update-listings", statusObject);
      }
    },
    saveComment() {
      this.listing.bookingComment = this.newBookingComment;
      this.editComment = false;
    },
    openCommentEdit() {
      this.editComment = true;
      if (this.listing.bookingComment) {
        this.newBookingComment = this.listing.bookingComment;
      }
      if (this.editComment) {
        this.$nextTick(() => {
          this.$refs.commentbox.focus();
        });
      }
    },
    selectBookingType(type) {
      this.newBookingType = type;
    },
    async removeBooking() {
      if (!this.bulkBooking) {
        let listingLastStatus = null;
        if (this.unit) {
          listingLastStatus = await Listing.getListingStatus(
            this.unit.listing._id,
          );
        } else {
          listingLastStatus = await Listing.getListingStatus(this.listing._id);
        }

        let newStatus = "";
        if (
          listingLastStatus.data.lastStatus &&
          listingLastStatus.data.lastStatus === "booked"
        ) {
          newStatus = "active";
        } else {
          newStatus = listingLastStatus.data.lastStatus;
        }

        let statusObject = {
          listingId: this.listing._id,
          status: newStatus ? newStatus : listingLastStatus.data.status,
          bookedUntil: null,
          statusChangedDate: this.listing?.statusChangedDate,
          bookingComment: null,
          bookerContact: null,
          bookingType: null,
          lastStatus: null,
        };
        if (this.unit) {
          statusObject.unitId = this.unit._id;
        }
        if (!this.listing.statusChangedDate) {
          statusObject.statusChangedDate = Date.now();
        }

        if (!this.unit) {
          const statusUpdateResponse = await axios.post(
            `/api/listing/update-status`,
            statusObject,
          );
          if (statusUpdateResponse.status === 200) {
            this.$emit("update-listing", statusObject);
            this.closeModal();
          } else if (statusUpdateResponse.status > 499) {
            this.$toast.error(this.$t("ErrorUpdating"));
          }
        } else {
          this.$emit("statusChanged", statusObject);
          this.closeModal();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.widget-container {
  position: fixed;
  left: 44%;
  top: 26% !important;
  padding-bottom: 20px;
  padding-right: 40px;
}

.drag-area {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
}

.widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  background-color: #f4f5f7;
  border-radius: 8px 8px 0 0;

  &:hover {
    cursor: pointer;
  }
}

.booking-details-widget {
  width: 440px;
  height: fit-content;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  position: fixed;
  color: white !important;
  background: #e6e8ec;
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.11),
    2px 1px 9px rgba(0, 0, 0, 0.11),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  background: #ffffff;
}

.modal-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 368px;
  height: fit-content;
}

.booker-search {
  margin-bottom: -10px;

  &:hover {
    cursor: pointer;
  }
}

.booker-selection {
  &:hover {
    cursor: pointer;
  }
}

.listing-title {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  max-width: 336px;
}

.booking-info-header {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #75787a;
  }
}

.booking-type-label {
  display: flex;
  height: 32px;
  overflow: hidden;
  flex-direction: row;
  //align-items: center;
  padding: 0;
  background: white;
  border: 1px solid #e6e8ec;
  border-radius: 8px;

  p {
    justify-content: center;
    align-items: center;
    height: 35px;
    padding: 4px 8px;
    gap: 8px;
    font-size: 12px;
  }

  :nth-child(2) {
    border-left: 1px solid #e6e8ec;
    border-right: 1px solid #e6e8ec;
  }

  &:hover {
    cursor: pointer;
  }
}

.selected-type {
  justify-content: center;
  align-items: center;
  background: #e6e8ec;
  font-weight: 600;
  font-size: 12px;
  //line-height: 16px;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  gap: 24px;
  height: 88px;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
}

.remove-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px 12px 0;
  gap: 12px;
  width: 100%;
  height: 40px;

  p {
    color: #ff1e24;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    font-weight: 400;
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
  }
}

.textarea-booking#textarea {
  width: 70%;
}

.booking-modal-divider {
  border-color: $grey500 !important;
}

.other-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  gap: 8px;
  width: 257px;
  height: 40px;
}

.comment {
  &:hover {
    cursor: pointer;
  }
}

.button-cancel {
  color: black;
}

:deep(.vdpCell.today .vdpCellContent) {
  outline: 1px solid #ff5c01;
  //border-radius: 1000px;
  color: #ff5c01;
  font-weight: 600;
}

/* :deep(.vdpCell .vdpCellContent ){
  color: #000000 !important;
}  */
:deep(.vdpArrowPrev:after) {
  border-right-color: #ff5c01;
}

:deep(.vdpArrowNext:after) {
  border-left-color: #ff5c01;
}

:deep(.vdpCell.selectable:hover .vdpCellContent),
.vdpCell.selected .vdpCellContent {
  color: #000000;
  //background-color: #000000 !important;
  background: #f4f5f7 !important;
  font-weight: 400;
}

:deep(.vdpCell.selected .vdpCellContent) {
  background: #ff5c01;
  color: #ffffff;
  font-weight: 600;
}
</style>
