<template>
  <div style="width: 100%">
    <BaseSelect
      v-model="selectedLanguage"
      :items="languages"
      :placeholder="t('DisplayLanguage')"
    />
  </div>
</template>
<script>
import BaseSelect from "@/components/common/BaseSelect.vue";
import { mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "LanguageSelection",
  props: ["closedPanel"],
  components: {
    BaseSelect,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  data() {
    return {
      languages: [
        { name: "DK - Dansk", value: "dk", tag: "Danish" },
        { name: "NL - Nederlands", value: "nl", tag: "Dutch" },
        { name: "EN - English", value: "en", tag: "English" },
        { name: "EE - Eesti", value: "et", tag: "Estonian" },
        { name: "FR - Français", value: "fr", tag: "French" },
        { name: "DE - Deutsch", value: "de", tag: "German" },
        { name: "IT - Italiano", value: "it", tag: "Italian" },
        { name: "LV - Latviešu", value: "lv", tag: "Latvian" },
        { name: "LT - Lietuvių", value: "lt", tag: "Lithuanian" },
        { name: "PT - Português", value: "pt", tag: "Portuguese" },
        { name: "PL - Polski", value: "pl", tag: "Polish" },
        { name: "RU - Русский", value: "ru", tag: "Russian" },
        { name: "RO - Română", value: "ro", tag: "Romanian" },
        { name: "ES - Español", value: "es", tag: "Spanish" },
        { name: "SE - Svenska", value: "se", tag: "wedish" },

        { name: "BG - Български", value: "bg", tag: "Bulgarian" },
        { name: "CT - Català", value: "ct", tag: "Catalan" },
        { name: "CZ - Čeština", value: "cz", tag: "Czech" },
        { name: "FI - Suomi", value: "fi", tag: "Finnish" },
        { name: "GR - Ελληνικά", value: "gr", tag: "Greek" },
        { name: "HU - Magyar", value: "hu", tag: "Hungarian" },
        { name: "NO - Norsk", value: "no", tag: "Norwegian" },
        { name: "SK - Slovenčina", value: "sk", tag: "Slovak" },
        { name: "SI - Slovenščina", value: "si", tag: "Slovenian" },
        { name: "TR - Türkçe", value: "tr", tag: "Turkish" },
      ],
      selectedLanguage: "",
      previousSelected: null,
      openConfirmationPanel: false,
    };
  },
  watch: {
    selectedLanguage(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.$emit("confirmationOpen", true, newVal);
      }
    },
  },
  created() {
    for (let _language of this.languages) {
      if (this.$i18n.locale === _language.value) {
        this.selectedLanguage = _language.value;
        this.previousSelected = this.selectedLanguage;
      }
    }
  },
  mounted() {},
  computed: {},
  methods: {
    ...mapActions(["setLanguage", "setLanguageSet"]),
    closePanel() {
      if (
        this.selectedLanguage !== this.previousSelected &&
        !this.closedPanel
      ) {
        this.$emit("confirmationOpen", true, this.selectedLanguage);
        this.selectedLanguage = null;
        this.previousSelected = null;
      }
    },
    changeLocale(loc) {
      this.setLanguage(loc);
      this.setLanguageSet(true);
      this.$i18n.locale = loc;
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

:deep(.v-text-field--enclosed .v-input__append-inner),
:deep(.v-text-field--enclosed .v-input__append-outer),
:deep(.v-text-field--enclosed .v-input__prepend-inner),
:deep(.v-text-field--enclosed .v-input__prepend-outer),
:deep(.v-text-field--full-width .v-input__append-inner),
:deep(.v-text-field--full-width .v-input__append-outer),
:deep(.v-text-field--full-width .v-input__prepend-inner),
:deep(.v-text-field--full-width .v-input__prepend-outer) {
  margin-top: 10px !important;
}

:deep(.v-text-field--outlined > .v-input__control > .v-input__slot) {
  min-height: 44px !important;
  max-height: 44px !important;
}

:deep(.v-text-field--outlined > .v-input__control > .v-input__slot) {
  min-height: 44px !important;
  max-height: 44px !important;
}
</style>
