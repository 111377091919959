export default {
  mounted(el, binding) {
    const tooltipText = binding.value;

    const createTooltip = (tooltipText) => {
      if (!tooltipText) return;

      const tooltip = document.createElement("div");
      tooltip.className = "custom-tooltip";
      tooltip.textContent = tooltipText;
      el._tooltip = tooltip;

      el._showTooltip = () => {
        document.body.appendChild(tooltip);
        const rect = el.getBoundingClientRect();
        const gap = 16;
        tooltip.style.top = `${rect.top + window.scrollY + rect.height / 2 - tooltip.offsetHeight / 2}px`;
        tooltip.style.left = `${rect.left + window.scrollX + rect.width + gap}px`;

        void tooltip.offsetWidth;
        tooltip.classList.add("visible");
      };

      el._hideTooltip = () => {
        if (tooltip.parentNode) {
          tooltip.classList.remove("visible");
          setTimeout(() => {
            if (tooltip.parentNode) {
              tooltip.parentNode.removeChild(tooltip);
            }
          }, 200);
        }
      };

      el.addEventListener("mouseenter", el._showTooltip);
      el.addEventListener("focus", el._showTooltip);
      el.addEventListener("mouseleave", el._hideTooltip);
      el.addEventListener("blur", el._hideTooltip);
    };

    if (tooltipText) {
      createTooltip(tooltipText);
    }
  },
  updated(el, binding) {
    const tooltipText = binding.value;

    if (!tooltipText) {
      if (el._tooltip) {
        el._hideTooltip();
        el.removeEventListener("mouseenter", el._showTooltip);
        el.removeEventListener("focus", el._showTooltip);
        el.removeEventListener("mouseleave", el._hideTooltip);
        el.removeEventListener("blur", el._hideTooltip);
        el._tooltip = null;
      }
    } else {
      if (!el._tooltip) {
        const createTooltip = (tooltipText) => {
          const tooltip = document.createElement("div");
          tooltip.className = "custom-tooltip";
          tooltip.textContent = tooltipText;
          el._tooltip = tooltip;

          el._showTooltip = () => {
            document.body.appendChild(tooltip);
            const rect = el.getBoundingClientRect();
            const gap = 16;
            tooltip.style.top = `${rect.top + window.scrollY + rect.height / 2 - tooltip.offsetHeight / 2}px`;
            tooltip.style.left = `${rect.left + window.scrollX + rect.width + gap}px`;

            void tooltip.offsetWidth;
            tooltip.classList.add("visible");
          };

          el._hideTooltip = () => {
            if (tooltip.parentNode) {
              tooltip.classList.remove("visible");
              setTimeout(() => {
                if (tooltip.parentNode) {
                  tooltip.parentNode.removeChild(tooltip);
                }
              }, 200);
            }
          };

          el.addEventListener("mouseenter", el._showTooltip);
          el.addEventListener("focus", el._showTooltip);
          el.addEventListener("mouseleave", el._hideTooltip);
          el.addEventListener("blur", el._hideTooltip);
        };

        createTooltip(tooltipText);
      } else {
        el._tooltip.textContent = tooltipText;
      }
    }
  },
  unmounted(el) {
    if (el._tooltip) {
      el._hideTooltip();
      el.removeEventListener("mouseenter", el._showTooltip);
      el.removeEventListener("focus", el._showTooltip);
      el.removeEventListener("mouseleave", el._hideTooltip);
      el.removeEventListener("blur", el._hideTooltip);
      el._tooltip = null;
    }
  },
};
