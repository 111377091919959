export const countries_et = {
  AF: "Afganistan",
  AX: "Ahvenamaa",
  AL: "Albaania",
  DZ: "Alžeeria",
  AS: "Ameerika Samoa",
  US: "Ameerika ühendriigid",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktika",
  AG: "Antigua ja Barbuda",
  AE: "Araabia ühendemiraadid",
  AR: "Argentina",
  AM: "Armeenia",
  AW: "Aruba",
  AZ: "Aserbaidžaan",
  AU: "Austraalia",
  AT: "Austria",
  BS: "Bahama",
  BH: "Bahrein",
  BD: "Bangladesh",
  BB: "Barbados",
  PW: "Belau",
  BE: "Belgia",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Boliivia",
  BA: "Bosnia ja Hertsegoviina",
  BW: "Botswana",
  BV: "Bouvet' saar",
  BR: "Brasiilia",
  IO: "Briti India ookeani ala",
  VG: "Briti Neitsisaared",
  BN: "Brunei",
  BG: "Bulgaaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CO: "Colombia",
  CK: "Cooki saared",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  CW: "Curaçao",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominikaani Vabariik",
  EC: "Ecuador",
  EE: "Eesti",
  EG: "Egiptus",
  GQ: "Ekvatoriaal-Guinea",
  SV: "El Salvador",
  ER: "Eritrea",
  ET: "Etioopia",
  FK: "Falklandi saared",
  FJ: "Fidži",
  PH: "Filipiinid",
  FO: "Fääri saared",
  GA: "Gabon",
  GM: "Gambia",
  GH: "Ghana",
  GI: "Gibraltar",
  GD: "Grenada",
  GE: "Gruusia",
  GL: "Gröönimaa",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heardi ja McDonaldi saared",
  CN: "Hiina",
  ES: "Hispaania",
  NL: "Holland",
  BQ: "Hollandi Kariibi mere saared",
  HN: "Honduras",
  HK: "Hongkongi erihalduspiirkond",
  HR: "Horvaatia",
  TL: "Ida-Timor",
  IE: "Iirimaa",
  IL: "Iisrael",
  IN: "India",
  ID: "Indoneesia",
  IQ: "Iraak",
  IR: "Iraan",
  IS: "Island",
  IT: "Itaalia",
  JP: "Jaapan",
  JM: "Jamaica",
  YE: "Jeemen",
  JE: "Jersey",
  JO: "Jordaania",
  CX: "Jõulusaar",
  KY: "Kaimanisaared",
  KH: "Kambodža",
  CM: "Kamerun",
  CA: "Kanada",
  KZ: "Kasahstan",
  QA: "Katar",
  KE: "Keenia",
  CF: "Kesk-Aafrika Vabariik",
  KI: "Kiribati",
  KM: "Komoorid",
  CD: "Kongo DV",
  CG: "Kongo Vabariik",
  CC: "Kookossaared",
  GR: "Kreeka",
  CU: "Kuuba",
  KW: "Kuveit",
  KG: "Kõrgõzstan",
  CY: "Küpros",
  LA: "Laos",
  LT: "Leedu",
  LS: "Lesotho",
  LR: "Libeeria",
  LI: "Liechtenstein",
  LB: "Liibanon",
  LY: "Liibüa",
  LU: "Luksemburg",
  ZA: "Lõuna-Aafrika Vabariik",
  GS: "Lõuna-Georgia ja Lõuna-Sandwichi saared",
  KR: "Lõuna-Korea",
  SS: "Lõuna-Sudaan",
  LV: "Läti",
  EH: "Lääne-Sahara",
  MO: "Macau erihalduspiirkond",
  MG: "Madagaskar",
  MY: "Malaisia",
  MW: "Malawi",
  MV: "Maldiivid",
  ML: "Mali",
  MT: "Malta",
  IM: "Mani saar",
  MA: "Maroko",
  MH: "Marshalli Saared",
  MQ: "Martinique",
  MR: "Mauritaania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mehhiko",
  FM: "Mikroneesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongoolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MZ: "Mosambiik",
  MM: "Myanmar (Birma)",
  NA: "Namiibia",
  NR: "Nauru",
  NP: "Nepal",
  NI: "Nicaragua",
  NG: "Nigeeria",
  NE: "Niger",
  NU: "Niue",
  NF: "Norfolk",
  NO: "Norra",
  OM: "Omaan",
  PG: "Paapua Uus-Guinea",
  PK: "Pakistan",
  PS: "Palestiina alad",
  PA: "Panama",
  PY: "Paraguay",
  PE: "Peruu",
  PN: "Pitcairni saared",
  PL: "Poola",
  PT: "Portugal",
  GF: "Prantsuse Guajaana",
  TF: "Prantsuse Lõunaalad",
  PF: "Prantsuse Polüneesia",
  FR: "Prantsusmaa",
  PR: "Puerto Rico",
  KP: "Põhja-Korea",
  MK: "Põhja-Makedoonia",
  MP: "Põhja-Mariaanid",
  RE: "Réunion",
  CV: "Roheneemesaared",
  SE: "Rootsi",
  RO: "Rumeenia",
  RW: "Rwanda",
  SB: "Saalomoni Saared",
  SH: "Saint Helena",
  KN: "Saint Kitts ja Nevis",
  LC: "Saint Lucia",
  VC: "Saint Vincent ja Grenadiinid",
  BL: "Saint-Barthélemy",
  MF: "Saint-Martin",
  PM: "Saint-Pierre ja Miquelon",
  DE: "Saksamaa",
  ZM: "Sambia",
  WS: "Samoa",
  SM: "San Marino",
  ST: "S\u00e3o Tomé ja Pr\u00edncipe",
  SA: "Saudi Araabia",
  SC: "Seišellid",
  SN: "Senegal",
  RS: "Serbia",
  SL: "Sierra Leone",
  SG: "Singapur",
  SX: "Sint Maarten",
  SK: "Slovakkia",
  SI: "Sloveenia",
  SO: "Somaalia",
  FI: "Soome",
  LK: "Sri Lanka",
  SD: "Sudaan",
  SR: "Suriname",
  GB: "Suurbritannia",
  SZ: "Svaasimaa",
  SJ: "Svalbard ja Jan Mayen",
  SY: "Süüria",
  CH: "Šveits",
  ZW: "Zimbabwe",
  DK: "Taani",
  TJ: "Tadžikistan",
  TH: "Tai",
  TW: "Taiwan",
  TZ: "Tansaania",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad ja Tobago",
  TD: "Tšaad",
  CZ: "Tšehhi",
  CL: "Tšiili",
  TN: "Tuneesia",
  TC: "Turks ja Caicos",
  TV: "Tuvalu",
  TR: "Türgi",
  TM: "Türkmenistan",
  UG: "Uganda",
  UA: "Ukraina",
  HU: "Ungari",
  UY: "Uruguay",
  VI: "USA Neitsisaared",
  UZ: "Usbekistan",
  NC: "Uus-Kaledoonia",
  NZ: "Uus-Meremaa",
  BY: "Valgevene",
  VU: "Vanuatu",
  VA: "Vatikan",
  RU: "Venemaa",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis ja Futuna",
  UM: "Ühendriikide hajasaared",
};
